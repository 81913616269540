import { VStack, Box, Cell } from '@revolut/ui-kit'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Subtab, HomeSectionId } from '@src/interfaces/tabPinning'
import { PipReviews } from '@src/pages/Performance/Reviews/PipReviews'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProbationReviews } from './ProbationReviews'
import { Reviews } from './Reviews'

const reviewsTabs: Subtab[] = [
  {
    id: HomeSectionId.performance,
    title: 'Performance',
    path: ROUTES.PERFORMANCE.REVIEWS.GENERAL,
    to: ROUTES.PERFORMANCE.REVIEWS.GENERAL,
    component: Reviews,
  },
  {
    id: HomeSectionId.reviewsProbation,
    title: 'Probation',
    path: ROUTES.PERFORMANCE.REVIEWS.PROBATION,
    to: ROUTES.PERFORMANCE.REVIEWS.PROBATION,
    component: ProbationReviews,
  },
  {
    id: HomeSectionId.reviewsPip,
    title: 'PIP',
    path: ROUTES.PERFORMANCE.REVIEWS.PIP,
    to: ROUTES.PERFORMANCE.REVIEWS.PIP,
    component: PipReviews,
  },
]

export const ReviewsBase = () => {
  return (
    <OnboardingAppScreen category="reviews">
      <VStack space="s-24">
        <Cell px={0} pb={0}>
          <VStack width="100%">
            <Box px="s-16" pb="s-8">
              <TabBarNavigation tabs={reviewsTabs} isSubtab />
            </Box>
            <Switch>
              {reviewsTabs.map(tab => (
                <Route exact path={tab.path} key={tab.to}>
                  <tab.component />
                </Route>
              ))}
              <InternalRedirect to={reviewsTabs[0].to} />
            </Switch>
          </VStack>
        </Cell>
      </VStack>
    </OnboardingAppScreen>
  )
}
