import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EntityPermissions } from '@src/store/auth/types'
import React from 'react'
import { FieldOptions, Statuses } from '@src/interfaces'
import { Copy } from '@revolut/icons'
import { omit } from 'lodash'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import MoreBarButton, {
  SettingsButtonProps,
} from '@src/features/SettingsButtons/MoreBarButton'
import { navigateTo } from '@src/actions/RouterActions'

interface CopyButtonProps extends SettingsButtonsInstanceProps, SettingsButtonProps {
  cleanFields?: string[]
  afterSubmitUrl: string
  replaceValues?: object
  children?: string
}

const CopyButton = ({
  useMoreButton,
  afterSubmitUrl,
  cleanFields,
  replaceValues,
  isVisible = true,
  entityPermissions = EntityPermissions.Add,
  globalPermissions,
  children,
}: CopyButtonProps) => {
  const context =
    useLapeContext<{ id?: string; status?: Statuses; field_options: FieldOptions }>()
  const { values } = context
  const permissions = useSelector(selectPermissions)

  const canCopy =
    values.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const copy = () => {
    const cleanValues = omit(values, [
      'status',
      'id',
      'field_options',
      'targets',
      'target_epics',
      ...(cleanFields || []),
    ])
    workspaceLocalStorage.setItem(
      afterSubmitUrl,
      JSON.stringify({ ...cleanValues, ...(replaceValues || {}) }),
    )
    navigateTo(afterSubmitUrl)
  }

  if (!isVisible || !canCopy) {
    return null
  }

  return (
    <MoreBarButton useMoreButton={useMoreButton} useIcon={Copy} onClick={copy}>
      {children || 'Duplicate'}
    </MoreBarButton>
  )
}

export default connect(CopyButton)
