import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const EmployeeRecordsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Employees.mov"
      videoPosterUrl="/intros/Intro%20-%20Employees.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.employeeRecords.intro.tips.title"
            defaultMessage="Unify and customise all employee records"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.records.title"
                defaultMessage="Employee records"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.records.text"
                defaultMessage="All your employee information can be centralised in one location. We provide numerous default fields, but you also have the flexibility to add additional fields to store any information you need."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.lifecycle.title"
                defaultMessage="Employee lifecycle"
              />
            ),
            icon: 'Rocket',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.lifecycle.text"
                defaultMessage="An employee can have several statuses that indicate their current stage in the employment lifecycle. The key statuses are 'active', 'onboarding', and 'deactivated'. Revolut People allows you to onboard employees from the beginning, ensuring a positive experience."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.compensation.title"
                defaultMessage="Contract & compensation"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.compensation.text"
                defaultMessage='In Revolut People, all employment contract data is stored under "Contracts". This section allows you to manage compensation details, employee roles, and more.'
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
