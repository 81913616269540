import React from 'react'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  InterviewFeedbackStatus,
  InterviewResult,
  InterviewResultToTitle,
} from '@src/interfaces/interviewTool'
import { getColor } from '@src/styles/colors'
import { Color, Icon, IconName, Text } from '@revolut/ui-kit'

const IconWrapper = styled.div<{
  result?: InterviewResult | null | ''
  status: InterviewFeedbackStatus
}>`
  display: inline-flex;
  vertical-align: bottom;
  color: ${props => {
    if (
      props.status === InterviewFeedbackStatus.pending ||
      props.status === InterviewFeedbackStatus.draft
    ) {
      return getColor(props.theme, Color.ORANGE)
    }
    if (props.status !== InterviewFeedbackStatus.completed) {
      return getColor(props.theme, Color.GREY_20_OPAQUE_90)
    }
    if (props.result === InterviewResult.NoHire) {
      return getColor(props.theme, Color.ORANGE)
    }
    if (props.result === InterviewResult.StrongNoHire) {
      return getColor(props.theme, Color.RED)
    }
    if (props.result === InterviewResult.StrongHire) {
      return getColor(props.theme, Color.GREEN)
    }
    if (props.result === InterviewResult.Hire) {
      return getColor(props.theme, Color.GREY_TONE_50)
    }
    return getColor(props.theme, Color.GREY_20_OPAQUE_90)
  }};
`

interface Props {
  status: InterviewFeedbackStatus
  result?: InterviewResult | null | ''
  showTooltip?: boolean
  showLabel?: boolean
}

interface IconDataInterface {
  icon: IconName
  text: string
}

const getIconData = (
  status: InterviewFeedbackStatus,
  result?: InterviewResult | null | '',
): IconDataInterface => {
  if (status === InterviewFeedbackStatus.completed) {
    switch (result) {
      case InterviewResult.Hire:
        return {
          icon: 'SocialLike',
          text: InterviewResultToTitle[InterviewResult.Hire],
        }
      case InterviewResult.NoHire:
        return {
          icon: 'SocialDislike',
          text: InterviewResultToTitle[InterviewResult.NoHire],
        }
      case InterviewResult.StrongHire:
        return {
          icon: 'SocialLike',
          text: InterviewResultToTitle[InterviewResult.StrongHire],
        }
      case InterviewResult.StrongNoHire:
        return {
          icon: 'SocialDislike',
          text: InterviewResultToTitle[InterviewResult.StrongNoHire],
        }
    }
  }

  if (status === InterviewFeedbackStatus.rejected) {
    return {
      icon: 'CrossCircle',
      text: 'Rejected',
    }
  }

  return {
    icon: '16/SandWatch',
    text: 'Pending',
  }
}

export const InterviewResultIcon = ({
  status,
  result,
  showTooltip,
  showLabel,
}: Props) => {
  const data = getIconData(status, result)

  return (
    <IconWrapper result={result} status={status}>
      <Tooltip placement="top" text={data.text} hide={!showTooltip}>
        <Icon name={data.icon} size={20} />
      </Tooltip>
      {showLabel && (
        <Text ml="s-4" whiteSpace="nowrap">
          {data.text}
        </Text>
      )}
    </IconWrapper>
  )
}
