import React from 'react'
import { Box, Color, Flex, TextProps } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { CareersPositionLocation } from '@src/interfaces/careers'

import CareersPositionLocations from '@src/pages/Careers/CareersPosition/CareersPositionLocations'
import { CareersLocationsVariant } from '@src/interfaces/careers'
import { Bank, Laptop } from '@revolut/icons'
import { LocationType } from '@src/interfaces/locations'

interface CareersPositionWidgetLocationsRowProps extends TextProps {
  locations: CareersPositionLocation[]
  type: LocationType
  variant?: CareersLocationsVariant
}

const Locations = styled(CareersPositionLocations)<{
  componentVariant?: CareersLocationsVariant
}>(({ componentVariant }) =>
  css({
    textAlign: componentVariant === CareersLocationsVariant.Default ? 'center' : 'left',
    ...(componentVariant === CareersLocationsVariant.Small && {
      fontSize: '14px',
      lineHeight: '20px',
    }),
  }),
)

const LocationIcon = ({ type, size = 16 }: { type: LocationType; size?: number }) => {
  const commonProps = {
    display: 'inline-block',
    style: { verticalAlign: 'text-top' },
    mr: 's-8',
  }

  if (type === 'remote') {
    return (
      <Box {...commonProps}>
        <Laptop size={size} color={Color.GREY_TONE_20} />
      </Box>
    )
  }

  return (
    <Box {...commonProps}>
      <Bank size={size} color={Color.GREY_TONE_20} />
    </Box>
  )
}

const CareersPositionLocationsRow = ({
  locations,
  type,
  variant,
}: CareersPositionWidgetLocationsRowProps) => {
  const fontVariant = variant === CareersLocationsVariant.Default ? 'h4' : 'secondary'
  return (
    <Flex
      justifyContent={variant === CareersLocationsVariant.Default ? 'center' : 'left'}
      pb={variant === CareersLocationsVariant.Default ? 's-8' : 0}
      alignItems="center"
      data-testid="location-row"
    >
      {variant === CareersLocationsVariant.Small && <LocationIcon type={type} />}
      <Locations
        locations={locations.map(({ name }) => name.replace(/- remote/gi, ''))}
        componentVariant={variant}
        variant={fontVariant}
      >
        {variant === CareersLocationsVariant.Default && (
          <LocationIcon size={24} type={type} />
        )}

        {type === 'office' ? 'Office' : 'Remote'}
        {': '}
      </Locations>
    </Flex>
  )
}

export default CareersPositionLocationsRow
