import React, { useMemo } from 'react'
import {
  Flex,
  Search,
  VStack,
  ItemSkeleton,
  Item,
  Avatar,
  TextButton,
  Bar,
} from '@revolut/ui-kit'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { getQuestionDescription } from '@src/apps/People/Engagement/helpers'
import {
  useInfiniteQuestionsLoading,
  useQuestionsFilters,
} from '@src/apps/People/Engagement/Survey/components/AddQuestionSidebar/hooks'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { useDriversOptions } from '@src/apps/People/Engagement/Results/hooks'

type Props = {
  onAddQuestion: (q: EngagementQuestionInterface, closeSidebar?: boolean) => void
  addedQuestionsIds: number[]
}
export const QuestionsTab = ({ onAddQuestion, addedQuestionsIds }: Props) => {
  const { driversOptions } = useDriversOptions({
    defaultFilters: [
      { columnName: 'status', filters: [{ id: 'active', name: 'active' }] },
    ],
  })

  const { search, filters, filtersParams } = useQuestionsFilters()
  const { questions, isLoadingMore, isReloadingFilters, ref, hasMoreData } =
    useInfiniteQuestionsLoading({ filtersParams })

  const filteredQuestions = useMemo(() => {
    return (
      questions.filter(({ id: questionId }) => !addedQuestionsIds.includes(questionId)) ||
      []
    )
  }, [questions, addedQuestionsIds])

  return (
    <Flex style={{ flex: '1 0' }} flexDirection="column" justifyContent="space-between">
      <VStack space="s-16">
        <Search
          value={search.searchValue}
          placeholder="Search"
          onChange={newValue => {
            search.setSearchValue(newValue)
            filters.setTextFilter(newValue)
          }}
        />
        <Bar pb="s-12">
          <FilterButtonCheckboxSelect
            searchable
            width={300}
            options={driversOptions}
            label="Driver"
            value={filters.driversFilter}
            onChange={value => {
              if (value) {
                filters.setDriversFilter(value)
              }
            }}
          />
        </Bar>
        {!isLoadingMore && isReloadingFilters ? (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        ) : (
          <>
            {filteredQuestions.map(question => (
              <Item key={question.id}>
                <Item.Avatar>
                  <Avatar
                    useIcon={
                      question.type.id === 'open_ended' ? 'Paragraph' : 'LikeDislike'
                    }
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{question.question_text}</Item.Title>
                  <Item.Description>{getQuestionDescription(question)}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <Item.Value>
                    <TextButton onClick={() => onAddQuestion(question)}>Add</TextButton>
                  </Item.Value>
                </Item.Side>
              </Item>
            ))}
          </>
        )}
        <VStack ref={ref} space="s-12">
          {isLoadingMore && hasMoreData && (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          )}
        </VStack>
      </VStack>
    </Flex>
  )
}
