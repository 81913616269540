import React, { useState } from 'react'
import {
  Box,
  chain,
  Flex,
  Text,
  PerformanceChain,
  PerformanceChainColor,
  HStack,
  TextButton,
  Token,
} from '@revolut/ui-kit'
import format from 'date-fns/format'
import { css } from 'styled-components'
import { useParams } from 'react-router-dom'

import { selectorKeys } from '../api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  ApprovalsInfoInterface,
  ScheduleShiftSummaryInterface,
  EmployeeShiftApprovalInfoData,
  EmployeeShiftDetailsInterface,
  EmployeeShiftInterface,
  SchedulePolicyShiftInterface,
  SchedulingPolicyTableInterface,
  AttendanceApprovalStatus,
  ScheduleShiftBreakdownInterface,
} from '@src/interfaces/attendance'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@src/components/CommonSC/General'
import {
  approveShift,
  deletePolicyShift,
  rejectShift,
  schedulingPolicyShiftsRequests,
} from '@src/api/attendance'
import { IdAndName } from '@src/interfaces'
import { getColor } from '@src/styles/colors'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { ShiftRejectDialiog } from '@src/pages/Forms/ScheduleShift/common'
import { utcToLocalDate } from '@src/utils/timezones'

export const schedulingPoliciesNameColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.scheduling_policies_names,
    title: 'Name',
  }

export const schedulingPoliciesLocationColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'location.id',
    dataPoint: 'location.name',
    sortKey: 'location__name',
    filterKey: 'location__id',
    selectorsKey: selectorKeys.location,
    title: 'Location',
  }

export const schedulingPoliciesGroupsColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.dynamic_groups,
    title: 'Eligibility groups',
    insert: ({ data }) => data.eligibility_groups.map(g => g.name).join(', '),
  }

export const schedulingPoliciesMembersColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'total_members',
    dataPoint: 'total_members',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Members',
  }

export const schedulingPoliciesShiftsColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'total_shifts',
    dataPoint: 'total_shifts',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Shifts',
  }

export const schedulingPoliciesCreatedOnColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.date,
    title: 'Created on',
  }

export const schedulingPoliciesOwnerColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.employee,
    title: 'Owner',
    insert: ({ data }) => <UserWithAvatar {...data.owner} />,
  }

export const schedulingPoliciesStatusColumn: ColumnInterface<SchedulingPolicyTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.scheduling_policies_statuses,
    colors: data => getStatusColor(data.status.id),
    title: 'Status',
  }

export const schedulingPolicyShiftNameColumn: ColumnInterface<SchedulePolicyShiftInterface> =
  {
    type: CellTypes.text,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Name',
  }

export const schedulingPolicyShiftTypeColumn: ColumnInterface<SchedulePolicyShiftInterface> =
  {
    type: CellTypes.text,
    idPoint: 'type.id',
    dataPoint: 'type.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
  }

export const schedulingPolicyShiftDayPeriodColumn: ColumnInterface<SchedulePolicyShiftInterface> =
  {
    type: CellTypes.text,
    idPoint: 'day_period.id',
    dataPoint: 'day_period.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Day period',
  }

export const schedulingPolicyShiftWeekPeriodColumn: ColumnInterface<SchedulePolicyShiftInterface> =
  {
    type: CellTypes.text,
    idPoint: 'week_period.id',
    dataPoint: 'week_period.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Week period',
  }

export const schedulingPolicyShiftCompensationColumn: ColumnInterface<SchedulePolicyShiftInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'compensation_level',
    dataPoint: 'compensation_level',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Compensation',
    insert: ({ data }) =>
      data.compensation_level == null ? '-' : `${data.compensation_level}%`,
  }

export const schedulingPolicyShiftStatusColumn: ColumnInterface<SchedulePolicyShiftInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    colors: data => getStatusColor(data.status.id),
    title: 'Status',
  }

interface ShiftActionsProps {
  data: SchedulePolicyShiftInterface
  onActionComplete: () => void
}

const ShiftActions = ({ data, onActionComplete }: ShiftActionsProps) => {
  const [pending, setPending] = useState(false)
  const params = useParams<{ id: string }>()

  return (
    <Flex>
      <HStack gap="s-8">
        {data.status.id === 'draft' ? (
          <TextButton
            onClick={e => {
              e.stopPropagation()
              setPending(true)
              schedulingPolicyShiftsRequests
                .update({}, { policyId: params.id, id: `${data.id}` })
                .then(onActionComplete)
                .finally(() => setPending(false))
            }}
            disabled={pending}
            textStyle="h6"
          >
            Confirm
          </TextButton>
        ) : null}
        {pending ? null : (
          <TextButton
            onClick={e => {
              e.stopPropagation()
              setPending(true)
              deletePolicyShift(params.id, data.id)
                .then(onActionComplete)
                .catch(() => setPending(false))
            }}
            disabled={pending}
            textStyle="h6"
            color={Token.color.red}
          >
            Delete
          </TextButton>
        )}
      </HStack>
    </Flex>
  )
}

export const schedulingPolicyShiftActionsColumn = (
  onActionComplete: () => void,
): ColumnInterface<SchedulePolicyShiftInterface> => ({
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => <ShiftActions data={data} onActionComplete={onActionComplete} />,
})

export type ScheduleShiftRow = EmployeeShiftInterface | EmployeeShiftDetailsInterface

const formatDate = (date: string) => format(utcToLocalDate(date), 'EEE, d MMM yyyy')

export const isChildShift = (row: ScheduleShiftRow): row is EmployeeShiftInterface =>
  'id' in row

export const formatShiftDuration = ({
  days,
  hours,
  minutes,
}: {
  days?: number
  hours?: number
  minutes?: number
}) => {
  const dayPart = days ? `${days}d ` : ''
  const hourPart = hours ? `${hours}h` : ''
  const minutePart = minutes ? `${minutes}min` : ''
  return `${dayPart}${hourPart}${minutePart}` || '-'
}

export const scheduleShiftDateColumn: ColumnInterface<ScheduleShiftRow> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Date',
  insert: ({ data }) => ('date' in data ? formatDate(data.date) : ''),
}

interface ShiftTypeCellProps {
  data: ScheduleShiftRow
}

const ShiftTypeCell = ({ data }: ShiftTypeCellProps) => {
  if (isChildShift(data)) {
    return (
      <Flex flexDirection="column" gap="s-2" justifyContent="center">
        <Text fontSize="h6" fontWeight="500">
          {data.type.name}
        </Text>
        <Box>
          {data.type.id === 'time_off' && (
            <Text fontSize="h6" color={Token.color.greyTone50}>
              {data.category} (
              {data.time_range
                ? formatShiftDuration({
                    hours: data.time_range.duration_hours,
                    minutes: data.time_range.duration_minutes,
                  })
                : 'All day'}
              )
            </Text>
          )}
          {data.type.id === 'work' && data.sub_shifts?.length
            ? chain(
                ...data.sub_shifts.map(shift => (
                  <Text
                    fontSize="h6"
                    color={
                      shift.type.id === 'regular'
                        ? Token.color.greyTone50
                        : Token.color.orange
                    }
                    key={shift.type.id}
                  >
                    {shift.type.name} (
                    {formatShiftDuration({
                      hours: shift.time_range.duration_hours,
                      minutes: shift.time_range.duration_minutes,
                    })}
                    )
                  </Text>
                )),
              )
            : null}
        </Box>
      </Flex>
    )
  }

  return <>{chain(...data.children.map(row => row.type.name))}</>
}

export const scheduleShiftTypesColumn: ColumnInterface<ScheduleShiftRow> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: 'shift_type',
  selectorsKey: selectorKeys.summaries_list_shift_types,
  title: 'Shift types',
  wrapperCss: data => (isChildShift(data) ? css({ minHeight: 52 }) : undefined),
  insert: ({ data }) => <ShiftTypeCell data={data} />,
}

export const scheduleShiftStartColumn: ColumnInterface<ScheduleShiftRow> = {
  type: CellTypes.text,
  idPoint: 'time_range.start',
  dataPoint: 'time_range.start',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start',
}

export const scheduleShiftEndColumn: ColumnInterface<ScheduleShiftRow> = {
  type: CellTypes.text,
  idPoint: 'time_range.end',
  dataPoint: 'time_range.end',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End',
}

interface DurationProps {
  data: ScheduleShiftRow
}

const Duration = ({ data }: DurationProps) => {
  if (data.time_range) {
    return (
      <>
        {formatShiftDuration({
          hours: data.time_range.duration_hours,
          minutes: data.time_range.duration_minutes,
        })}
      </>
    )
  }
  if (
    (isChildShift(data) && data.time_range == null) ||
    (!isChildShift(data) && data.time_range == null && data.children.length)
  ) {
    return <>All day</>
  }

  return <Text color={Token.color.greyTone50}>-</Text>
}

export const scheduleShiftDurationColumn: ColumnInterface<ScheduleShiftRow> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Duration',
  insert: ({ data }) => <Duration data={data} />,
}

interface ApprovalsInfoProps {
  data: ApprovalsInfoInterface
  showWhenTheresCount?: boolean
}

const ApprovalsInfo = ({ data, showWhenTheresCount }: ApprovalsInfoProps) => {
  return (
    <PerformanceChain>
      {(showWhenTheresCount && data.rejected > 0) || !showWhenTheresCount ? (
        <PerformanceChain.Item color={PerformanceChainColor.RED}>
          {data.rejected}
        </PerformanceChain.Item>
      ) : null}
      {(showWhenTheresCount && data.pending > 0) || !showWhenTheresCount ? (
        <PerformanceChain.Item color={PerformanceChainColor.ORANGE}>
          {data.pending}
        </PerformanceChain.Item>
      ) : null}
      {(showWhenTheresCount && data.approved > 0) || !showWhenTheresCount ? (
        <PerformanceChain.Item color={PerformanceChainColor.GREEN}>
          {data.approved}
        </PerformanceChain.Item>
      ) : null}
    </PerformanceChain>
  )
}

const getApprovalStatusColor = (status: IdAndName<AttendanceApprovalStatus>) =>
  status.id === 'none' ? Token.color.greyTone50 : getStatusColor(status.id)

interface ApprovalTextProps {
  status: IdAndName<AttendanceApprovalStatus> | null
}

const ApprovalText = ({ status }: ApprovalTextProps) => {
  if (status == null || status.id === 'none') {
    return <Text color={Token.color.greyTone50}>-</Text>
  }
  return <Text color={getApprovalStatusColor(status)}>{status.name}</Text>
}

interface ApprovalsProps {
  data: ScheduleShiftRow
}

const Approvals = ({ data }: ApprovalsProps) => {
  if (isChildShift(data) && data.approval_status) {
    return <ApprovalText status={data.approval_status} />
  }

  if (isChildShift(data)) {
    return <Text color={Token.color.greyTone50}>-</Text>
  }

  return <ApprovalsInfo data={data.approvals_info} showWhenTheresCount />
}

export const scheduleShiftApprovalColumn: ColumnInterface<ScheduleShiftRow> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: 'approval',
  selectorsKey: selectorKeys.summaries_list_approval_statuses,
  title: 'Approval',
  insert: ({ data }) => <Approvals data={data} />,
}

export type EmployeeShiftColumn = ScheduleShiftRow | EmployeeShiftApprovalInfoData

export const employeeShiftDateColumn: ColumnInterface<EmployeeShiftColumn> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Date',
  insert: ({ data }) => {
    if ('employee' in data) {
      return <UserWithAvatar {...data.employee} />
    }
    return 'date' in data ? formatDate(data.date) : ''
  },
}

export const employeeShiftTypesColumn: ColumnInterface<EmployeeShiftColumn> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: 'shift_type',
  selectorsKey: selectorKeys.summaries_list_shift_types,
  title: 'Shift types',
  wrapperCss: data =>
    !('children_type' in data) && isChildShift(data) ? css({ minHeight: 52 }) : undefined,
  insert: ({ data }) => ('children_type' in data ? null : <ShiftTypeCell data={data} />),
}

export const employeeShiftStartColumn: ColumnInterface<EmployeeShiftColumn> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start',
  insert: ({ data }) => ('children_type' in data ? '' : <>{data.time_range?.start}</>),
}

export const employeeShiftEndColumn: ColumnInterface<EmployeeShiftColumn> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End',
  insert: ({ data }) => ('children_type' in data ? '' : <>{data.time_range?.end}</>),
}

export const employeeShiftDurationColumn: ColumnInterface<EmployeeShiftColumn> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Duration',
  insert: ({ data }) => {
    if ('children_type' in data) {
      return ''
    }
    return <Duration data={data} />
  },
}

export const employeeShiftApprovalColumn: ColumnInterface<EmployeeShiftColumn> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: 'approval',
  selectorsKey: selectorKeys.summaries_list_approval_statuses,
  title: 'Approval',
  insert: ({ data }) => {
    if ('children_type' in data) {
      return <ApprovalsInfo data={data.approvals_info} />
    }
    return <Approvals data={data} />
  },
}

export const shiftSummaryDateColumn: ColumnInterface<
  ScheduleShiftSummaryInterface | ScheduleShiftBreakdownInterface
> = {
  type: CellTypes.dateInUtc,
  filterType: FilterType.shortDate,
  idPoint: 'date',
  dataPoint: 'date',
  sortKey: 'date',
  filterKey: 'date',
  selectorsKey: selectorKeys.none,
  title: 'Shift date',
}

export const shiftSummaryUpdatedOnColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.dateInUtc,
    idPoint: 'update_date_time',
    dataPoint: 'update_date_time',
    sortKey: 'update_date_time',
    filterKey: 'update_date_time',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.date,
    title: 'Updated on',
  }

export const shiftSummaryEmployeeColumn: ColumnInterface<
  ScheduleShiftSummaryInterface | ScheduleShiftBreakdownInterface
> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
}

export const shiftSummaryLineManagerColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'line_manager.id',
    dataPoint: 'line_manager.name',
    sortKey: 'line_manager__full_name',
    filterKey: 'line_manager__id',
    selectorsKey: selectorKeys.manager,
    title: 'LM',
    insert: ({ data }) => <UserWithAvatar {...data.line_manager} />,
  }

export const shiftSummaryTeamColumn: ColumnInterface<
  ScheduleShiftSummaryInterface | ScheduleShiftBreakdownInterface
> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const shiftSummaryLocationColumn: ColumnInterface<
  ScheduleShiftSummaryInterface | ScheduleShiftBreakdownInterface
> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.name',
  sortKey: 'location__name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const shiftSummaryRegularDurationColumn: ColumnInterface<ScheduleShiftSummaryInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Regular',
    colors: (data, theme) =>
      getColor(
        theme,
        !data.regular_hours && !data.regular_minutes
          ? Token.color.greyTone50
          : Token.color.foreground,
      ),
    insert: ({ data }) =>
      formatShiftDuration({ hours: data.regular_hours, minutes: data.regular_minutes }),
  }

export const shiftSummaryOvertimeDurationColumn: ColumnInterface<ScheduleShiftSummaryInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    colors: data =>
      !data.overtime_hours && !data.overtime_minutes
        ? Token.color.greyTone50
        : getApprovalStatusColor(data.approval_status),
    title: 'Overtime',
    insert: ({ data }) =>
      formatShiftDuration({ hours: data.overtime_hours, minutes: data.overtime_minutes }),
  }

export const shiftSummaryUnsocialDurationColumn: ColumnInterface<ScheduleShiftSummaryInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    colors: data =>
      !data.unsocial_hours && !data.unsocial_minutes
        ? Token.color.greyTone50
        : getApprovalStatusColor(data.approval_status),
    title: 'Unsocial',
    insert: ({ data }) =>
      formatShiftDuration({ hours: data.unsocial_hours, minutes: data.unsocial_minutes }),
  }

export const shiftSummaryStartColumn: ColumnInterface<
  ScheduleShiftSummaryInterface | ScheduleShiftBreakdownInterface
> = {
  type: CellTypes.text,
  idPoint: 'start_time',
  dataPoint: 'start_time',
  sortKey: 'start_time',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start',
}

export const shiftSummaryEndColumn: ColumnInterface<
  ScheduleShiftSummaryInterface | ScheduleShiftBreakdownInterface
> = {
  type: CellTypes.text,
  idPoint: 'end_time',
  dataPoint: 'end_time',
  sortKey: 'end_time',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End',
}

interface ShiftSummaryActionsProps {
  data: ScheduleShiftSummaryInterface
  onActionComplete: () => void
}

const ShiftSummaryActions = ({ data, onActionComplete }: ShiftSummaryActionsProps) => {
  const [pending, setPending] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
  const [isRejectPending, setIsRejectPending] = useState(false)

  if (
    data.approval_status.id === 'none' ||
    data.approval_status.id !== ApprovalStatuses.Pending
  ) {
    return null
  }

  const onReject = () => {
    setIsRejectPending(true)

    rejectShift(data.employee.id, data.id, rejectionComment)
      .then(() => onActionComplete())
      .finally(() => {
        setRejectionComment('')
        setRejectDialogOpen(false)
        setIsRejectPending(false)
      })
  }

  return (
    <>
      <Flex>
        <HStack gap="s-16">
          <TextButton
            onClick={e => {
              e.stopPropagation()
              setPending(true)
              approveShift(data.employee.id, data.id)
                .then(() => onActionComplete())
                .finally(() => setPending(false))
            }}
            disabled={pending}
            textStyle="h6"
          >
            Approve
          </TextButton>
          <TextButton
            onClick={e => {
              e.stopPropagation()
              setRejectDialogOpen(true)
            }}
            disabled={pending}
            color={Token.color.red}
            textStyle="h6"
          >
            Reject
          </TextButton>
        </HStack>
      </Flex>

      <Box onClick={e => e.stopPropagation()}>
        <ShiftRejectDialiog
          open={rejectDialogOpen}
          isPending={isRejectPending}
          onReject={onReject}
          onClose={() => setRejectDialogOpen(false)}
          comment={rejectionComment}
          setComment={setRejectionComment}
        />
      </Box>
    </>
  )
}

export const shiftSummaryActionColumn = (
  onActionComplete: () => void,
): ColumnInterface<ScheduleShiftSummaryInterface> => ({
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => (
    <ShiftSummaryActions data={data} onActionComplete={onActionComplete} />
  ),
})

export const shiftSummaryApprovalColumn: ColumnInterface<ScheduleShiftSummaryInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: 'approval_status',
    filterKey: 'approval_status',
    selectorsKey: selectorKeys.summaries_list_approval_statuses,
    title: 'Approval',
    insert: ({ data }) => <ApprovalText status={data.approval_status} />,
  }

export const shiftBreakdownTypeColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.text,
    idPoint: 'type.id',
    dataPoint: 'type.name',
    sortKey: 'type',
    filterKey: 'type',
    selectorsKey: selectorKeys.sub_shift_types,
    title: 'Shift type',
  }

export const shiftBreakdownDurationColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Duration',
    colors: (data, theme) =>
      getColor(
        theme,
        !data.duration_hours && !data.duration_minutes
          ? Token.color.greyTone50
          : Token.color.foreground,
      ),
    insert: ({ data }) =>
      formatShiftDuration({ hours: data.duration_hours, minutes: data.duration_minutes }),
  }

export const shiftBreakdownCompensationTypeColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.text,
    idPoint: 'preferred_compensation.id',
    dataPoint: 'preferred_compensation.name',
    sortKey: 'preferred_compensation',
    filterKey: 'preferred_compensation',
    selectorsKey: selectorKeys.shift_compensation_types,
    title: 'Compensation choice',
  }

export const shiftBreakdownCompensationChoiceTypeColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.text,
    idPoint: 'compensation_level',
    dataPoint: 'compensation_level',
    sortKey: 'compensation_level',
    filterKey: null,
    selectorsKey: selectorKeys.shift_compensation_types,
    title: 'Compensation level',
  }

export const shiftBreakdownApprovalColumn: ColumnInterface<ScheduleShiftBreakdownInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: 'approval_status',
    filterKey: 'approval_status',
    selectorsKey: selectorKeys.summaries_list_approval_statuses,
    title: 'Approval',
    insert: ({ data }) => <ApprovalText status={data.approval_status} />,
  }
