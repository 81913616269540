import React, { useState } from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  useGetSpecialisationHiringProcess,
  useGetActiveSpecialisationHiringProcessList,
} from '@src/api/hiringProcess'
import {
  Box,
  Button,
  ItemSkeleton,
  Subheader,
  Tooltip,
  VStack,
  useTooltip,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useLocation, useParams } from 'react-router-dom'
import {
  getLocationDescriptor,
  navigateTo,
  useOpenNewTab,
} from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import RadioSelectInput, {
  createNewKey,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { RoundDetailsSidebar } from '@src/pages/OnboardingChecklistV2/Interviews/components/RoundDetailsSidebar'

type HiringProcessSelectorProps = {
  disabled: boolean
}

const HiringProcessSelector = ({ disabled }: HiringProcessSelectorProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const permissions = useSelector(selectPermissions)
  const tooltip = useTooltip()
  const canCreate = permissions.includes(PermissionTypes.AddHiringProcess)
  const canEdit = permissions.includes(PermissionTypes.ChangeHiringProcess)
  const { data, isLoading } = useGetActiveSpecialisationHiringProcessList(
    values.specialisation?.id,
  )
  const openNewTab = useOpenNewTab()
  if (!values.hiring_process && data.length) {
    values.hiring_process = data.find(({ is_default }) => is_default)
  }
  const options = data.map(specialisationHiringProcess => ({
    key: specialisationHiringProcess.id,
    label: specialisationHiringProcess.name,
    value: specialisationHiringProcess,
  }))
  const value = options?.find(
    option => values.hiring_process?.id === option.value.id,
  )?.value
  const handleChange = (hiringProcess: SpecialisationHiringProcess | null) => {
    if (hiringProcess) {
      values.hiring_process = hiringProcess
    }
  }
  return (
    <VStack>
      <Subheader variant="nested">
        <Subheader.Title>
          Select the hiring process to be used for this job posting. All candidates who
          will apply or will be sourced to this job posting will have the same set of the
          hiring stages, the one you select. The hiring process for job posting cannot be
          changed after job posting is created
        </Subheader.Title>
      </Subheader>
      <Box {...tooltip.getAnchorProps()}>
        <RadioSelectInput
          label="Hiring process"
          loading={isLoading}
          options={options}
          showCreateNewButton={canCreate}
          value={value}
          disabled={disabled}
          onChange={val => {
            if (!val) {
              return
            }
            if (typeof val.id === 'string' && val.id === createNewKey) {
              openNewTab(
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                  id: values.specialisation?.id,
                }),
              )
            } else if (val) {
              handleChange(val)
            }
          }}
          referenceUrl={
            canEdit && values.hiring_process?.id && values.specialisation?.id
              ? getLocationDescriptor(
                  pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL, {
                    hiringProcessId: values.hiring_process.id,
                    specialisationId: values.specialisation.id,
                  }),
                )
              : undefined
          }
        />
        {disabled && (
          <Tooltip {...tooltip.getTargetProps()}>
            Hiring process for the job posting can't be changed once it's selected.
            <br />
            You can adjust the existing hiring process or create an additional job posting
            with a different hiring process
          </Tooltip>
        )}
      </Box>
    </VStack>
  )
}

type HiringProcessListProps = {
  onShowRoundDetails: (hiringProcessRound: HiringProcessInterface) => void
}

export const HiringProcessList = ({ onShowRoundDetails }: HiringProcessListProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetSpecialisationHiringProcess(
    values.specialisation?.id,
    values.hiring_process?.id,
  )
  if (isLoading) {
    return (
      <VStack gap="s-8">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }
  return (
    <HiringProcessDetails
      hiringProcessRounds={data?.hiring_process_stages ?? []}
      loading={isLoading}
      onShowRoundDetails={onShowRoundDetails}
    />
  )
}

export const HiringProcess = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const [stageDetails, setStageDetails] = useState<HiringProcessInterface | null>(null)
  return (
    <>
      {stageDetails && (
        <RoundDetailsSidebar
          hiringProcessRound={stageDetails}
          onClose={() => {
            setStageDetails(null)
          }}
        />
      )}
      <PageBody>
        <VStack gap="s-16">
          <HiringProcessSelector disabled={!!params?.id} />
          <HiringProcessList
            onShowRoundDetails={hiringProcessRound => {
              setStageDetails(
                stageDetails?.id === hiringProcessRound.id ? null : hiringProcessRound,
              )
            }}
          />
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          successText="Job posting saved successfully"
          onAfterSubmit={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                id: values.id,
                specId: values.specialisation?.id,
              }),
            )
          }}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
