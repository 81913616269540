import React from 'react'

import { Box, Text } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { FaqInterface } from '@src/interfaces/faq'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { faqStatusToColor } from '@src/apps/General/HelpCenter/helpers'

// TODO: [RHR-3191] add selectors when implemented on BE

export const faqTitleColumn: ColumnInterface<FaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  notHoverable: true,
  title: 'Question',
  insert: ({ data }) => (
    <Box py="s-8">
      <Text whiteSpace="pre-wrap">{data.title}</Text>
    </Box>
  ),
}

export const faqTopicColumn: ColumnInterface<FaqInterface> = {
  type: CellTypes.text,
  idPoint: 'topic.id',
  dataPoint: 'topic.title',
  sortKey: 'topic__title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Topic',
}

export const faqCountriesColumn: ColumnInterface<FaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: 'country__name',
  filterKey: 'country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Countries',
  insert: ({ data }) =>
    data.countries?.length ? data.countries.map(country => country.name).join(', ') : '-',
}

export const faqUpdatedOnColumn: ColumnInterface<FaqInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Updated on',
}

export const faqMaintainerColumn: ColumnInterface<FaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'maintainer.id',
  dataPoint: 'maintainer.full_name',
  sortKey: 'maintainer__id',
  filterKey: 'maintainer__id',
  selectorsKey: selectorKeys.employee,
  title: 'Maintainer',
  insert: ({ data }) => <UserWithAvatar {...data.maintainer} />,
}

export const faqStatusColumn: ColumnInterface<FaqInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.faq_statuses,
  title: 'Status',
  colors: data => faqStatusToColor(data.status.id),
}
