import React, { useState } from 'react'
import {
  ActionButton,
  Item,
  ItemSkeleton,
  Text,
  chain,
  Button,
  Grid,
  Token,
} from '@revolut/ui-kit'

import {
  changeMerchantApiPaymentMethod,
  getMerchantApiConfig,
  useMerchantApiPaymentMethod,
  useSubsciptionInfo,
} from '@src/api/plans'
import { OrderAndConfig } from './SubscriptionPlanProvider'
import {
  CardItemAvatar,
  FAILED_PAYMENT_MESSAGE,
  NO_PAYMENT_METHOD_MESSAGE,
} from './common'
import { CheckoutWidget } from './CheckoutWidget'
import { MerchantPaymentMethod, SubscriptionInfoInterface } from '@src/interfaces/plans'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { useRetryPaymentFlow } from '@src/pages/AccountRestricted/useRetryPaymentFlow'

interface PaymentDetailsProps {
  customBody?: React.ReactNode
  customActions?: React.ReactNode
  onAfterSuccess?: () => void
}

export const PaymentDetails = ({
  onAfterSuccess,
  customBody,
  customActions,
}: PaymentDetailsProps) => {
  const queryClient = useQueryClient()

  const [orderAndConfig, setOrderAndConfig] = useState<OrderAndConfig>()

  const { info, isFetching: isInfoLoading } = useSubsciptionInfo()
  const { data: paymentMethodData } = useMerchantApiPaymentMethod(
    info?.revolut_merchant_api_payment_method?.id,
  )
  const { onRetryPayment } = useRetryPaymentFlow({
    onSuccessPopupClose: () => {
      resetState()
      onAfterSuccess?.()
    },
  })

  const resetState = () => {
    queryClient.invalidateQueries(API.SUBSCRIPTIONS_INFO_API)
    setOrderAndConfig(undefined)
  }

  return orderAndConfig ? (
    <CheckoutWidget
      orderAndConfig={orderAndConfig}
      initialPaymentMethod={
        paymentMethodData?.type.id === 'CARD' ? 'card' : 'revolut-pay'
      }
      onRetryPayment={onRetryPayment}
      onSuccessPopupClose={resetState}
    >
      <PageBody>
        <CheckoutWidget.Details />
        {customBody}
      </PageBody>

      <PageActions>
        <Grid columns={2} gap="s-8" maxWidth={375}>
          <Button variant="secondary" onClick={resetState}>
            Cancel
          </Button>
          <CheckoutWidget.Actions />
        </Grid>
      </PageActions>
    </CheckoutWidget>
  ) : (
    <>
      <PageBody>
        <PreviewPaymentDetails
          onSetOrderAndConfig={setOrderAndConfig}
          info={isInfoLoading ? undefined : info}
          paymentMethodData={paymentMethodData}
        />
        {customBody}
      </PageBody>

      {customActions ? <PageActions>{customActions}</PageActions> : null}
    </>
  )
}

interface PreviewPaymentDetailsProps {
  onSetOrderAndConfig: (data: OrderAndConfig) => void
  paymentMethodData?: MerchantPaymentMethod
  info?: SubscriptionInfoInterface
}

const PreviewPaymentDetails = ({
  onSetOrderAndConfig,
  paymentMethodData,
  info,
}: PreviewPaymentDetailsProps) => {
  const [createChangeOrderPending, setCreateChangeOrderPending] = useState(false)

  const cardNumber = paymentMethodData?.last4 ? (
    <Text color={Token.color.foreground}>•••• •••• •••• {paymentMethodData.last4}</Text>
  ) : null
  const expiryMonth =
    paymentMethodData?.expiry_month &&
    String(paymentMethodData.expiry_month).padStart(2, '0')
  const expiryYear =
    paymentMethodData?.expiry_year && String(paymentMethodData.expiry_year).slice(-2)
  const cardExpiry = expiryMonth && expiryYear ? `${expiryMonth}/${expiryYear}` : null
  const isRevolutPayMethod = paymentMethodData?.type.id === 'REVOLUT_PAY'
  const isLastPaymentFailed = info?.last_payment_status?.id === 'failure'
  const isOnCustomPlan = !!info?.subscription_plan?.manual_payment

  const onUpdate = async () => {
    if (!createChangeOrderPending) {
      setCreateChangeOrderPending(true)

      try {
        const [configResponse, orderResponse] = await Promise.all([
          getMerchantApiConfig(),
          changeMerchantApiPaymentMethod(),
        ])

        onSetOrderAndConfig({
          order: orderResponse.data,
          config: configResponse.data,
          type: 'change-payment-method',
        })
      } catch {
        setCreateChangeOrderPending(false)
      }
    }
  }

  if (!info || (info.revolut_merchant_api_payment_method && !paymentMethodData)) {
    return <ItemSkeleton />
  }

  if (isOnCustomPlan) {
    return (
      <Item aria-label="payment method">
        <Item.Avatar>
          <CardItemAvatar variant="default" type="customPlan" />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>Custom annual plan</Item.Title>
        </Item.Content>
      </Item>
    )
  }

  if (!info.revolut_merchant_api_payment_method) {
    return (
      <Item aria-label="payment method">
        <Item.Avatar>
          <CardItemAvatar variant="error" type="card" />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>{NO_PAYMENT_METHOD_MESSAGE}</Item.Title>
        </Item.Content>

        <Item.Side>
          <ActionButton onClick={onUpdate} pending={createChangeOrderPending}>
            Update
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  if (isRevolutPayMethod) {
    return (
      <Item aria-label="payment method">
        <Item.Avatar>
          <CardItemAvatar
            type="revolut-pay"
            variant={isLastPaymentFailed ? 'error' : 'default'}
          />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>Revolut Pay</Item.Title>
          {isLastPaymentFailed ? (
            <Item.Description color={Token.color.red}>
              {FAILED_PAYMENT_MESSAGE}
            </Item.Description>
          ) : null}
        </Item.Content>

        <Item.Side>
          <ActionButton onClick={onUpdate} pending={createChangeOrderPending}>
            Update
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }

  return (
    <Item aria-label="payment method">
      <Item.Avatar>
        <CardItemAvatar type="card" variant={isLastPaymentFailed ? 'error' : 'default'} />
      </Item.Avatar>

      <Item.Content>
        <Item.Title>
          <Text color={Token.color.greyTone50}>{chain(cardNumber, cardExpiry)}</Text>
        </Item.Title>
        {isLastPaymentFailed ? (
          <Item.Description color={Token.color.red}>
            {FAILED_PAYMENT_MESSAGE}
          </Item.Description>
        ) : null}
      </Item.Content>

      <Item.Side>
        <ActionButton onClick={onUpdate} pending={createChangeOrderPending}>
          Update
        </ActionButton>
      </Item.Side>
    </Item>
  )
}
