import React, { useEffect, useState } from 'react'
import { EntityTypes } from '@src/constants/api'
import { TabBar, TabProps, Tooltip, useTooltip } from '@revolut/ui-kit'
import { FilterByInterface } from '@src/interfaces/data'

interface Props {
  cycleFilter?: FilterByInterface
  onFilterChange: (
    filter: FilterByInterface | FilterByInterface[],
    resetDefaultFilters?: boolean | undefined,
    forceResetOnFilter?: boolean | undefined,
  ) => void
}

export const EntitySelector = ({ onFilterChange, cycleFilter }: Props) => {
  const [selectedValue, setSelectedValue] = useState<EntityTypes>(EntityTypes.companyV2)
  useEffect(() => {
    const newFilters: FilterByInterface[] = [
      {
        columnName: 'is_company',
        filters: [
          selectedValue === EntityTypes.companyV2
            ? { name: 'True', id: 'True' }
            : { name: 'False', id: 'False' },
        ],
      },
    ]

    if (cycleFilter) {
      newFilters.push(cycleFilter)
    }

    if (selectedValue === EntityTypes.department) {
      newFilters.push({
        columnName: 'type',
        filters: [{ id: 'department_roadmap', name: 'Department' }],
      })
    }
    if (selectedValue === EntityTypes.team) {
      newFilters.push({
        columnName: 'type',
        filters: [{ id: 'team_roadmap', name: 'Team' }],
      })
    }
    onFilterChange(newFilters, true, true)
  }, [selectedValue])
  return (
    <TabBar
      variant="segmented fit"
      mx="auto"
      value={selectedValue}
      onChange={value => setSelectedValue(value || EntityTypes.companyV2)}
    >
      <TabBarItemWithTooltip
        /* @ts-expect-error this icon works but it's added to types in ui-kit v14 and there is a significant effort needed to upgade */
        useIcon="CompanyFilled"
        tooltip="Company roadmaps"
        to={EntityTypes.companyV2}
      />
      <TabBarItemWithTooltip
        useIcon="Bank"
        tooltip="Department roadmaps"
        to={EntityTypes.department}
      />
      <TabBarItemWithTooltip
        useIcon="People"
        tooltip="Team roadmaps"
        to={EntityTypes.team}
      />
    </TabBar>
  )
}

const TabBarItemWithTooltip = ({
  tooltip: content,
  ...tabBarItemProps
}: TabProps & { tooltip: string }) => {
  const tooltip = useTooltip()
  return (
    <>
      <TabBar.Item {...tooltip.getAnchorProps()} {...tabBarItemProps} />
      <Tooltip {...tooltip.getTargetProps()}>{content}</Tooltip>
    </>
  )
}
