import React, { useRef, useState } from 'react'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import isArray from 'lodash/isArray'
import { ActionButton, Token, TableWidget } from '@revolut/ui-kit'
import { AxiosPromise } from 'axios'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import SuccessWidget from '@components/SuccessWidget/SuccessWidget'
import { ROUTES } from '@src/constants/routes'
import {
  confirmImportExternalEmployees,
  exportSessionData,
  getExternalEmployeesForSession,
  getImportExternalEmployeesTemplateUrl,
  importExternalEmployees,
} from '@src/api/importData'
import {
  ExternalEmployeeInterface,
  ImportExternalEmployeesInterface,
  ImportExternalEmployeesMetadata,
} from '@src/interfaces/importExternalEmployees'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { FetchDataQueryInterface, RowInterface } from '@src/interfaces/data'
import {
  externalEmployeeActionColumn,
  externalEmployeeEmailColumn,
  externalEmployeeFirstNameColumn,
  externalEmployeeJoinDateColumn,
  externalEmployeeLastNameColumn,
  externalEmployeeLineManagerColumn,
  externalEmployeeLocationColumn,
  externalEmployeeSeniorityColumn,
  externalEmployeeSpecialisationColumn,
  externalEmployeeStatusColumn,
  externalEmployeeTeamColumn,
  externalEmployeeTerminationDateColumn,
  externalEmployeeVendorNameColumn,
} from '@src/constants/columns/importExternalEmployees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Stat from '@src/components/Stat/Stat'
import { saveFile, saveFileByBlob } from '@src/utils'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { PermissionTypes } from '@src/store/auth/types'
import { getColor } from '@src/styles/colors'
import { useTheme } from '@src/styles/theme'
import { PageBody } from '@src/components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import { ImportSessionStatus } from '@src/interfaces/importData'
import { GetRequestInterface } from '@src/interfaces'
import { TableNames } from '@src/constants/table'
import { ClosingSubscriptionPageWall } from '@src/features/Subscriptions/ClosingSubscriptionPageWall'

const ROW: RowInterface<ExternalEmployeeInterface> = {
  highlight: (data, theme) => {
    return Object.keys(data.errors || {}).length
      ? theme.colors['red-action-background']
      : ''
  },
  cells: [
    {
      ...externalEmployeeFirstNameColumn,
      width: 120,
    },
    {
      ...externalEmployeeLastNameColumn,
      width: 120,
    },
    {
      ...externalEmployeeEmailColumn,
      width: 200,
    },
    {
      ...externalEmployeeTeamColumn,
      width: 200,
    },
    {
      ...externalEmployeeSpecialisationColumn,
      width: 200,
    },
    {
      ...externalEmployeeSeniorityColumn,
      width: 120,
    },
    {
      ...externalEmployeeLineManagerColumn,
      width: 200,
    },

    {
      ...externalEmployeeLocationColumn,
      width: 120,
    },
    {
      ...externalEmployeeJoinDateColumn,
      width: 120,
    },
    {
      ...externalEmployeeTerminationDateColumn,
      width: 120,
    },
    {
      ...externalEmployeeStatusColumn,
      width: 120,
    },
    {
      ...externalEmployeeVendorNameColumn,
      width: 120,
    },
    {
      ...externalEmployeeActionColumn,
      width: 160,
    },
  ],
}

const ImportExternalEmployeesSession = () => {
  const params = useParams<{ id?: any }>()
  const theme = useTheme()

  const [exportSessionDataPending, setExportSessionDataPending] = useState(false)
  const [importDataPending, setImportSessionDataPending] = useState(false)

  const firstLoad = useRef(true)

  const location = useLocation<{ data: ImportExternalEmployeesInterface } | undefined>()

  const getItems = (requestData: FetchDataQueryInterface) => {
    if (firstLoad.current && location.state?.data != null) {
      firstLoad.current = false
      return Promise.resolve({ data: location.state.data } as unknown as AxiosPromise<
        GetRequestInterface<ExternalEmployeeInterface, ImportExternalEmployeesMetadata>
      >)
    }

    return getExternalEmployeesForSession(requestData, params.id)
  }

  const table = useTable<
    ExternalEmployeeInterface,
    undefined,
    ImportExternalEmployeesMetadata
  >({ getItems })

  const isValid = table.metadata?.status.id === ImportSessionStatus.Valid
  const isPending = table.metadata?.status.id === ImportSessionStatus.InProgress
  const isSuccess = table.metadata?.status.id === ImportSessionStatus.Completed
  const isFailed = table.metadata?.status.id === ImportSessionStatus.CompletedWithErrors
  const showStatus = isPending || isSuccess || isFailed
  const canImport = table.metadata?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeUploadingSession,
  )

  const onImportData = () => {
    setImportSessionDataPending(true)
    confirmImportExternalEmployees(params.id)
      .then(() => table.refresh())
      .finally(() => setImportSessionDataPending(false))
  }

  const onExportSessionData = () => {
    setExportSessionDataPending(true)
    exportSessionData(params.id)
      .then(response => {
        saveFileByBlob(
          response.data,
          'session_data.csv',
          response.headers['content-type'],
        )
      })
      .finally(() => setExportSessionDataPending(false))
  }

  const renderStatusCard = () => {
    if (isPending) {
      return (
        <SuccessWidget
          title="Task in progress..."
          type="pending"
          text="Please wait for the data to upload, this can take several minutes. You can
          refresh this page to update the task status."
        />
      )
    }

    if (isSuccess) {
      return (
        <SuccessWidget
          title="Task finished!"
          type="success"
          text="External employee data imported successfully"
        />
      )
    }

    if (isFailed) {
      return (
        <SuccessWidget
          title="Task failed!"
          type="error"
          text="There was an error with the upload"
        />
      )
    }
    return null
  }

  const statusToColor = (status?: ImportSessionStatus): string => {
    switch (status) {
      case ImportSessionStatus.InProgress:
        return getColor(theme, Token.color.yellow)
      case ImportSessionStatus.Completed:
        return getColor(theme, Token.color.green)
      case ImportSessionStatus.Failed:
        return getColor(theme, Token.color.red)
      default:
        return getColor(theme, Token.color.foreground)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Review external employee data"
        backUrl={ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.UPLOAD_FILE}
      />
      {showStatus ? (
        renderStatusCard()
      ) : (
        <TableWidget>
          <TableWidget.Info>
            <Stat
              label="Employees to import"
              val={table.metadata?.total_employees_count}
              mr="s-32"
              data-testid="employees_to_import"
            />
            <Stat
              label="Employees to create"
              val={table.metadata?.employees_to_create_count}
              mr="s-32"
              data-testid="employees_to_create"
            />
            <Stat
              label="Employees to update"
              val={table.metadata?.employees_to_update_count}
              mr="s-32"
              data-testid="employees_to_update"
            />
            <Stat
              label="Employees to terminate"
              val={table.metadata?.employees_to_terminate_count}
              mr="s-32"
              data-testid="employees_to_terminate"
            />
            <Stat
              label="Employees to activate"
              val={table.metadata?.employees_to_activate_count}
              mr="s-32"
              data-testid="employees_to_activate"
            />
            <Stat
              label="Errors"
              val={table.metadata?.invalid_employees_count}
              color={
                table.metadata?.invalid_employees_count &&
                table.metadata.invalid_employees_count > 0
                  ? 'red'
                  : undefined
              }
              mr="s-32"
              data-testid="errors"
            />
            <Stat
              label="Status"
              val={table.metadata?.status?.name}
              color={statusToColor(table.metadata?.status?.id)}
              mr="s-32"
            />
          </TableWidget.Info>

          <TableWidget.Actions>
            <ActionButton
              onClick={onExportSessionData}
              pending={exportSessionDataPending}
              mr="s-16"
              useIcon="Download"
            >
              Export table data
            </ActionButton>
            {canImport && (
              <Tooltip text="Data is not valid" placement="right" hide={isValid}>
                <ActionButton
                  variant="accent"
                  onClick={onImportData}
                  pending={importDataPending}
                  disabled={!isValid}
                  useIcon="ShareIOs"
                >
                  Import this data
                </ActionButton>
              </Tooltip>
            )}
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.ImportExternalEmployees}
              useWindowScroll
              dataType="External employees"
              row={ROW}
              {...table}
            />
          </TableWidget.Table>
        </TableWidget>
      )}
    </PageWrapper>
  )
}

const ImportExternalEmployeesFile = () => {
  const [importPending, setImportPending] = useState(false)
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)

  const onFileUpload = (fileUpload: File | null) => {
    if (fileUpload) {
      setImportPending(true)
      importExternalEmployees(fileUpload)
        .then(response => {
          navigateTo(
            pathToUrl(ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.SESSION, {
              id: response.data.id,
            }),
            { data: response.data },
          )
        })
        .catch(() => setImportPending(false))
    }
  }

  const onDownloadTemplate = () => {
    setDownloadTemplatePending(true)

    getImportExternalEmployeesTemplateUrl()
      .then(details => saveFile(details, 'external_emps_upload_example'))
      .finally(() => setDownloadTemplatePending(false))
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Import external employees"
        backUrl={ROUTES.FORMS.EMPLOYEES_BULK_IMPORT}
        mb="28px"
      />
      <ClosingSubscriptionPageWall>
        <PageBody>
          <ImportFile
            importPending={importPending}
            downloadTemplatePending={downloadTemplatePending}
            onDownloadTemplate={onDownloadTemplate}
            supportedFormats={['CSV']}
            onFileUpload={file => {
              if (isArray(file)) {
                onFileUpload(file[0])
              } else {
                onFileUpload(file)
              }
            }}
          />
        </PageBody>
      </ClosingSubscriptionPageWall>
    </PageWrapper>
  )
}

const ImportExternalEmployees = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.UPLOAD_FILE}>
        <ImportExternalEmployeesFile />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.SESSION}>
        <ImportExternalEmployeesSession />
      </Route>
    </Switch>
  )
}

export default ImportExternalEmployees
