import React, { useEffect, useMemo } from 'react'
import {
  CandidatesPipelineViewType,
  useGetCandidatesPipelineView,
} from '@src/api/recruitment/candidates'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { Color, DataPointSkeleton, HStack, Icon, Text } from '@revolut/ui-kit'
import { StatFilter, StatFilters } from '@components/StatFilters/StatFilters'
import Tooltip from '@components/Tooltip/Tooltip'
import pluralize from 'pluralize'
import { Statuses } from '@src/interfaces'
import { formatNumberMillions } from '@src/utils/format'

interface Props extends Pick<FetchDataQueryInterface, 'filters'> {
  onFilterChange: (filter: FilterByInterface | FilterByInterface[]) => void
}

export const INTERVIEW_TYPE_FILTER =
  'active_interview_round__latest_interview_stage__interview_type'

const FILTER_FIELDS = [
  INTERVIEW_TYPE_FILTER,
  'active_interview_round__latest_interview_stage__hiring_stage',
  'active_interview_round__latest_interview_stage__title',
]

export const getCandidatesPipelineFilters = (filters?: FilterByInterface[]) =>
  filters?.filter(item => !FILTER_FIELDS.includes(item.columnName))

export const getSelectedTab = (
  filters?: FilterByInterface[],
  candidatesPipelineData?: CandidatesPipelineViewType[],
) => {
  // check if we have some filter applied from defined list
  const appliedFilterKey = filters?.find(filter =>
    FILTER_FIELDS.includes(filter.columnName),
  )?.filters?.[0]?.id

  // match applied filter to tab
  return (
    (appliedFilterKey &&
      candidatesPipelineData?.find(tab =>
        tab.filters?.some(filter => String(filter) === String(appliedFilterKey)),
      )) ||
    candidatesPipelineData?.[0]
  )
}

const CandidatesPipelineFilter = ({ filters, onFilterChange }: Props) => {
  const { data, isLoading } = useGetCandidatesPipelineView(
    getCandidatesPipelineFilters(filters),
  )

  const brokenCount = useMemo(() => {
    if (!data) {
      return 0
    }

    // we subtract total count and sum count of all stages to get the candidates with missing stages
    const total = data.find(item => !item.filter_field_name)?.count || 0

    return (
      total -
      data.reduce(
        (count, item) => (!item.filter_field_name ? count : count + item.count),
        0,
      )
    )
  }, [data])

  useEffect(() => {
    if (data) {
      // we could have 2 modes: general and comprehensive, we check it by filter field name
      const isComprehensive = data.every(
        item => item.filter_field_name !== INTERVIEW_TYPE_FILTER,
      )
      const stageType = filters?.find(item => item.columnName === INTERVIEW_TYPE_FILTER)
        ?.filters?.[0]?.id

      // if it's comprehensive view and there is stage filter applied we have to remove this filter
      if (isComprehensive && stageType) {
        // try to find active stages in comprehensive view
        const stages = data.filter(
          item => item.stage_type === stageType && item.status !== Statuses.archived,
        )

        // need to remove interview type filter
        const newFilters = [
          ...(filters?.filter(item => item.columnName !== INTERVIEW_TYPE_FILTER) || []),
          {
            columnName: INTERVIEW_TYPE_FILTER,
            filters: [],
          },
        ]

        // if we have 1 active stage, apply it as a filter
        if (stages.length === 1 && stages[0].filter_field_name) {
          const stage = stages[0]

          newFilters.push({
            columnName: stage.filter_field_name!,
            filters:
              stage?.filters?.map(filter => ({
                id: filter,
                name: String(filter),
              })) || [],
          })
        }

        onFilterChange(newFilters)
      }
    }
  }, [data])

  const tabs = useMemo<StatFilter[] | undefined>(
    () =>
      data?.map(item => {
        let title: React.ReactNode = item.label

        if (!item.filter_field_name && brokenCount) {
          title = (
            <HStack gap="s-4">
              {item.label}
              <Tooltip
                placement="top"
                text={`${pluralize(
                  'candidate',
                  brokenCount,
                  true,
                )} with missing active stage`}
              >
                <Icon name="QuestionOutline" size={15} />
              </Tooltip>
            </HStack>
          )
        }

        if (item.status === Statuses.archived) {
          title = <Text color={Color.GREY_50_OPAQUE_50}>{item.label}</Text>
        }

        const count = formatNumberMillions(item.count)

        return {
          id: item.id,
          title,
          value:
            item.status === Statuses.archived ? (
              <Text color={Color.GREY_50_OPAQUE_50}>{count}</Text>
            ) : (
              count
            ),
          ariaLabel: `${item.label} (${item.count})`,
        }
      }),
    [data],
  )

  const onClick = (id: string) => {
    const item = data?.find(elem => elem.id === id)

    if (!item) {
      return
    }

    let filtersToApply: FilterByInterface[] = []

    if (item.filter_field_name) {
      filtersToApply.push({
        columnName: item.filter_field_name,
        filters:
          item?.filters?.map(filter => ({
            id: filter,
            name: String(filter),
          })) || [],
      })
    }

    // we need to reset all other filters
    filtersToApply = [
      ...filtersToApply,
      ...FILTER_FIELDS.filter(field => field !== item.filter_field_name).map(field => ({
        columnName: field,
        filters: [],
      })),
    ]

    onFilterChange(filtersToApply)
  }

  if (isLoading) {
    return (
      <HStack gap="s-24" data-testid="candidate-pipeline-filter-skeleton">
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
      </HStack>
    )
  }

  if (!tabs) {
    return null
  }

  const selectedTabId = getSelectedTab(filters, data)?.id

  return (
    <StatFilters
      filters={tabs}
      maxItemWidth={150}
      selectedFilter={selectedTabId}
      onClick={onClick}
    />
  )
}

export default CandidatesPipelineFilter
