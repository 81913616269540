import React, { useState } from 'react'
import {
  VStack,
  Text,
  Token,
  MoreBar,
  BottomSheet,
  Header,
  Button,
  useStatusPopup,
  StatusPopup,
  Input,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { formatMoney, formatPeriod } from '@src/utils/format'
import { Delete, Download } from '@revolut/icons'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { saveFile } from '@src/utils'
import { UseFetchResult } from '@src/interfaces'
import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { goBack } from '@src/actions/RouterActions'
import { InvoiceEmployeesPreview, InvoiceSummary, InvoiceTotal } from './common'
import { markInvoiceAsPaid } from '@src/api/plans'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface InvoiceDetailsPageProps {
  data: UseFetchResult<SubscriptionInvoiceInterface>
  downloadInvoiceCallback: () => Promise<{ file: string; fileName: string }>
  backUrl: string
  deleteInvoice?: () => Promise<any>
  showMarkAsPaidButton: boolean
}

export const InvoiceDetailsPage = ({
  data,
  downloadInvoiceCallback,
  deleteInvoice,
  backUrl,
  showMarkAsPaidButton,
}: InvoiceDetailsPageProps) => {
  const params = useParams<{ id: string; invoiceId: string }>()

  const statusPopup = useStatusPopup()

  const featureFlags = useSelector(selectFeatureFlags)

  const [invoiceDownloadPending, setInvoiceDownloadPending] = useState(false)
  const [invoiceDeletePending, setInvoiceDeletePending] = useState(false)
  const [markInvoiceAsPaidPopupOpen, setMarkInvoiceAsPaidPopupOpen] = useState(false)
  const [markInvoiceAsPaidPending, setMarkInvoiceAsPaidPending] = useState(false)
  const [invoiceSettlementNumber, setInvoiceSettlementNumber] = useState('')

  const { data: invoice, isFetching, error, refetch } = data

  const subscriptionPlansDevtoolsEnabled = featureFlags.includes(
    FeatureFlags.SubscriptionPlansDevtools,
  )

  if (error) {
    return <FormErrorGuard error={error} />
  }

  if (!invoice || isFetching) {
    return <PageLoading />
  }

  const onDeleteInvoice = () => {
    setInvoiceDeletePending(true)

    deleteInvoice?.()
      .then(() => goBack())
      .catch(() => {
        setInvoiceDeletePending(false)
      })
  }

  const onCloseMarkInvoiceAsPaidPopup = () => {
    setMarkInvoiceAsPaidPopupOpen(false)
    setInvoiceSettlementNumber('')
  }

  const onConfirmMarkInvoiceAsPaid = () => {
    setMarkInvoiceAsPaidPending(true)

    markInvoiceAsPaid(params.invoiceId, { settlement_reference: invoiceSettlementNumber })
      .then(() => {
        refetch()
        onCloseMarkInvoiceAsPaidPopup()

        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Success</StatusPopup.Title>
            <StatusPopup.Description>Invoice record updated</StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to mark invoice as paid</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(err)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
      .finally(() => setMarkInvoiceAsPaidPending(false))
  }

  return (
    <>
      <PageHeader
        title={`Invoice ${invoice.order_number || ''}`}
        subtitle={
          <VStack space="s-2">
            <Text variant="primary" color={Token.color.foreground}>
              {formatPeriod(invoice.start_date, invoice.end_date)}
            </Text>
            <Text>
              {formatMoney(Number(invoice.original_subscription_fee), invoice.currency)}{' '}
              per active employee/month.
            </Text>
          </VStack>
        }
        backUrl={backUrl}
      />

      <PageBody>
        <MoreBar>
          <MoreBar.Action
            onClick={() => {
              setInvoiceDownloadPending(true)
              downloadInvoiceCallback()
                .then(response => {
                  saveFile(response.file, response.fileName)
                })
                .finally(() => {
                  setInvoiceDownloadPending(false)
                })
            }}
            useIcon={Download}
            pending={invoiceDownloadPending}
          >
            Download invoice
          </MoreBar.Action>
          {invoice.status.id === 'open' &&
          invoice.manual_payment &&
          showMarkAsPaidButton ? (
            <MoreBar.Action
              onClick={() => setMarkInvoiceAsPaidPopupOpen(true)}
              useIcon="Statement"
            >
              Mark as paid
            </MoreBar.Action>
          ) : null}
          {subscriptionPlansDevtoolsEnabled && !!deleteInvoice && (
            <MoreBar.Action
              onClick={onDeleteInvoice}
              useIcon={Delete}
              pending={invoiceDeletePending}
            >
              Delete
            </MoreBar.Action>
          )}
        </MoreBar>

        <VStack space="s-16" mt="s-24">
          <InvoiceSummary invoice={invoice} />
          <InvoiceEmployeesPreview invoice={invoice} />
          <InvoiceTotal invoice={invoice} />
        </VStack>
      </PageBody>

      <BottomSheet
        open={markInvoiceAsPaidPopupOpen}
        onClose={onCloseMarkInvoiceAsPaidPopup}
      >
        <Header>
          <Header.Title>Mark invoice as paid</Header.Title>
        </Header>

        <Input
          value={invoiceSettlementNumber}
          onChange={e => setInvoiceSettlementNumber(e.currentTarget.value)}
          label="Settlement reference number"
        />

        <BottomSheet.Actions horizontal>
          <Button onClick={onCloseMarkInvoiceAsPaidPopup} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={onConfirmMarkInvoiceAsPaid}
            pending={markInvoiceAsPaidPending}
            disabled={!invoiceSettlementNumber}
          >
            Save
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}
