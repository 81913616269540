import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { ButtonGroup } from '@components/ButtonGroup/ButtonGroup'
import Icon from '../../components/Icon/Icon'
import {
  getExport,
  getPerformanceReviews,
  getReviewersSelector,
  getScorecardForEditing,
  getScorecardStructure,
} from '@src/api/performance'
import {
  PerformanceScores,
  ScorecardFeedback,
  ScorecardInterface,
} from '@src/interfaces/scorecard'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { FieldArray } from 'react-final-form-arrays'
import { format } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { filterSortPageIntoQuery } from '@src/utils/table'
import Tooltip from '../../components/Tooltip/Tooltip'
import { errorToFormError } from '@src/utils/form'
import {
  performanceToColor,
  performanceToString,
  TabsOrder,
  TabsStates,
} from './constants'
import { FieldsRouter, HeaderRouter } from './Router'
import { HeaderScoreTooltip } from './Tooltips/HeaderScoreTooltip'
import { ScorecardFilters } from './ScorecardFilters'
import { PerformanceReviewerSelector } from '@src/interfaces/performance'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { MainScore } from './MainScore'
import { EmployeeInterface } from '@src/interfaces/employees'
import Button from '../../components/Button/Button'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { formatPercentage, formatPeriod } from '@src/utils/format'
import { colorGetter } from '@src/styles/colors'
import { Color, Flex, Token, opacity } from '@revolut/ui-kit'
import { saveFileByBlob } from '@src/utils'
import { Cross } from '@revolut/icons'
import { Queries } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  flex-shrink: 0;
  min-width: 640px;
  max-width: 650px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-top: 36px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${opacity(Token.colorChannel.deepGrey, 0.1)};
`

const CancelButton = styled.button`
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  font-size: 14px;
  padding: 10px 25px;

  &:hover {
    opacity: 0.8;
  }
`

const HeaderEditingMessageTooltip = styled(Tooltip)`
  justify-content: flex-start;

  &:hover {
    opacity: 0.8;
  }
`

const SubmitButtonsContainer = styled.div`
  display: flex;
`

const Performance = styled.div<{ performance?: PerformanceScores }>`
  color: ${props => {
    return performanceToColor(props.performance)
  }};
  font-size: 20px;
`

const FeedbackPerformance = styled(Performance)`
  font-size: 12px;
`

const PeriodTextSpinner = styled(Icon)`
  color: ${colorGetter(Color.GREY_TONE_50)};
`
const ScorecardBody = styled.div`
  padding-bottom: 50px;
`
const SectionBody = styled.div`
  margin-bottom: 24px;
`
const Top = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const Period = styled.div`
  text-transform: capitalize;
  line-height: 40px;
  font-size: 20px;
  white-space: nowrap;
  font-weight: 600;
`

const EmptyStateIcon = styled(Icon)`
  height: 127px;
  width: 127px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const InfoBlock = styled.div`
  font-size: 14px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const EmptyState = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  font-size: 14px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`

const FeedbackContainer = styled.button`
  margin-bottom: 24px;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
`
const A = styled.a`
  display: block;
  margin-top: 4px;
  margin-bottom: 24px;
  width: 240px;
  color: ${props => props.theme.colors.blue};
`
const FeedBackHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`
const FeedbackName = styled.div`
  font-size: 18px;
  font-weight: bold;
`
const FeedbackDate = styled.div`
  font-size: 13px;
  color: ${colorGetter(Color.GREY_TONE_50)};
`
const FeedbackScore = styled.div`
  margin-bottom: 12px;
`
const FeedbackText = styled.div`
  font-size: 12px;
  line-height: 1.4em;
  word-break: break-word;
  white-space: pre-wrap;
`

const IconExport = styled.div`
  margin-left: 12px;
  cursor: pointer;
  color: ${colorGetter(Color.GREY_TONE_50)};

  &:hover {
    color: ${colorGetter(Color.BLUE_OPAQUE_90)};
  }
`

const CloseBtn = styled.button`
  position: relative;
  top: 12px;
  left: 28px;
`

const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colorGetter(Color.GREY_50_OPAQUE_10)};
  z-index: ${props => props.theme.zIndex.aboveMain};
  width: 100%;
  padding-bottom: 18px;
`

interface ScorecardProps {
  className?: string
  onSubmit?: (data: ScorecardInterface) => void
  onCancel?: () => void
  performanceType?: ReviewCycleCategory
  employee: EmployeeInterface
  loading?: boolean
  cycleId?: string
  forcedFilter?: OptionInterface
  requestId?: string
  editingId?: string
  fromDate?: string
  toDate?: string
  setEditing: (edit: boolean) => void
  editing: boolean
  onClose?: () => void
}

export const Scorecard = ({
  className,
  editing,
  setEditing,
  requestId,
  fromDate,
  employee,
  cycleId,
  forcedFilter,
  performanceType,
  loading,
  editingId,
  toDate,
  onSubmit,
  onCancel,
  onClose,
}: ScorecardProps) => {
  const { changeQueryParam } = useQuery()
  const [activeTab, setActiveTab] = useState<TabsStates>(TabsStates.Scores)
  const [fetching, setFetching] = useState(false)
  const [editingScorecardId, setEditingScorecardId] = useState(
    editingId ? +editingId : undefined,
  )
  const [submitting, setSubmitting] = useState(false)
  const [filters, setFilters] = useState<OptionInterface[]>(
    forcedFilter ? [forcedFilter] : [],
  )
  const [scorecardData, setScorecardData] = useState<ScorecardInterface>()
  const [isExportLoading, setIsExportLoading] = useState(false)
  const user = useSelector(selectUser)
  const submit = async (data: ScorecardInterface) => {
    if (!onSubmit) {
      return null
    }
    try {
      setSubmitting(true)
      await onSubmit(data)
    } catch (e) {
      return errorToFormError(e)
    } finally {
      setSubmitting(false)
    }

    return null
  }

  useEffect(() => {
    if (!forcedFilter) {
      setFilters([])
    }
  }, [cycleId])

  useEffect(() => {
    if (forcedFilter) {
      setFilters([forcedFilter])
    } else {
      setFilters([])
    }
    setActiveTab(TabsStates.Scores)
  }, [forcedFilter])

  useEffect(() => {
    setScorecardData(undefined)
    if (!editing) {
      fetchScorecard()
    } else if (editingScorecardId && editing) {
      fetchScorecardForEditing(editingScorecardId)
    } else if (performanceType) {
      fetchScorecardStructure()
    }
  }, [fromDate, employee.id, performanceType, toDate, editing, filters])

  const fetchReviewersSelectors = async (): Promise<{
    options: PerformanceReviewerSelector[]
  }> => {
    const selectorsFilters = [
      {
        filters: [{ name: `completed`, id: `completed` }],
        columnName: 'status',
      },
    ]
    const sortBy = [
      {
        sortBy: 'updated_date',
        direction: SORT_DIRECTION.ASC,
      },
    ]
    if (performanceType !== 'probation' && cycleId) {
      selectorsFilters.push({
        filters: [{ name: `${cycleId}`, id: `${cycleId}` }],
        columnName: 'cycle__id',
      })
    }

    const result = await getReviewersSelector(
      employee.id,
      filterSortPageIntoQuery(sortBy, selectorsFilters, undefined),
    )

    if (result.data) {
      return {
        options: result.data.map(option => ({
          ...option,
          name: `${option.reviewer_name} - ${format(
            new Date(option.updated_date),
            'd MMM yyyy hh:mm a',
          )}`,
        })),
      }
    }

    return { options: [] }
  }

  const handleFeedbackClick = (feedback: ScorecardFeedback) => {
    setFilters([
      {
        name: `${feedback.full_name} - ${format(
          new Date(feedback.submitted_date),
          'd MMM yyyy hh:mm a',
        )}`,
        id: feedback.scorecard_id,
      },
    ])
    setActiveTab(TabsStates.Scores)
  }

  const fetchScorecardForEditing = async (id: number) => {
    setFetching(true)
    const result = await getScorecardForEditing(id)

    if (result?.data) {
      setScorecardData(result.data)
      setFetching(false)
    }
  }

  const handleEditClick = () => {
    const scorecardId =
      (filters?.length === 1 && +(filters[0] as PerformanceReviewerSelector)?.id) ||
      (scorecardData?.feedback?.length === 1 && scorecardData?.feedback[0]?.scorecard_id)

    if (scorecardId) {
      changeQueryParam(Queries.EditingId, `${scorecardId}`)
      setEditingScorecardId(scorecardId)
      setEditing(true)
    }
  }

  const fetchScorecard = async () => {
    if (fromDate && toDate) {
      setFetching(true)
      const performanceFilter = []

      if (performanceType !== 'probation') {
        performanceFilter.push({
          filters: [{ name: `${cycleId}`, id: `${cycleId}` }],
          columnName: 'cycle__id',
        })
      }

      if (filters && filters.length) {
        performanceFilter.push({
          filters: filters.map(filter => ({ name: `${filter.id}`, id: `${filter.id}` })),
          columnName: 'id',
        })
      }

      const result = await getPerformanceReviews(
        employee.id,
        filterSortPageIntoQuery(undefined, performanceFilter, undefined),
      )

      if (result.data) {
        setScorecardData(result.data)
        setFetching(false)
      }
    }
  }

  const fetchScorecardStructure = async () => {
    setFetching(true)
    const structureFilters = []

    if (performanceType !== 'probation') {
      structureFilters.push({
        filters: [{ name: `${cycleId}`, id: `${cycleId}` }],
        columnName: 'cycle_id',
      })
    }

    if (requestId) {
      structureFilters.push({
        filters: [{ name: `${requestId}`, id: `${requestId}` }],
        columnName: 'request_id',
      })
    }
    const result = await getScorecardStructure(
      employee.id,
      filterSortPageIntoQuery(undefined, structureFilters, undefined),
    )
    setFetching(false)

    if (result.data) {
      setScorecardData(result.data)
    }
  }

  const exportData = () => {
    if (!cycleId || isExportLoading) {
      return
    }

    setIsExportLoading(true)

    const ids = filters.map(filter => filter.id).join(',')

    getExport(employee.id, cycleId, ids)
      .then(response => {
        saveFileByBlob(response.data, 'performance.csv')
      })
      .finally(() => {
        setIsExportLoading(false)
      })
  }

  const showEditing =
    scorecardData?.feedback?.length === 1 &&
    scorecardData?.feedback[0]?.reviewer_id === user?.id &&
    scorecardData?.feedback[0].editable

  return (
    <Container className={className}>
      {onClose && (
        <CloseBtnWrapper>
          <CloseBtn type="button" onClick={onClose}>
            <Cross size={18} />
          </CloseBtn>
        </CloseBtnWrapper>
      )}
      <Form<ScorecardInterface>
        onSubmit={submit}
        initialValues={scorecardData}
        mutators={
          {
            ...arrayMutators,
          } as any
        }
        render={({ values, handleSubmit }) => {
          return (
            <>
              <Top>
                <Period>
                  {!!fromDate && !!toDate && formatPeriod(fromDate, toDate)}
                </Period>
                {editing ? (
                  <SubmitButtonsContainer>
                    {onCancel && (
                      <CancelButton
                        onClick={e => {
                          e.preventDefault()
                          onCancel()
                        }}
                      >
                        Cancel
                      </CancelButton>
                    )}

                    {onSubmit && (
                      <Button
                        square
                        onClick={e => {
                          e.preventDefault()
                          return handleSubmit()
                        }}
                        loading={submitting}
                      >
                        Submit
                      </Button>
                    )}
                  </SubmitButtonsContainer>
                ) : (
                  <Flex alignItems="center">
                    <MainScore
                      performanceLabel={scorecardData?.performance_score_label}
                      performancePercentage={scorecardData?.performance_score}
                      onEditClick={handleEditClick}
                      showEditing={showEditing}
                    />
                    {performanceType === 'PIP' && (
                      <IconExport onClick={exportData}>
                        {isExportLoading ? (
                          <Icon type="Spinner" />
                        ) : (
                          <Icon type="Excel" />
                        )}
                      </IconExport>
                    )}
                  </Flex>
                )}
              </Top>
              {!editing && (
                <Top>
                  <ScorecardFilters
                    onFilterChange={setFilters}
                    filters={filters}
                    cycleId={cycleId}
                    selector={fetchReviewersSelectors}
                  />
                  <ButtonGroup
                    value={activeTab}
                    onChange={setActiveTab}
                    tabs={TabsOrder}
                  />
                </Top>
              )}
              {editing && (
                <InfoBlock>
                  {values?.category === ReviewCycleCategory.Performance && (
                    <>
                      You can edit your review after submission, but only until{' '}
                      <b>noon April 1st BST</b> for self reviews and{' '}
                      <b>noon April 7th BST </b>
                      for manager reviews.
                    </>
                  )}
                  <A
                    target="_blank"
                    href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1486124834/Evaluating+Individual+Performance"
                  >
                    <HeaderEditingMessageTooltip
                      placement="right"
                      body={<HeaderScoreTooltip />}
                    >
                      How overall score will be calculated?
                    </HeaderEditingMessageTooltip>
                  </A>
                </InfoBlock>
              )}
              {loading || fetching ? (
                <PeriodTextSpinner type="Spinner" size="big" />
              ) : (
                <Bottom>
                  {values?.scorecards && values?.scorecards?.length ? (
                    <ScorecardBody>
                      {activeTab === TabsStates.Scores || editing ? (
                        <FieldArray name="scorecards">
                          {({ fields: scorecards }) => {
                            return (
                              !!scorecards &&
                              scorecards.map((section, sectionIndex) => {
                                const sectionData = values.scorecards[sectionIndex]
                                return (
                                  <SectionBody key={sectionIndex}>
                                    <HeaderRouter
                                      section={sectionData}
                                      editing={editing}
                                    />
                                    {sectionData.fields &&
                                      sectionData.fields.length !== 0 && (
                                        <FieldArray name={`${section}.fields`}>
                                          {({ fields }) => {
                                            return fields.map((field, fieldId) => (
                                              <FieldsRouter
                                                reviewedUser={employee}
                                                editing={editing}
                                                name={field}
                                                field={sectionData.fields[fieldId]}
                                                key={field}
                                              />
                                            ))
                                          }}
                                        </FieldArray>
                                      )}
                                  </SectionBody>
                                )
                              })
                            )
                          }}
                        </FieldArray>
                      ) : (
                        values.feedback?.map(feedback => {
                          return (
                            <FeedbackContainer
                              key={feedback.scorecard_id}
                              onClick={() => {
                                handleFeedbackClick(feedback)
                              }}
                            >
                              <FeedBackHeader>
                                <FeedbackName>{feedback.full_name}</FeedbackName>
                                <FeedbackDate>
                                  {format(
                                    new Date(feedback.submitted_date),
                                    'd MMM yyyy',
                                  )}
                                </FeedbackDate>
                              </FeedBackHeader>
                              <FeedbackScore>
                                <FeedbackPerformance performance={feedback.score_label}>
                                  {performanceToString(feedback.score_label, true)}
                                  {feedback.score
                                    ? ` · ${formatPercentage(feedback.score)}`
                                    : ''}
                                </FeedbackPerformance>
                              </FeedbackScore>
                              <FeedbackText>
                                {feedback.text || 'No Feedback'}
                              </FeedbackText>
                            </FeedbackContainer>
                          )
                        })
                      )}
                    </ScorecardBody>
                  ) : (
                    <EmptyState>
                      <EmptyStateIcon type="Feedback" />
                      {!editing &&
                        (activeTab === TabsStates.Feedback
                          ? 'All written feedback will appear here.'
                          : 'All reviews will appear here.')}
                    </EmptyState>
                  )}
                </Bottom>
              )}
            </>
          )
        }}
      />
    </Container>
  )
}
