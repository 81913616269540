import isNumber from 'lodash/isNumber'
import { PerformanceScores } from '@src/interfaces/scorecard'
import { ReviewerRelation } from '@src/interfaces/performance'
import { Token } from '@revolut/ui-kit'

export enum TabsStates {
  Scores = 'Scores',
  Feedback = 'Feedback',
}

export const TabsOrder = [TabsStates.Scores, TabsStates.Feedback]

export const performanceToString = (
  label?: PerformanceScores,
  withoutSigns: boolean = false,
) => {
  switch (label) {
    case PerformanceScores.UNSATISFACTORY:
      return 'Unsatisfactory'
    case PerformanceScores.BELOW_EXPECTATIONS:
    case PerformanceScores.AVERAGE_MINUS:
      return withoutSigns ? 'Average Minus' : 'Average –'
    case PerformanceScores.MEETS_EXPECTATIONS:
    case PerformanceScores.AVERAGE_PLUS:
      return withoutSigns ? 'Average Plus' : 'Average +'
    case PerformanceScores.STRONG:
    case PerformanceScores.ABOVE_EXPECTATIONS:
      return 'Strong'
    case PerformanceScores.EXCEPTIONAL:
      return 'Exceptional'
    case PerformanceScores.PROBATION_FAILED:
      return 'Probation failed'
    case PerformanceScores.PROBATION_PASSED:
      return 'Probation passed'
    case PerformanceScores.NEUTRAL:
      return 'Neutral'
    default:
      return 'N/A'
  }
}

export const performanceToColor = (label?: PerformanceScores) => {
  switch (label) {
    case PerformanceScores.UNSATISFACTORY:
    case PerformanceScores.PROBATION_FAILED:
      return Token.color.error
    case PerformanceScores.BELOW_EXPECTATIONS:
    case PerformanceScores.AVERAGE_MINUS:
      return Token.color.warning
    case PerformanceScores.MEETS_EXPECTATIONS:
      return Token.color.green_60
    case PerformanceScores.AVERAGE_PLUS:
      return Token.color.foreground
    case PerformanceScores.STRONG:
    case PerformanceScores.ABOVE_EXPECTATIONS:
    case PerformanceScores.PROBATION_PASSED:
    case PerformanceScores.EXCEPTIONAL:
      return Token.color.green
    default:
      return Token.color.greyTone50
  }
}

export const relationToString = (
  relation: ReviewerRelation,
  short?: boolean,
  byDefault = 'Peer',
) => {
  switch (relation) {
    case ReviewerRelation.FunctionalManager:
      return short ? 'FM' : 'Functional Manager'
    case ReviewerRelation.LineManager:
      return short ? 'LM' : 'Line Manager'
    case ReviewerRelation.Self:
      return 'Self'
    case ReviewerRelation.HeadOfFunction:
      return short ? 'HoF' : 'Head of Function'
    case ReviewerRelation.Unknown:
      return ''
    default:
      return byDefault
  }
}

export const percentToColor = (num?: number | null) => {
  if (!isNumber(num)) {
    return Token.color.greyTone50
  }
  if (num >= 70) {
    return Token.color.green
  }
  if (num >= 50) {
    return Token.color.green_60
  }
  if (num >= 25) {
    return Token.color.warning
  }
  return Token.color.error
}
