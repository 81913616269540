import { Color, Token, UnifiedTheme } from '@revolut/ui-kit'
import { RevolutTheme } from '@src/styles/theme'

// Note: this helper ensures a type check that is not present in string literals like theme.colors['grey-20']
export const getColor = (theme: UnifiedTheme, colorName: Color): Color =>
  // @ts-expect-error UI kit team will check this
  theme.colors[colorName]

// For styled components to omit writing: props => props.theme.colors[COLORS[colorName]]
export const colorGetter = (colorName: Color) => (props: { theme: RevolutTheme }) => {
  return getColor(props.theme, colorName)
}

export const CHART_COLORS: Color[] = [
  Token.color.blue_80,
  Token.color.green_90,
  Token.color.yellow_90,
  Token.color.indigo_70,
  Token.color.green_60,
  Token.color.cyan_60,
  Token.color.deepPink_80,
  Token.color.purple_90,
  Token.color.red_70,
  Token.color.indigo,
  Token.color.red_40,
  Token.color.blue_60,
  Token.color.terracotta_50,
  Token.color.lightGreen_70,
  Token.color.cyan,
  Token.color.yellow_50,
  Token.color.indigo_50,
  Token.color.terracotta,
  Token.color.deepPink,
  Token.color.lightGreen_90,
  Token.color.blue_40,
  Token.color.yellow_70,
  Token.color.deepPink_50,
  Token.color.red_90,
  Token.color.green_40,
  Token.color.terracotta_70,
  Token.color.lime_60,
  Token.color.lightGreen_50,
  Token.color.purple_70,
  Token.color.greyTone50,
]
