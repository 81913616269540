import React, { useState } from 'react'
import upperFirst from 'lodash/upperFirst'
import { connect } from 'lape'
import {
  Action,
  Bar,
  ProgressSteps,
  ProgressStep,
  Flex,
  Text,
  VStack,
  Widget,
  Box,
  ActionButton,
  Icon,
  Token,
} from '@revolut/ui-kit'
import { format } from 'date-fns'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import {
  GradeCalculationMethodToTitle,
  ReviewCycleStatus,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  closeReviewCycle,
  reopenReviewCycle,
  reviewCyclesRequestsNew,
  startReviewCycle,
  useGetReviewCycle,
  useGetReviewCycleGeneralInfo,
} from '@src/api/reviewCycles'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Dot } from '@src/pages/Forms/FormTitles'
import { ROUTES } from '@src/constants/routes'
import { getReviewCycleStatusColor } from '@src/utils/reviewCycles'
import { PageBody } from '@src/components/Page/PageBody'
import { useParams } from 'react-router'
import { ReviewCycleBanner } from '@src/pages/Forms/ReviewCycle/components/ReviewCycleBanner'
import { pathToUrl } from '@src/utils/router'
import { formatDate, formatPeriodWithDays } from '@src/utils/format'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import pluralize from 'pluralize'
import AdditionalSettingsToggles from './AdditionalSettingsToggles'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { navigateTo } from '@src/actions/RouterActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const General = () => {
  const params = useParams<{ id: string }>()
  const [startLoading, setStartLoading] = useState(false)
  const [stopLoading, setStopLoading] = useState(false)
  const [reopenLoading, setReopenLoading] = useState(false)
  const [additionalOpened, setAdditionalOpened] = useState(false)

  const { data } = useGetReviewCycleGeneralInfo(params.id)
  const { data: reviewCycle } = useGetReviewCycle(params.id)
  const permissions = useSelector(selectPermissions)
  const canDelete =
    permissions.includes(PermissionTypes.DeleteReviewCycles) &&
    reviewCycle?.delete_allowed
  const canViewEligibleEmployees = permissions.includes(
    PermissionTypes.ViewReviewsWhitelist,
  )

  const handleStartCycle = async () => {
    try {
      if (data) {
        setStartLoading(true)
        const result = await startReviewCycle(data.id)
        setStartLoading(false)

        if (result) {
          data.status = ReviewCycleStatus.ongoing
          pushNotification({
            value: `${data.name} cycle is successfully started.`,
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
      }
    } finally {
      setStartLoading(false)
    }
  }

  const handleStopCycle = async () => {
    try {
      if (data) {
        setStopLoading(true)
        const result = await closeReviewCycle(data.id)
        setStopLoading(false)

        if (result) {
          data.status = ReviewCycleStatus.closed
          pushNotification({
            value: `${data.name} cycle is successfully stopped.`,
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
      }
    } finally {
      setStopLoading(false)
    }
  }

  const handleReopenCycle = async () => {
    try {
      if (data) {
        setReopenLoading(true)
        const result = await reopenReviewCycle(data.id)
        setReopenLoading(false)

        if (result) {
          data.status = ReviewCycleStatus.ongoing
          pushNotification({
            value: `${data.name} cycle is successfully re-opened.`,
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
      }
    } finally {
      setReopenLoading(false)
    }
  }

  const handleDeleteCycle = async () => {
    if (reviewCyclesRequestsNew.delete && reviewCycle) {
      await reviewCyclesRequestsNew.delete({ id: reviewCycle.id }, {})
    }
  }

  const getStopCycleTooltipText = () => {
    if (data?.status === 'planned') {
      return 'The cycle has not been started yet'
    }
    if (data?.status === 'closed') {
      return 'The cycle has already been stopped'
    }

    return "If you stop this cycle you won't be able to start it again"
  }

  const generalInfo = [
    { name: 'Cycle name', value: data?.name || '-' },
    {
      name: 'Cycle start date',
      value: data?.start_date_time ? formatDate(data.start_date_time) : '-',
    },
    {
      name: 'Cycle end date',
      value: data?.end_date_time ? formatDate(data.end_date_time) : '-',
    },
  ]

  const kpiDatesAligned =
    data?.department_kpi_period_start_day === data?.team_kpi_period_start_day &&
    data?.department_kpi_period_end_day === data?.team_kpi_period_end_day

  const kpiTimeline = kpiDatesAligned
    ? [
        {
          title: 'KPI setting',
          value: formatPeriodWithDays(
            data?.department_kpi_period_start_day,
            data?.department_kpi_period_end_day,
          ),
          id: 'department_kpi_period_start_day',
        },
      ]
    : [
        {
          title: 'Department KPI setting',
          value: formatPeriodWithDays(
            data?.department_kpi_period_start_day,
            data?.department_kpi_period_end_day,
          ),
          id: 'department_kpi_period_start_day',
        },
        {
          title: 'Team KPI setting',
          value: formatPeriodWithDays(
            data?.team_kpi_period_start_day,
            data?.team_kpi_period_end_day,
          ),
          id: 'team_kpi_period_start_day',
        },
      ]

  const reviewDatesAligned =
    data?.managers_reviews_last_day === data?.self_and_peer_reviews_last_day

  const reviewTimeline = reviewDatesAligned
    ? [
        {
          title: 'Performance review feedback',
          value: formatPeriodWithDays(
            data?.review_period_start_day,
            data?.managers_reviews_last_day,
          ),
          id: 'managers_reviews_last_day',
        },
      ]
    : [
        {
          title: 'Managers performance review feedback',
          value: formatPeriodWithDays(
            data?.review_period_start_day,
            data?.managers_reviews_last_day,
          ),
          id: 'managers_reviews_last_day',
        },
        {
          title: 'Employees performance review feedback',
          value: formatPeriodWithDays(
            data?.review_period_start_day,
            data?.self_and_peer_reviews_last_day,
          ),
          id: 'self_and_peer_reviews_last_day',
        },
      ]

  const calibrationDatesAligned =
    data?.department_owner_calibration_start_day ===
    data?.head_of_function_calibration_start_day

  const calibrationTimeline = calibrationDatesAligned
    ? [
        {
          title: 'Calibration period',
          value: formatPeriodWithDays(
            data?.department_owner_calibration_start_day,
            data?.head_of_function_and_department_last_calibration_day,
          ),
          id: 'department_owner_calibration_start_day',
        },
      ]
    : [
        {
          title: 'HoD calibration period',
          value: formatPeriodWithDays(
            data?.department_owner_calibration_start_day,
            data?.head_of_function_and_department_last_calibration_day,
          ),
          id: 'department_owner_calibration_start_day',
        },
        {
          title: 'HoF calibration period',
          value: formatPeriodWithDays(
            data?.head_of_function_calibration_start_day,
            data?.head_of_function_and_department_last_calibration_day,
          ),
          id: 'head_of_function_calibration_start_day',
        },
      ]

  const timeline = [
    ...kpiTimeline,
    ...reviewTimeline,
    ...calibrationTimeline,
    {
      title: 'Results published to managers',
      value: data?.managers_publishing_day
        ? format(new Date(data.managers_publishing_day), 'd MMM yyyy')
        : 'Date not set',
      id: 'managers_publishing_day',
    },
    {
      title: 'Results published to employees',
      value: data?.reviews_publishing_day
        ? format(new Date(data.reviews_publishing_day), 'd MMM yyyy')
        : 'Date not set',
      id: 'reviews_publishing_day',
    },
  ]

  return (
    <PageWrapper>
      {data && (
        <PageHeader
          title={
            <>
              {data.name}
              <Dot>·</Dot>
              <Text color={getReviewCycleStatusColor(data.status)}>
                {upperFirst(data.status)}
              </Text>
            </>
          }
          backUrl={ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES}
        />
      )}

      <PageBody>
        <AutoStepper>
          {data && (
            <Bar mb="s-32">
              {data.status === 'planned' && (
                <Tooltip placement="right">
                  <ActionButton
                    useIcon="Play"
                    onClick={handleStartCycle}
                    pending={startLoading}
                  >
                    Start Cycle
                  </ActionButton>
                </Tooltip>
              )}
              {data.status === 'ongoing' && (
                <Tooltip placement="right" text={getStopCycleTooltipText()}>
                  <ActionButton
                    pending={stopLoading}
                    useIcon="Stop"
                    variant="negative"
                    onClick={handleStopCycle}
                  >
                    Stop Cycle
                  </ActionButton>
                </Tooltip>
              )}
              {data.status === 'closed' && (
                <Tooltip placement="right" text={getStopCycleTooltipText()}>
                  <ActionButton
                    pending={reopenLoading}
                    useIcon="Play"
                    onClick={handleReopenCycle}
                  >
                    Re-open Cycle
                  </ActionButton>
                </Tooltip>
              )}
              {canDelete && (
                <NewSaveButtonWithPopup<ReviewCyclesInterface>
                  confirmationDialogue={{
                    body: (
                      <Text>
                        This will also delete all related objects such as scorecards and
                        grades. <b>This action is not reversible.</b>
                      </Text>
                    ),
                  }}
                  successText="Review cycle deleted succesfully"
                  onAfterSubmit={() =>
                    navigateTo(ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES)
                  }
                  onClick={handleDeleteCycle}
                  variant="negative"
                  useIcon="Delete"
                  small
                >
                  Delete cycle
                </NewSaveButtonWithPopup>
              )}
            </Bar>
          )}
          <ReviewCycleBanner />
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <NewStepperTitle title="General Info" />
              <Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL_INFO, params)}
              >
                Edit
              </Action>
            </Flex>
            <Widget p="12px 16px">
              <VStack space="s-24">
                {generalInfo.map((item, i) => (
                  <Flex justifyContent="space-between" key={i}>
                    <Text variant="caption" fontWeight={500} color="grey-tone-50">
                      {item.name}
                    </Text>
                    <Text variant="caption">{item.value}</Text>
                  </Flex>
                ))}
              </VStack>
            </Widget>
          </>
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <NewStepperTitle title="Timeline" />
              <Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.TIMELINE, params)}
              >
                Edit
              </Action>
            </Flex>
            <Widget>
              <ProgressSteps variant="vertical-compact">
                {timeline.map(step => (
                  <ProgressStep key={step.id}>
                    <ProgressStep.Title>{step.title}</ProgressStep.Title>
                    <ProgressStep.Description>{step.value}</ProgressStep.Description>
                  </ProgressStep>
                ))}
              </ProgressSteps>
            </Widget>
          </>
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <NewStepperTitle
                title="Eligible employees"
                subtitle={`Total number of eligible employees: ${
                  data?.eligible_employees_count || 0
                }`}
              />
              <Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES, params)}
              >
                Edit
              </Action>
            </Flex>
            {canViewEligibleEmployees && (
              <ActionButton
                useIcon="16/ListBullet"
                mb="s-16"
                onClick={() => {
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES_LIST),
                  )
                }}
              >
                View employees
              </ActionButton>
            )}
            <Widget p="12px 16px">
              <VStack space="s-24">
                {data?.eligible_groups?.length ? (
                  data.eligible_groups.map((item, i) => (
                    <Flex justifyContent="space-between" key={i}>
                      <Text variant="caption" fontWeight={500} color="grey-tone-50">
                        {item.name} ({item.preview?.count} employees included)
                      </Text>
                      <Flex flexDirection="column" alignItems="flex-end">
                        <Text variant="caption">
                          {pluralize(
                            'filter',
                            Object.keys(item.filters || {}).length,
                            true,
                          )}
                        </Text>
                        <Text variant="caption" color="grey-tone-50">
                          {item.sign === 'exclude'
                            ? 'Set as ineligible'
                            : `Scorecards: ${
                                item.should_create_scorecards ? 'enabled' : 'disabled'
                              }`}
                        </Text>
                      </Flex>
                    </Flex>
                  ))
                ) : (
                  <Text variant="caption" color="grey-tone-50">
                    No eligible groups set
                  </Text>
                )}
              </VStack>
            </Widget>
          </>
          <Flex
            alignItems="center"
            gap="s-8"
            mt="s-32"
            mb={additionalOpened ? 's-8' : 's-32'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setAdditionalOpened(!additionalOpened)
            }}
          >
            <Icon
              size={16}
              color={Token.color.greyTone20}
              css={{ rotate: additionalOpened ? '90deg' : undefined }}
            />
            <Text variant="primary">Additional settings</Text>
          </Flex>
          {additionalOpened && (
            <>
              <Box mt="s-20">
                <AdditionalSettingsToggles />
              </Box>
              <Flex alignItems="center" justifyContent="space-between">
                <NewStepperTitle title="Additional scorecards questions" />
                <Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.SCORECARDS, params)}
                >
                  Edit
                </Action>
              </Flex>
              <Widget p="12px 16px">
                <VStack space="s-24">
                  {data?.scorecard_sections?.length ? (
                    data.scorecard_sections.map((item, i) => (
                      <Flex justifyContent="space-between" key={i}>
                        <Text variant="caption" fontWeight={500} color="grey-tone-50">
                          {item.title}
                        </Text>
                        <Flex flexDirection="column" alignItems="flex-end">
                          <Text variant="caption">
                            {pluralize(
                              'question',
                              Object.keys(item.questions || {}).length,
                              true,
                            )}
                          </Text>
                          <Text variant="caption" color="grey-tone-50">
                            Shown for: {item.show_for?.map(el => el.name).join(', ')}
                          </Text>
                        </Flex>
                      </Flex>
                    ))
                  ) : (
                    <Text variant="caption" color="grey-tone-50">
                      No additional scorecards set
                    </Text>
                  )}
                </VStack>
              </Widget>
              <Flex alignItems="center" justifyContent="space-between">
                <NewStepperTitle title="Grade logic" />
                <Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GRADE, params)}
                >
                  Edit
                </Action>
              </Flex>
              <Widget p="12px 16px" mb="s-32">
                <VStack space="s-24">
                  {reviewCycle?.grade_calculation_method ? (
                    <Flex justifyContent="space-between">
                      <Text variant="caption" fontWeight={500} color="grey-tone-50">
                        Recommended grade logic
                      </Text>
                      <Text variant="caption">
                        {GradeCalculationMethodToTitle[
                          reviewCycle.grade_calculation_method
                        ] || ''}
                      </Text>
                    </Flex>
                  ) : (
                    <Text variant="caption" color="grey-tone-50">
                      No calculation method set
                    </Text>
                  )}
                </VStack>
              </Widget>
            </>
          )}
        </AutoStepper>
      </PageBody>
    </PageWrapper>
  )
}

export default connect(General)
