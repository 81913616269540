import React, { MouseEvent, ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { Color, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'

const Appear = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`
const Circle = styled.div<{ checked: boolean; color: string; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid
    ${props => {
      if (!props.checked && props.disabled) {
        return Token.color.greyTone20
      }
      if (props.checked) {
        return props.color
      }
      return Token.color.greyTone50
    }};
`
const ButtonLabel = styled.label<{
  disabled?: boolean
  color: string
  alignTop?: boolean
}>`
  display: flex;
  align-items: ${props => (props.alignTop ? 'baseline' : 'center')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover > ${Circle} {
    border-color: ${props => !props.disabled && props.color};
  }
`
const InnerCircle = styled.div<{ checked: boolean; color: string }>`
  width: 8px;
  height: 8px;
  animation-name: ${props => (props.checked ? Appear : 'none')};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: 150ms;
  border-radius: 50%;
  background-color: ${props => {
    if (props.checked) {
      return props.color
    }
    return 'transparent'
  }};
`
const ButtonSpan = styled.div`
  text-transform: capitalize;
  margin-left: 8px;
`
const ButtonInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: ${props => props.theme.zIndex.main};

  &:focus + ${Circle} {
    border-color: ${props => !props.disabled && props.color};
  }
`

const ButtonContainer = styled.div<{
  selected: boolean
  disabled?: boolean
  disableLabel?: boolean
  highlightSelected?: boolean
  capitalize?: boolean
}>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: ${props => (props.capitalize ? 'capitalize' : undefined)};
  font-size: 14px;
  color: ${({ theme, disabled, disableLabel }) =>
    getColor(
      theme,
      disabled && disableLabel ? Color.GREY_20_OPAQUE_90 : Color.FOREGROUND,
    )};
  line-height: 18px;
  text-align: left;
  border-radius: 4px;
  position: relative;

  &:active {
    color: ${({ theme, disabled }) =>
      getColor(theme, disabled ? Color.GREY_20_OPAQUE_90 : Color.FOREGROUND)};
  }
`

const ButtonContainerSquare = styled(ButtonContainer)<{
  selected: boolean
  disabled?: boolean
}>`
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${props => {
    if (props.disabled) {
      return getColor(props.theme, Color.GREY_50_OPAQUE_10)
    }

    return props.selected
      ? 'linear-gradient(0deg, rgba(0, 117, 235, 0.06), rgba(0, 117, 235, 0.06)),\n    #ffffff'
      : '#ffffff'
  }};
  border: 1px solid
    ${props =>
      getColor(props.theme, props.selected ? Color.BLUE : Color.GREY_20_OPAQUE_90)};
`

const SurveyButtonContainer = styled(ButtonContainer)<{
  selected: boolean
}>`
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0;
  background-color: ${props => {
    if (props.disabled) {
      return getColor(props.theme, Color.GREY_50_OPAQUE_10)
    }

    return props.selected ? '#E6F0FD' : '#ffffff'
  }};
  white-space: nowrap;
  justify-content: center;
  border: 1px solid ${props => (props.selected ? props.theme.colors.blue : `#DFE2E6`)};
  border-left: 1px solid
    ${props => (props.selected ? props.theme.colors.blue : 'transparent')};

  & > label > ${ButtonSpan} {
    margin: 0;
  }

  &:first-child {
    border-left: 1px solid
      ${props => (props.selected ? props.theme.colors.blue : `#DFE2E6`)};
  }

  &:hover {
    background-color: #e6f0fd;
  }
`

const ButtonListContainer = styled(ButtonContainer)<{
  selected: boolean
}>`
  padding: 10px 13px;
  background-color: ${props =>
    props.selected ? getColor(props.theme, Color.GREY_TONE_2) : 'inherit'};
  text-transform: none;
`

type RadioStyleType = 'default' | 'square' | 'list' | 'buttons' | 'survey'

export interface RadioOptionInterface {
  name: ReactNode
  id: any
  disabled?: boolean
}

interface RadioButtonProps {
  label?: React.ReactNode
  color?: string
  tabIndex?: number
  disabled?: boolean
  disableLabel?: boolean
  checked: boolean
  onChange: () => void
  type?: RadioStyleType
  alignTop?: boolean
  inputRef?: (ref: HTMLInputElement) => void
  name?: string
  capitalize?: boolean
  tooltipText?: string
}

export const RadioButton = ({
  disabled,
  disableLabel = true,
  label,
  checked,
  tabIndex,
  onChange,
  type,
  alignTop,
  inputRef,
  name,
  capitalize = true,
  tooltipText,
  ...props
}: RadioButtonProps) => {
  const color = props.color || Token.color.blue
  const tooltip = useTooltip()

  const handleChange = (e: MouseEvent<any>) => {
    e.preventDefault()
    if (!disabled) {
      onChange()
    }
  }

  const renderLabel = () => {
    if (!label) {
      return null
    }

    if (typeof label === 'string') {
      return <ButtonSpan>{label}</ButtonSpan>
    }

    return label
  }

  let Container = ButtonContainer

  switch (type) {
    case 'square':
    case 'buttons':
      Container = ButtonContainerSquare
      break
    case 'survey':
      Container = SurveyButtonContainer
      break
    case 'list':
      Container = ButtonListContainer
      break
  }

  return (
    <Container
      tabIndex={tabIndex}
      selected={checked}
      capitalize={capitalize}
      disabled={disabled}
      disableLabel={disableLabel}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          if (!disabled) {
            onChange()
          }
        }
      }}
      onClick={handleChange}
      {...tooltip.getAnchorProps()}
    >
      <ButtonLabel disabled={disabled} color={color} alignTop={alignTop}>
        <ButtonInput
          type="checkbox"
          tabIndex={-1}
          checked={checked}
          disabled={disabled}
          color={color}
          ref={inputRef}
          onChange={() => {}}
          data-testid={name}
        />
        {type !== 'buttons' && type !== 'survey' && (
          <Circle checked={checked} disabled={disabled} color={color}>
            <InnerCircle checked={checked} color={color} />
          </Circle>
        )}
        {renderLabel()}
      </ButtonLabel>
      {tooltipText && (
        <Tooltip {...tooltip.getTargetProps()} placement="left" maxWidth={300}>
          {tooltipText}
        </Tooltip>
      )}
    </Container>
  )
}
