import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getLocationDescriptor, useOpenNewTab } from '@src/actions/RouterActions'
import { OptionInterface } from '@src/interfaces/selectors'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'

type LapeScorecardFieldProps = {
  canAddNewScorecard?: boolean
  isClearable: boolean
  isDisabled?: boolean
  isOnboarding?: boolean
  isRequired?: boolean
}

const LapeScorecardField = ({
  canAddNewScorecard = false,
  isClearable,
  isDisabled,
  isOnboarding = false,
  isRequired,
}: LapeScorecardFieldProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const openNewTab = useOpenNewTab()
  return (
    <LapeRadioSelectInput<OptionInterface>
      disabled={isDisabled}
      name="scorecard_template"
      required={isRequired}
      label="Scorecard"
      clearable={isClearable}
      selector={selectorKeys.interview_scorecard_templates}
      useQuery
      showCreateNewButton={canAddNewScorecard}
      onChange={option => {
        if (option) {
          if (option?.id === createNewKey) {
            openNewTab(
              pathToUrl(
                ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS,
                undefined,
                isOnboarding
                  ? {
                      ref: 'onboarding',
                    }
                  : undefined,
              ),
            )
          } else {
            values.scorecard_template = option
          }
        }
      }}
      referenceUrl={
        values.scorecard_template?.id
          ? getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
                id: values.scorecard_template.id,
              }),
            )
          : undefined
      }
    />
  )
}

export default LapeScorecardField
