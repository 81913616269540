import React from 'react'
import Form from '@src/features/Form/Form'
import { formHiringProcessStagesSpecialisationRequest } from '@src/api/hiringProcess'

import { connect } from 'lape'
import { HiringProcessInterface, HiringStageType } from '@src/interfaces/hiringProccess'
import HiringStageSidebarPreview from '@src/features/HiringStageSidebarPreview/HiringStageSidebarPreview'

interface Props {
  id?: number
  type: HiringStageType
  onClose: () => void
  onSuccessDelete: () => void
}

const HiringStageSpecialisationPreviewForm = ({
  id,
  type,
  onClose,
  onSuccessDelete,
}: Props) => {
  if (!id) {
    return null
  }
  return (
    <Form<HiringProcessInterface>
      api={formHiringProcessStagesSpecialisationRequest}
      forceParams={{ id: String(id) }}
      disableLoading
    >
      <HiringStageSidebarPreview
        type={type}
        onSuccessDelete={onSuccessDelete}
        onClose={onClose}
      />
    </Form>
  )
}

export default connect(HiringStageSpecialisationPreviewForm)
