import { Stats } from './data'
import { EmployeeInterface, EmployeeOptionInterface } from './employees'
import { IssueTypes } from '@src/interfaces/deliverables'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { IdAndName, IdAndOptionalName } from '.'
import { TeamInterface } from './teams'
import { DepartmentInterface } from './deparment'

export enum RoadmapPriorities {
  TRIVIAL = 'Trivial',
  MINOR = 'Minor',
  MEDIUM = 'Medium',
  HIGH = 'High',
  CRITICAL = 'Critical',
  COMPANY = 'Company',
  MAJOR = 'Major',
  BLOCKER = 'Blocker',
  TRANSFORMATION = 'Transformation',
}

export enum RoadmapStatuses {
  DONE = 'Done',
  IN_PROGRESS = 'In Progress',
  TO_DO = 'To Do',
}

export enum RoadmapChildrenTypes {
  EMPLOYEE_ROADMAP = 'employee_roadmap',
  DEPARTMENT_ROADMAP = 'department_roadmap',
  FUNCTION_ROADMAP = 'function_roadmap',
  TEAM_ROADMAP = 'team_roadmap',
}

export interface RoadmapInterface {
  id: number
  reviewCycle: ReviewCyclesInterface
  // why both?
  review_cycle: ReviewCyclesInterface
  name: string
  description: string
  owner: EmployeeOptionInterface
  priority: RoadmapPriorities
  status: RoadmapStatuses
  eta: string
  creation_date_time: string
  progress: number
  due_date?: string
  resolution: string
  resolution_date_time: string
  key: string
  epic_url: string
  project: string
  project_url: string
  todo: number
  done: number
  stats: Stats
  created_after_deadline: boolean
  deleted: boolean
  fields_changed_after_deadline?: string[]
  start_date: string
  displayable_labels: string[]
  children?: RoadmapInterface[]
  children_type?: RoadmapChildrenTypes
  issue_type?: IssueTypes
  parent_issue_key?: string
  type?: IdAndName<RoadmapChildrenTypes>
  organisational_unit?: IdAndName
  employee?: EmployeeInterface
  is_company?: boolean
  team?: TeamInterface
  department?: DepartmentInterface
  goal?: IdAndOptionalName
}

export interface RoadmapInterfaceForm
  extends Omit<RoadmapInterface, 'status' | 'priority'> {
  status: IdAndName<RoadmapStatuses, RoadmapStatuses>
  priority: IdAndName<RoadmapPriorities, RoadmapPriorities>
}

export interface UnassignedRoadmapInterface
  extends Pick<RoadmapInterface, 'epic_url' | 'id' | 'key' | 'owner' | 'issue_type'> {
  display_name: string
  epic_name: string
  priority: RoadmapPriorities
  due_date: string
}
