import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const annualPlanFilter = {
  columnName: 'subscription_state',
  filters: [{ id: 'custom_plan_requested', name: 'custom_plan_requested' }],
}

export const useAnnualPlanRequests = () => {
  const result = useFetch<GetRequestInterface<AccountsSettingsInterface>>(
    API.TENANTS,
    'v1',
    {
      params: {
        page_size: 10000,
        ...filterSortPageIntoQuery(undefined, [annualPlanFilter]),
      },
    },
  )

  return {
    ...result,
    annualPlanRequests: result?.data?.results.filter(
      r => r.subscription_state?.id === 'custom_plan_requested',
    ),
  }
}
