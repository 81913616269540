import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon/Icon'
import { getColor } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

const Spinner = styled(Icon)<{ color: string }>`
  margin-left: 8px;
  width: 24px;
  height: 24px;
  color: ${props => props.color};
`

const ButtonInner = styled.button<Props>`
  background: ${props => {
    let color: Color

    if (props.disabled && props.styles !== 'text') {
      color = Color.GREY_20_OPAQUE_90
    } else if (props.styles === 'secondary') {
      color = Color.PINK
    } else if (props.styles === 'tertiary') {
      color = Color.BACKGROUND
    } else if (props.styles === 'dangerous') {
      color = Color.RED
    } else if (props.styles === 'tertiary_v2') {
      color = Color.LIGHT_BLUE_OPAQUE_10
    } else if (props.styles === 'text') {
      return 'none'
    } else {
      color = Color.BLUE
    }
    return getColor(props.theme, color)
  }};
  border: ${props => {
    if (props.disabled) {
      return getColor(props.theme, Color.GREY_20_OPAQUE_90)
    }
    if (props.styles === 'tertiary') {
      return `1px solid ${Token.color.blue}`
    }
    return 'none'
  }};
  border-radius: ${({ square }) => (square ? '4px' : '56px')};
  color: ${props => {
    if (props.styles === 'text' && props.disabled) {
      return Token.color.blue
    }
    if (props.disabled) {
      return Token.color.background
    }
    if (
      props.styles === 'tertiary' ||
      props.styles === 'tertiary_v2' ||
      props.styles === 'text'
    ) {
      return Token.color.blue
    }
    return Token.color.background
  }};
  opacity: ${props => {
    if (props.styles === 'text' && props.disabled) {
      return '0.3'
    }
    return '1'
  }};
  box-shadow: ${props => {
    if (props.styles === 'text') {
      return 'none'
    }
    return '0px 3px 6px rgba(25, 28, 31, 0.06), 0px 1px 2px rgba(25, 28, 31, 0.06),\n    0px -1px 0px rgba(25, 28, 31, 0.04)'
  }};
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  padding: 0 16px;
  font-weight: 600;
  min-width: ${({ square }: Props) => (square ? '100px' : '132px')};
  height: 40px;
  align-self: start;
  justify-self: start;
  display: grid;
  align-content: center;
  justify-content: center;
  &:hover {
    background: ${props => {
      if (props.disabled && props.styles !== 'text') {
        return getColor(props.theme, Color.GREY_20_OPAQUE_90)
      }
      if (props.styles === 'secondary') {
        return Token.color.purple
      }
      if (props.styles === 'tertiary') {
        return Token.color.background
      }
      if (props.styles === 'dangerous') {
        return Token.color.red
      }
      if (props.styles === 'tertiary_v2') {
        return Token.color.greyTone10
      }
      if (props.styles === 'text') {
        return 'none'
      }
      return Token.color.blue_90
    }};
    text-decoration: ${props => {
      if (props.styles === 'text' && !props.disabled) {
        return 'underline'
      }
      return 'none'
    }};
  }

  &:active {
    color: ${props => {
      if (props.disabled) {
        return Token.color.background
      }
      if (props.styles === 'tertiary') {
        return Token.color.blue
      }
      if (props.styles === 'tertiary_v2') {
        return Token.color.blue
      }
      if (props.styles === 'text') {
        return Token.color.blue
      }
      return Token.color.background
    }};
    opacity: 0.9;
  }

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : ''}

  &:focus {
    opacity: 0.9;
  }
`

const Loader = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
`

type styles = 'primary' | 'secondary' | 'tertiary' | 'tertiary_v2' | 'text' | 'dangerous'

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Button type */
  styles?: styles
  /** Loading state flag */
  loading?: boolean
  /** Square type of button flag */
  square?: boolean
  /** If the button is floating, it should have a shadow */
  withShadow?: boolean
}

const Button = ({ loading, styles = 'primary', ...rest }: Props) => {
  const styleToIconColor = useMemo(
    () => ({
      primary: Token.color.background,
      secondary: Token.color.background,
      tertiary: Token.color.blue,
      tertiary_v2: Token.color.blue,
      text: Token.color.blue,
      dangerous: Token.color.background,
    }),
    [],
  )

  return (
    <ButtonInner
      type="button"
      styles={styles}
      {...rest}
      onClick={loading || rest.disabled ? () => {} : rest.onClick}
    >
      {loading ? (
        <Loader>
          <Spinner color={styleToIconColor[styles]} type="Spinner" size="big" />
        </Loader>
      ) : (
        rest.children
      )}
    </ButtonInner>
  )
}

/** @deprecated use UI Kit button */
export default Button
