import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Group,
  IconButton,
  Item,
  ItemSkeleton,
  Skeleton,
  Subheader,
  SubheaderSkeleton,
  Text,
  TextButton,
  Token,
  VStack,
  textChain,
  times,
} from '@revolut/ui-kit'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import { useGetSelectors } from '@src/api/selectors'
import { createInitialPolicies, useGetTimeOffCategories } from '@src/api/timeOff'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { FilterByInterface } from '@src/interfaces/data'
import { TimeOffCategoryInterface } from '@src/interfaces/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import {
  SelectAllCell,
  SelectCell,
} from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { CategoryPopup } from './components/CategoryPopup'

const tableFilters: FilterByInterface[] = []

interface CategoriesListProps {
  categories?: TimeOffCategoryInterface[]
  selectedCount: number
  handleAddCategory: (data: TimeOffCategoryInterface) => void
  refetchCategories: () => void
  setSelectControls: (data: SelectionControls<TimeOffCategoryInterface>) => void
  setSelectedData: (
    data: SelectTableWrapperOnChangeData<TimeOffCategoryInterface>,
  ) => void
}

const CategoriesList = ({
  categories,
  selectedCount,
  handleAddCategory,
  refetchCategories,
  setSelectControls,
  setSelectedData,
}: CategoriesListProps) => {
  const [popupState, setPopupState] = useState<string>()
  return (
    <SelectTableWrapper
      enabled
      filters={tableFilters}
      onChange={setSelectedData}
      onControlsLoaded={setSelectControls}
      tableDataLength={categories?.length || 0}
    >
      <Group>
        <Item>
          <Item.Prefix>
            <SelectAllCell />
            {!!selectedCount && (
              <Text
                color={Token.color.greyTone50}
                ml="s-16"
                variant="caption"
              >{`${selectedCount} selected`}</Text>
            )}
          </Item.Prefix>
          <Item.Side>
            <TextButton fontSize="h6" onClick={() => setPopupState('new')}>
              Create new category
            </TextButton>
          </Item.Side>
        </Item>
        {categories?.map(category => (
          <Item key={category.id} use="label">
            <Item.Prefix>
              <SelectCell data={category} primaryKey="id" />
            </Item.Prefix>
            <Item.Content>
              <Item.Title id="category-title-clickable">{category.name}</Item.Title>
              <Item.Description id="category-description-clickable">
                {category.description}
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <IconButton
                color={Token.color.greyTone50}
                onClick={() => setPopupState(String(category.id))}
                useIcon="Pencil"
              />
            </Item.Side>
          </Item>
        ))}
      </Group>
      <CategoryPopup
        id={popupState}
        onClose={() => setPopupState(undefined)}
        onSuccess={data => {
          setPopupState(undefined)
          refetchCategories()
          if (popupState === 'new') {
            handleAddCategory(data)
          }
        }}
      />
    </SelectTableWrapper>
  )
}

export const SelectPolicies = () => {
  const {
    data: categories,
    isLoading,
    refetch: refetchCategories,
  } = useGetTimeOffCategories()
  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    timeManagementConfig.category,
  )
  const { data: categoriesWithAssigments } = useGetSelectors<TimeOffCategoryInterface>(
    selectorKeys.time_off_policy_categories_with_policies_attached,
  )

  const selectControls = useRef<SelectionControls<TimeOffCategoryInterface>>()
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<TimeOffCategoryInterface>>()

  const stepIsCompleted = checkpoint?.state.id === 'completed'

  useEffect(() => {
    if (!isLoading) {
      const categoriesToBeSelected = categories?.filter(category =>
        categoriesWithAssigments?.find(el => el.id === category.id),
      )
      selectControls.current?.setSelection(categoriesToBeSelected || [])
    }
  }, [categoriesWithAssigments, isLoading])

  const selectedCategoriesIds = useMemo(() => {
    let ids = selectedData?.selectedRowsIds
      ? Array.from(selectedData?.selectedRowsIds).map(id => Number(id))
      : []

    if (selectedData?.isAllSelected) {
      ids =
        categories?.reduce<number[]>((acc, item) => {
          if (!selectedData?.excludeListIds?.has(String(item.id))) {
            acc.push(item.id)
          }

          return acc
        }, []) || []
    }
    return ids
  }, [categories, selectedData])

  const handleAddCategory = (data: TimeOffCategoryInterface) => {
    selectControls.current?.onSelect(String(data.id), false, data)
  }

  if (isLoading) {
    return (
      <PageBody>
        <SubheaderSkeleton>
          <SubheaderSkeleton.Title />
        </SubheaderSkeleton>
        <Group>
          {times(
            index => (
              <ItemSkeleton
                key={index}
                useIcon={<Skeleton radius={Token.radius.r8} size={24} />}
              >
                <ItemSkeleton.Content>
                  <ItemSkeleton.Title />
                  <ItemSkeleton.Description />
                </ItemSkeleton.Content>
              </ItemSkeleton>
            ),
            3,
          )}
        </Group>
      </PageBody>
    )
  }

  return (
    <>
      <PageBody>
        {stepIsCompleted ? (
          <StepCompletedWidget />
        ) : (
          <>
            <Subheader>
              <Subheader.Title>
                Select which types of policies you want to create
              </Subheader.Title>
            </Subheader>
            <VStack gap="s-16">
              <Text color={Token.color.greyTone50} variant="caption">
                We’ll automatically create some policies for you to customise in the next
                step, for now we just need to know which types of policies you want
              </Text>
              <CategoriesList
                categories={categories}
                selectedCount={selectedCategoriesIds.length}
                handleAddCategory={handleAddCategory}
                refetchCategories={refetchCategories}
                setSelectControls={controls => {
                  selectControls.current = controls
                }}
                setSelectedData={setSelectedData}
              />
            </VStack>
          </>
        )}
      </PageBody>

      <OnboardingActions
        config={timeManagementConfig}
        currentStep="Select policies"
        isForm={false}
        isLastStep={false}
        updateSteps
        disableNext={!selectedCategoriesIds.length && !stepIsCompleted}
        pendingNext={false}
        nextButtonLabel={
          selectedCategoriesIds.length && !stepIsCompleted
            ? textChain('Next', selectedCategoriesIds.length)
            : 'Next'
        }
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CONFIGURATION}
        onBeforeSubmit={
          stepIsCompleted ? undefined : () => createInitialPolicies(selectedCategoriesIds)
        }
      />
    </>
  )
}
