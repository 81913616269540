import React from 'react'
import { ActionButton, Color, Flex } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FieldOptions, Statuses } from '@src/interfaces'
import { getEmployeeAccessRequests } from '@src/api/accessRequests'
import { AccessRequestInterface, AccessTypes } from '@src/interfaces/accessRequest'
import { FetchDataQueryInterface, RowInterface } from '@src/interfaces/data'
import {
  accessRequestAccessType,
  accessRequestApproverColumn,
  accessRequestDatabaseColumn,
  accessRequestDateFromColumn,
  accessRequestDateToColumn,
  accessRequestPermissionColumn,
  accessRequestReasonColumn,
  accessRequestSourceColumn,
  accessRequestStatusColumn,
  accessRequestTool,
} from '@src/constants/columns/accessRequest'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { getColor } from '@src/styles/colors'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

interface Props {
  data: {
    id?: any
    full_name: string
    field_options: FieldOptions
  }
}

const ROW: RowInterface<AccessRequestInterface> = {
  highlight: (data, theme) => {
    if (data.status === Statuses.pending) {
      return getColor(theme, Color.RED_OPAQUE_20)
    }

    if (data.status === Statuses.expired) {
      return getColor(theme, Color.GREY_20_OPAQUE_50)
    }

    return ''
  },
  linkToForm: ({ access_type, access_request_id, employee, team }) => {
    switch (access_type.id) {
      case AccessTypes.METABASE_USER:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.GENERAL, {
            id: access_request_id,
            employeeId: employee.id,
          }),
        )
      case AccessTypes.METABASE_TEAM:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL, {
            id: access_request_id,
            teamId: team.id,
          }),
        )
      case AccessTypes.LOOKER_MODEL:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL, {
            id: access_request_id,
            employeeId: employee.id,
          }),
        )
      case AccessTypes.LOOKER_FOLDER:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_FOLDER, {
            id: access_request_id,
            employeeId: employee.id,
          }),
        )
      default:
        return '/'
    }
  },
  cells: [
    {
      ...accessRequestTool,
      width: 100,
    },
    {
      ...accessRequestAccessType,
      width: 150,
    },
    {
      ...accessRequestDatabaseColumn,
      width: 150,
    },
    {
      ...accessRequestApproverColumn,
      width: 150,
    },
    {
      ...accessRequestSourceColumn,
      width: 150,
    },
    {
      ...accessRequestPermissionColumn,
      width: 100,
    },
    {
      ...accessRequestDateFromColumn,
      width: 100,
    },
    {
      ...accessRequestDateToColumn,
      width: 100,
    },
    {
      ...accessRequestReasonColumn,
      width: 240,
    },
    {
      ...accessRequestStatusColumn,
      width: 200,
    },
  ],
}

export const DataAccess = ({ data }: Props) => {
  const initialFilter = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const table = useTable<AccessRequestInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getEmployeeAccessRequests(requestData, data.id),
    },
    initialFilter,
  )

  const canCreateRequests = data?.field_options?.permissions?.includes(
    PermissionTypes.CreateAccessRequests,
  )

  const handleNewRow = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.ROUTER, { employeeId: `${data.id}` }),
      {
        initialValues: { employee: { id: data.id, name: data.full_name } },
      },
    )
  }

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
        />
      </Flex>
      {canCreateRequests && (
        <Flex mb="s-16">
          <ActionButton onClick={handleNewRow} useIcon="Plus">
            New Data Access Request
          </ActionButton>
        </Flex>
      )}
      <AdjustableTable<AccessRequestInterface>
        name={TableNames.EmployeeAccessRequests}
        useWindowScroll
        dataType="Access Request"
        row={ROW}
        {...table}
        noDataMessage="All access requests will appear here."
      />
    </TableWrapper>
  )
}
