import React from 'react'
import styled from 'styled-components'
import { colorGetter, getColor } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'

interface TabContainerProps {
  className?: string
  /** Callback for when you click tab */
  onChange: (tab: any) => void
  /** Value of selected tab */
  value: any
  /** A list of values for tabs */
  tabs: Readonly<any[]>
}

const Container = styled.div`
  border: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_90)};
  font-size: 14px;
  font-weight: 600;
  background-color: ${Token.color.background};
  border-radius: 4px;
  flex-grow: 0;
`

const Tab = styled.button<{ active: string }>`
  padding: 6px 10px;
  line-height: 18px;
  z-index: ${props =>
    props.active ? props.theme.zIndex.main : props.theme.zIndex.belowMain};
  position: relative;
  font-weight: inherit;
  text-transform: capitalize;
  transition: transform 150ms ease-in-out;
  transform: ${props => (props.active ? 'scale(1.05)' : 'scale(1)')};
  color: ${props => (props.active ? Token.color.background : Token.color.greyTone50)};
  background-color: ${props =>
    props.active ? Token.color.blue : Token.color.background};
  border: 1px solid ${props => (props.active ? Token.color.blue : 'transparent')};
  flex-grow: 0;
  border-radius: 4px;

  &:active {
    color: ${props =>
      getColor(props.theme, props.active ? Color.BACKGROUND : Color.GREY_20_OPAQUE_90)};
    transform: scale(0.99);
  }

  &:hover {
    background-color: ${props =>
      props.active ? Token.color.blue : Token.color.greyTone2};
  }
`

export const ButtonGroup = ({ className, value, tabs, onChange }: TabContainerProps) => (
  <Container className={className}>
    {tabs.map((tab, i) => {
      return (
        <Tab
          key={`${tab}_${i}`}
          onClick={e => {
            e.preventDefault()
            onChange && onChange(tab)
          }}
          active={value === tab ? 'active' : ''}
        >
          {tab}
        </Tab>
      )
    })}
  </Container>
)
