import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const PayrollIntro = () => {
  return (
    <>
      <OnboardingIntro
        videoUrl="/intros/Intro%20-%20Payroll.mov"
        videoPosterUrl="/intros/Intro%20-%20Payroll.png"
      >
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="onboardingV2.payroll.intro.tips.title"
              defaultMessage="Handle all payroll information in one place"
            />
          }
          text={
            <FormattedMessage
              id="onboardingV2.payroll.intro.tips.text"
              defaultMessage="From salaries to bonuses, handle everything that impacts payroll in one central place"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.define.title"
                  defaultMessage="Define your payroll cycles"
                />
              ),
              icon: 'Document',
              text: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.define.text"
                  defaultMessage="Specify your payroll schedule by determining whether it operates on a monthly or weekly cycle and identifying the starting day of the cycle."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.keepTrack.title"
                  defaultMessage="Keep track of all changes in one place"
                />
              ),
              icon: 'People',
              text: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.keepTrack.text"
                  defaultMessage="You can monitor any changes affecting payroll in the payroll cycle screen, ensuring there are no unexpected issues when processing payroll."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.export.title"
                  defaultMessage="Export your change report"
                />
              ),
              icon: 'BalanceSheet',
              text: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.export.text"
                  defaultMessage="As the payroll cycle approaches its end, review all information thoroughly and then download the change report. This report can be sent to the person responsible for processing your company's payroll."
                />
              ),
            },
          ]}
        />
      </OnboardingIntro>
    </>
  )
}
