import React from 'react'
import { CircleButton, Grid, IconName } from '@revolut/ui-kit'
import { VisualisationType } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/VisualisationSidebar'

const visualisationOptions: { type: VisualisationType; name: string; icon: IconName }[] =
  [
    {
      type: 'line',
      name: 'Line',
      icon: '16/Timeline',
    },
    {
      type: 'bar',
      name: 'Bar',
      icon: 'BarChart',
    },
    {
      type: 'combo',
      name: 'Combo',
      icon: 'Invest',
    },
    {
      type: 'row',
      name: 'Row',
      icon: 'Paragraph',
    },
    {
      type: 'pie',
      name: 'Pie',
      icon: 'PieChart',
    },
    {
      type: 'counter',
      name: 'Value',
      icon: 'Target',
    },
  ]

interface VisualisationSidebarTypeSelectProps {
  selectedType?: VisualisationType
  onChange: (chartType: VisualisationType) => void
}

export const VisualisationSidebarTypeSelect = ({
  selectedType = 'line',
  onChange,
}: VisualisationSidebarTypeSelectProps) => {
  return (
    <Grid gap="s-16" columns={visualisationOptions.length} alignSelf="center" mt="s-16">
      {visualisationOptions.map(option => (
        <CircleButton
          variant={selectedType === option.type ? 'primary' : 'widget-action'}
          useIcon={option.icon}
          key={option.type}
          onClick={() => onChange(option.type)}
        >
          {option.name}
        </CircleButton>
      ))}
    </Grid>
  )
}
