import React, { useEffect, useMemo, useState } from 'react'
import { ActionButton, Cell, InputGroup, Text } from '@revolut/ui-kit'

import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { EmployeeEmailTemplateInterface } from '@src/interfaces/employeeEmails'
import { useSenderOptions } from './common'
import HideIfGmailDisabled from '@components/HideIfGmailDisabled/HideIfGmailDisabled'
import { useGetCandidateSettings } from '@src/api/settings'

interface EmailFormProps {
  userEmail?: string
  onShowPlaceholders?: () => void
  noInvalidPlaceholders?: boolean
}

const EmailForm = ({
  userEmail,
  onShowPlaceholders,
  noInvalidPlaceholders,
}: EmailFormProps) => {
  const { values } = useLapeContext<EmployeeEmailTemplateInterface>()
  const [showCCFields, setShowCCFields] = useState(
    !!values.recipients_cc?.length || !!values.recipients_bcc?.length,
  )
  const { data: candidateSettings } = useGetCandidateSettings()

  useEffect(() => {
    if (
      !values.attachments?.length ||
      values.attachments.some(attachment => !attachment.name)
    ) {
      values.attachments = null
    }
  }, [])

  const senderOptions = useSenderOptions(userEmail)

  useEffect(() => {
    if (candidateSettings?.enable_gmail_integration === false) {
      const senderType = senderOptions.find(item => item.value?.id === 'generic')?.value

      if (senderType) {
        values.sender_type = senderType
      }
    }
  }, [candidateSettings?.enable_gmail_integration, senderOptions])

  const nonPrefilledPlaceholders = useMemo(() => {
    return values.email_body?.match(/{([^}]+)}/g)
  }, [values.email_body])

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput
          name="sender_type"
          label="Send as"
          options={senderOptions}
          value={
            senderOptions.find(item => item.value.id === values.sender_type?.id)?.value
          }
          onChange={val => {
            if (val) {
              values.sender_type = val
            }
          }}
          disabled={!candidateSettings?.enable_gmail_integration}
        >
          {option => (
            <>
              <Text use="p">{option.value.name}</Text>
              <Text use="p" variant="caption" mt="s-4" color="grey-tone-50">
                {option.value.subtitle}
              </Text>
            </>
          )}
        </LapeRadioSelectInput>
        <HideIfGmailDisabled>
          {!showCCFields && (
            <ActionButton onClick={() => setShowCCFields(true)}>Add cc/bcc</ActionButton>
          )}
          {showCCFields && (
            <>
              <LapeNewMultiSelect<{ id: string }>
                name="recipients_cc"
                placeholder="Cc"
                allowCustomOptions
                customOptionLabel="Add email:"
                value={
                  values.recipients_cc?.map(email => ({
                    label: email,
                    value: { id: email },
                  })) || []
                }
                onChange={recipients => {
                  values.recipients_cc = recipients.map(recipient => recipient.value.id)
                }}
              />
              <LapeNewMultiSelect<{ id: string }>
                name="recipients_bcc"
                placeholder="Bcc"
                allowCustomOptions
                customOptionLabel="Add email:"
                value={
                  values.recipients_bcc?.map(email => ({
                    label: email,
                    value: { id: email },
                  })) || []
                }
                onChange={recipients => {
                  values.recipients_bcc = recipients.map(recipient => recipient.value.id)
                }}
              />
            </>
          )}
        </HideIfGmailDisabled>
        <LapeNewInput name="subject" label="Subject line" required />
        <LapeHTMLEditor
          name="email_body"
          placeholder="Email body"
          height={210}
          required
          addMarginToParagraphs
        />
        {!!nonPrefilledPlaceholders?.length && noInvalidPlaceholders && (
          <ActionWidget
            title="You have non-prefilled placeholders"
            text={`Placeholders ${nonPrefilledPlaceholders.join(
              ', ',
            )} have not been prefilled with actual data. Please replace it manually`}
          />
        )}
        <InputGroup>
          {onShowPlaceholders && (
            <Cell
              use="button"
              type="button"
              variant="disclosure"
              onClick={onShowPlaceholders}
            >
              View placeholders
            </Cell>
          )}
          <HideIfGmailDisabled>
            <LapeFileUploader name="attachments" label="Attach files" multiple />
          </HideIfGmailDisabled>
        </InputGroup>
      </InputGroup>
    </>
  )
}

export default EmailForm
