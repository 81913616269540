import { Item, Avatar, chain, Color, Text, BREAKPOINTS, HStack } from '@revolut/ui-kit'
import {
  getEmployeeDetailedPayrollReport,
  getEmployeePayrollReports,
  useEmployerPayrollReportStats,
} from '@src/api/employees'
import { TablePreview } from '@components/TablePreview/TablePreview'
import { formatMoneyWithCode, formatPeriod } from '@src/utils/format'
import React, { useState } from 'react'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useParams } from 'react-router-dom'
import {
  PayCycleDetailedReportInterface,
  PayCycleReportInterface,
} from '@src/interfaces/payroll'
import SideBar from '@components/SideBar/SideBar'
import { PaycyclePreview } from '@components/PaycyclePreview/PaycyclePreview'
import { getStatusColor } from '@components/CommonSC/General'
import { Statuses } from '@src/interfaces'
import Stat from '@components/Stat/Stat'
import PaycyclePreviewLoading from '@components/PaycyclePreview/PaycyclePreviewLoading'
import { FetchDataQueryInterface } from '@src/interfaces/data'

export const Payroll = () => {
  const { id: employeeId } = useParams<{ id: string }>()
  const [selectedReportData, setSelectedReportData] =
    useState<PayCycleDetailedReportInterface | null>(null)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [currency, setCurrency] = useState<string>()

  const { data: stats } = useEmployerPayrollReportStats(employeeId)

  const getData = async (requestData: FetchDataQueryInterface) => {
    const res = await getEmployeePayrollReports(employeeId)(requestData)
    setCurrency(
      res?.data.results[0]?.pay_cycle?.pay_group?.country?.national_currency?.iso_code,
    )
    return res
  }

  const handleSidebarOpen = async (data: PayCycleReportInterface) => {
    if (data.id === selectedReportData?.id) {
      return
    }
    setSelectedReportData(null)
    setSidebarOpen(true)
    const detailedData = await getEmployeeDetailedPayrollReport(
      `${data.pay_cycle.id}`,
      employeeId,
    )

    setSelectedReportData(detailedData.data)
  }

  const handleSidebarClose = () => {
    setSidebarOpen(false)
    setSelectedReportData(null)
  }

  return (
    <>
      <TableWrapper width={BREAKPOINTS.md}>
        <HStack space="s-32" mb="s-6">
          <Stat
            label="Total gross pay"
            val={formatMoneyWithCode(stats?.total_gross_pay, currency)}
          />
          <Stat
            label="Total net pay"
            val={formatMoneyWithCode(stats?.total_net_pay, currency)}
          />
          <Stat
            label="Total deductions pay"
            val={formatMoneyWithCode(stats?.total_deductions_pay, currency)}
          />
        </HStack>
        <TablePreview
          title="Pay cycles"
          detailsTitle="Net pay"
          api={{
            getItems: getData,
          }}
          emptyState={{
            title: 'No pay cycles to show',
            icon: 'Credit',
          }}
          row={paycycleData => {
            const showItemDescription =
              !!paycycleData.issue_count ||
              !!paycycleData.absence_count ||
              !!paycycleData.addition_count ||
              !!paycycleData.change_count

            return (
              <Item
                use="button"
                onClick={() => handleSidebarOpen(paycycleData)}
                key={paycycleData.id}
              >
                <Item.Avatar>
                  <Avatar useIcon="Credit" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    {chain(
                      formatPeriod(
                        new Date(paycycleData.pay_cycle.start_date),
                        new Date(paycycleData.pay_cycle.end_date),
                      ),
                      <Text
                        color={getStatusColor(
                          paycycleData.pay_cycle.status.id as Statuses,
                        )}
                      >
                        {paycycleData.pay_cycle.status.name}
                      </Text>,
                    )}
                  </Item.Title>
                  {showItemDescription && (
                    <Item.Description>
                      {chain(
                        !!paycycleData.issue_count && (
                          <Text color={Color.ORANGE}>
                            {paycycleData.issue_count} issues
                          </Text>
                        ),
                        !!paycycleData.change_count &&
                          `${paycycleData.change_count} changes`,
                        !!paycycleData.absence_count &&
                          `${paycycleData.absence_count} absences`,
                        !!paycycleData.addition_count &&
                          `${paycycleData.addition_count} additions`,
                      )}
                    </Item.Description>
                  )}
                </Item.Content>
                <Item.Side>
                  <Item.Value>
                    {paycycleData.total_net_pay
                      ? formatMoneyWithCode(
                          paycycleData.total_net_pay,
                          paycycleData.pay_cycle.pay_group.country.national_currency
                            .iso_code,
                        )
                      : '-'}
                  </Item.Value>
                  {paycycleData.total_net_pay && (
                    <Item.Value
                      variant="secondary"
                      color={paycycleData.total_net_pay ? Color.GREEN : Color.FOREGROUND}
                    >
                      Paid
                    </Item.Value>
                  )}
                </Item.Side>
              </Item>
            )
          }}
        />
      </TableWrapper>
      <SideBar
        variant="wide"
        title={selectedReportData?.employee?.full_name}
        onClose={handleSidebarClose}
        isOpen={sidebarOpen}
      >
        {selectedReportData ? (
          <PaycyclePreview data={selectedReportData} />
        ) : (
          <PaycyclePreviewLoading />
        )}
      </SideBar>
    </>
  )
}
