import React from 'react'
import { Box, ItemSkeleton } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useTableReturnType } from '@src/components/Table/hooks'
import { Virtuoso } from 'react-virtuoso'
import { HiringStage } from '@src/pages/Forms/SpecialisationHiringProcess/components/HiringStage'

const Scroller = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <Box ref={ref} {...props} />
})

type CompanyHiringStagesProps = {
  disabled: boolean
  disableSelectingCVScreeningStages: boolean
  scrollRef: React.RefObject<HTMLDivElement>
  selected: HiringProcessInterface[]
  table: useTableReturnType<HiringProcessInterface, {}, {}>
  onFetchNextPage: () => void
  onChangeSelected: (selected: HiringProcessInterface[]) => void
}

export const CompanyHiringStages = ({
  disabled,
  disableSelectingCVScreeningStages,
  scrollRef,
  selected,
  table,
  onFetchNextPage,
  onChangeSelected,
}: CompanyHiringStagesProps) => {
  return (
    <Box pb="s-32">
      <Virtuoso<HiringProcessInterface>
        data={table.data}
        customScrollParent={scrollRef?.current || undefined}
        overscan={150}
        // https://github.com/petyosi/react-virtuoso/issues/341
        initialItemCount={table.data.length - 1}
        endReached={() => {
          if (table.count > table.data.length) {
            onFetchNextPage()
          }
        }}
        components={{ Scroller }}
        itemContent={(_, hiringStage) => {
          return (
            <HiringStage
              key={hiringStage.id}
              hiringStage={hiringStage}
              disableSelectingCVScreeningStages={disableSelectingCVScreeningStages}
              disabled={disabled}
              selected={selected}
              onSelect={onChangeSelected}
            />
          )
        }}
      />
      {table.loading && <ItemSkeleton />}
    </Box>
  )
}
