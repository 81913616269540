import React, { useState } from 'react'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import { useTable } from '@src/components/Table/hooks'
import {
  hiringProcessesTableRequests,
  useGetSpecialisationHiringProcess,
} from '@src/api/hiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Box, MoreBar, TabBar, TableWidget, VStack } from '@revolut/ui-kit'
import { HiringProcessTable } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessTable'
import { CloneHiringProcess } from './CloneHiringProcess'

export const HiringProcessesTable = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const [tab, setTab] = useState<string | undefined>(
    String(values.default_hiring_process.id),
  )
  const { data: hiringProcess, isLoading } = useGetSpecialisationHiringProcess(
    values.id,
    tab,
  )
  const table = useTable<SpecialisationHiringProcess>(
    hiringProcessesTableRequests(values.id),
    [
      {
        columnName: 'status',
        filters: [{ id: 'active', name: 'active' }],
      },
    ],
  )
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.ChangeHiringProcess)
  const handleEdit = () => {
    if (hiringProcess?.id) {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL, {
          hiringProcessId: hiringProcess.id,
          specialisationId: values.id,
        }),
      )
    }
  }
  return (
    <VStack gap="s-8" pt="s-8">
      <Box px="s-8">
        <TabBar behaviour="scroll" value={tab} onChange={value => setTab(value)}>
          {table.data.map(({ id, name, is_default }) => (
            <TabBar.Item
              key={id}
              to={String(id)}
              useIcon={is_default ? 'StarFilled' : undefined}
            >
              {name}
            </TabBar.Item>
          ))}
        </TabBar>
      </Box>
      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            <CloneHiringProcess
              hiringProcess={values.default_hiring_process}
              name={values.name}
              variant="more-bar"
            />
            {canEdit && (
              <MoreBar.Action useIcon="Pencil" onClick={handleEdit} pending={isLoading}>
                Edit process
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <HiringProcessTable
            hiringProcessRounds={hiringProcess?.hiring_process_stages}
            loading={isLoading}
          />
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
