import React, { useEffect, useMemo, useState } from 'react'
import {
  Absolute,
  Portal,
  Text,
  Popup,
  Button,
  Header,
  StatusPopup,
  BREAKPOINTS,
  Widget,
  VStack,
  Token,
  H4,
  ActionButton,
} from '@revolut/ui-kit'

import QuestionBox from '@src/pages/Engagement/QuestionBox'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { getQuestionsQueue, useEngagementWelcomePage } from '@src/api/engagement'
import Loader from '@components/CommonSC/Loader'
import {
  EngagementQuestionInterface,
  EngagementQuestionVoicesInterface,
} from '@src/interfaces/engagement'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'
import { useQuery } from '@src/utils/queryParamsHooks'

type Props = {
  preview?: { question: EngagementQuestionInterface; onClose: () => void }
}
const EngagementQuestionPage = ({ preview }: Props) => {
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState<EngagementQuestionVoicesInterface[]>()
  const [questionIndex, setQuestionIndex] = useState<number>(0)
  const [submitLeavePageOpen, setSubmitLeavePageOpen] = useState<boolean>(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState<boolean>(false)
  const [isWelcomePageDismissed, setIsWelcomePageDismissed] = useState(false)
  const [welcomePagesSeen, setWelcomePagesSeen] = useLocalStorage<number[]>(
    LocalStorageKeys.ENGAGEMENT_WELCOME_PAGES_SEEN,
    [],
  )

  const { query } = useQuery<{ survey_id: string; is_test: string }>()
  const surveyId = query.survey_id
  const isTestSurvey = query.is_test === 'true'

  const fetchQuestionsOfTheDay = async () => {
    try {
      const result = await getQuestionsQueue(surveyId, isTestSurvey)
      if (result.data?.length) {
        setQuestions(result.data)
      } else {
        handleClose()
      }
    } catch (e) {
      handleClose()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (preview) {
      setQuestions([{ id: 0, question: preview.question }])
      setLoading(false)
    } else if (surveyId) {
      fetchQuestionsOfTheDay()
    }
  }, [])

  const { data: engagementSurveyData, isLoading: engagementSurveyDataLoading } =
    useEngagementWelcomePage({ surveyId, isTestSurvey })

  const currentQuestionIndex = useMemo(() => {
    const serverSideQuestionIndex = engagementSurveyData?.completed_questions || 0

    return questionIndex + serverSideQuestionIndex
  }, [engagementSurveyData, questionIndex])

  const totalQuestions = engagementSurveyData?.total_questions || questions?.length || 0

  const showWelcomePage =
    engagementSurveyData?.id &&
    !isWelcomePageDismissed &&
    !welcomePagesSeen.includes(engagementSurveyData.id)

  const handleComplete = () => {
    setSuccessPopupOpen(true)
  }

  const handleClose = () => {
    goBack(ROUTES.MAIN, {}, true)
  }

  const handleWelcomePageDismiss = () => {
    if (engagementSurveyData?.id) {
      setWelcomePagesSeen([...welcomePagesSeen, engagementSurveyData.id])
    }

    setIsWelcomePageDismissed(true)
  }

  if (loading || !questions || engagementSurveyDataLoading) {
    return (
      <PageWrapper height="100vh">
        <Loader />
      </PageWrapper>
    )
  }
  if (preview) {
    return (
      <Portal>
        <Absolute top={0}>
          <PageWrapper height="100vh" maxWidth={BREAKPOINTS.xl} pl="s-64">
            <PageHeader
              title="Preview"
              subtitle={`Click the back arrow to return to editing`}
              backUrl={pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW, {
                id: questions[0].id,
              })}
              onClickBack={() => preview.onClose()}
              hideGlobalSearch
            />
            <PageBody data-testid="engagement-container">
              <QuestionBox
                onComplete={handleComplete}
                questions={questions}
                onClose={preview.onClose}
                isPreview={!!preview}
                questionIndex={questionIndex}
                setQuestionIndex={setQuestionIndex}
              />
            </PageBody>
          </PageWrapper>
        </Absolute>
      </Portal>
    )
  }
  return (
    <PageWrapper height="100vh" maxWidth={BREAKPOINTS.xl} pl="s-64">
      <PageHeader
        title={
          <H4 color={Token.color.foreground}>
            {engagementSurveyData?.title || 'Revolut Voices'}
          </H4>
        }
        subtitle={
          showWelcomePage
            ? `${totalQuestions} questions`
            : `Question ${currentQuestionIndex + 1} of ${totalQuestions}`
        }
        backUrl={pathToUrl(ROUTES.MAIN)}
        onClickBack={() => setSubmitLeavePageOpen(true)}
        hideGlobalSearch
      />
      <PageBody data-testid="engagement-container">
        {showWelcomePage ? (
          <VStack space="s-12" mb="s-32">
            <Widget p="s-16">
              <HTMLEditor
                style={{ backgroundColor: Token.color.widgetBackground }}
                value={
                  engagementSurveyData?.instructions ||
                  'Click the button below to start the survey.'
                }
                onChange={() => {}}
                readOnly
                height="auto"
              />
            </Widget>
            <ActionButton
              variant="accent"
              onClick={handleWelcomePageDismiss}
              useIcon="ArrowThinRight"
            >
              Begin survey
            </ActionButton>
          </VStack>
        ) : (
          <QuestionBox
            onComplete={handleComplete}
            questions={questions}
            onClose={handleClose}
            questionIndex={questionIndex}
            setQuestionIndex={setQuestionIndex}
          />
        )}
      </PageBody>
      <Popup
        open={submitLeavePageOpen}
        onClose={() => setSubmitLeavePageOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>
            Are you sure you want to leave the survey unfinished?
          </Header.Title>
        </Header>
        <Text use="p" variant="caption" color={Token.color.greyTone50}>
          We will record all your current answers. The survey will remain as pending task
          in your dashboard until it’s completed.
        </Text>
        <Popup.Actions horizontal>
          <Button
            variant="secondary"
            onClick={e => {
              e.stopPropagation()
              setSubmitLeavePageOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button elevated onClick={handleClose}>
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          handleClose()
        }}
        // @ts-ignore this prop exists, TS definition is lying
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Thanks for your feedback!</StatusPopup.Title>
        <StatusPopup.Description>
          Your answers were successfully sent.
        </StatusPopup.Description>
      </StatusPopup>
    </PageWrapper>
  )
}

export default EngagementQuestionPage
