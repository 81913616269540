import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const DocumentsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.documents.intro.tips.title"
            defaultMessage="Organise and manage all your documents in secure place"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.documents.intro.tips.text"
            defaultMessage="Upload all employee documents, store company policies and generate eSignatures."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.upload.title"
                defaultMessage="Upload employee documents"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.upload.text"
                defaultMessage="Our documents feature offers a central hub for storing all employee-related documents. Each document is assigned to a specific employee and categorised into customisable categories. You can also set access permissions for each document. By default, documents are accessible to HR managers and the respective employee."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.request.title"
                defaultMessage="Request documents & signatures"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.request.text"
                defaultMessage="Our request feature enables you to ask one or multiple employees to upload or sign documents, such as passports or NDAs. This can be done individually or in bulk."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
