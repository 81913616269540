import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { CreateRoadmapLocationState } from './common/AddRoadmapMoreBarAction'
import { RoadmapPriorities } from '@src/interfaces/roadmaps'
import { navigateTo } from '@src/actions/RouterActions'
import { useLocation } from 'react-router-dom'
import { useGetReviewCycle } from '@src/api/reviewCycles'
import { NewSectionCardSkeleton } from '@src/components/NewSelectionCard/NewSectionCardSkeleton'
import { capitalize } from 'lodash'

const Title = () => (
  <FormattedMessage id="roadmapCreate.page.title" defaultMessage="Add new roadmap" />
)

const Description = () => (
  <FormattedMessage
    id="roadmapCreate.page.description"
    defaultMessage="Select the roadmap level"
  />
)

export const CreateRoadmapLevelSelect = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const location = useLocation<{ roadmapSelectCycleID: string }>()
  const { data: reviewCycle, isLoading } = useGetReviewCycle(
    location.state.roadmapSelectCycleID,
  )
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const backUrl = ROUTES.PERFORMANCE.GOALS.ROADMAPS

  const user = useSelector(selectUser)

  const handleNew = async (entity: EntityTypes) => {
    const type: CreateRoadmapLocationState['genericRoadmapCreationType'] =
      entity === EntityTypes.companyV2
        ? 'company'
        : entity === EntityTypes.department
        ? 'department'
        : 'team'
    const state: CreateRoadmapLocationState = {
      backUrl,
      name: capitalize(`${type} roadmap`),
      genericRoadmapCreationType: type,
      initialValues: {
        // we need to set null values to clean up previous state
        // these null values are removed from the API payload before sending
        // @ts-expect-error
        team: null,
        // @ts-expect-error
        employee: null,
        // @ts-expect-error
        department: null,
        // @ts-expect-error
        review_cycle: reviewCycle,
        // @ts-expect-error
        start_date: reviewCycle?.start_date_time,
        due_date: reviewCycle?.end_date_time,
        is_company: entity === EntityTypes.companyV2,
        owner: { id: user.id, name: user.display_name },
        priority: { id: RoadmapPriorities.MAJOR, name: RoadmapPriorities.MAJOR },
      },
    }
    navigateTo(ROUTES.FORMS.ROADMAP.NEW, state)
  }

  return (
    <PageWrapper>
      <PageHeader
        title={<Title />}
        subtitle={<Description />}
        backUrl={pathToUrl(backUrl)}
      />

      <PageBody>
        <Group>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <NewSelectionCard
                /* @ts-expect-error CompanyFilled icon works but it's added to types in ui-kit v14 and there is a significant effort needed to upgade */
                icon={isCommercial ? 'CompanyFilled' : 'LogoRevolut'}
                title="Company roadmap"
                data-testid="company-goal-select"
                onClick={() => handleNew(EntityTypes.companyV2)}
              />
              <NewSelectionCard
                icon="Bank"
                title="Department roadmap"
                data-testid="department-goal-select"
                onClick={() => handleNew(EntityTypes.department)}
              />
              <NewSelectionCard
                icon="People"
                title="Team roadmap"
                data-testid="team-goal-select"
                onClick={() => handleNew(EntityTypes.teams)}
              />
            </>
          )}
        </Group>
      </PageBody>
    </PageWrapper>
  )
}

const Skeleton = () => {
  return (
    <>
      <NewSectionCardSkeleton />
      <NewSectionCardSkeleton />
      <NewSectionCardSkeleton />
    </>
  )
}
