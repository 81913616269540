import { FinalGrade } from '@src/interfaces/performance'
import { Token } from '@revolut/ui-kit'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { GradesMapInterface } from '@src/utils/grades'

export enum ScorecardTypes {
  probation = 'probation',
  CV = 'CV',
  performance = 'performance',
  interview = 'interview',
  home_task = 'home_task',
  PIP = 'PIP',
  PIP_V2 = 'pip',
}

export enum ScorecardRadioValues {
  unsatisfactory = 'unsatisfactory',
  below_expectations = 'below_expectations',
  meets_expectations = 'meets_expectations',
  above_expectations = 'above_expectations',
  exceptional = 'exceptional',
  neutral = 'neutral',
}

export const scorecardRadioHeaders = {
  [ScorecardRadioValues.unsatisfactory]: 'Unsatisfactory',
  [ScorecardRadioValues.below_expectations]: 'Average –',
  [ScorecardRadioValues.meets_expectations]: 'Average +',
  [ScorecardRadioValues.above_expectations]: 'Strong',
  [ScorecardRadioValues.exceptional]: 'Exceptional',
  [ScorecardRadioValues.neutral]: "Don't know",
}

export const FinalGradeToString = {
  [FinalGrade.None]: '',
  [FinalGrade.Empty]: '-',
  [FinalGrade.Poor]: 'Unsatisfactory',
  [FinalGrade.AverageMinus]: 'Average -',
  [FinalGrade.AveragePlus]: 'Average +',
  [FinalGrade.Strong]: 'Strong',
  [FinalGrade.Exceptional]: 'Exceptional',
}

export const FinalGradeToShortString = {
  ...FinalGradeToString,
  [FinalGrade.AverageMinus]: 'Avg -',
  [FinalGrade.AveragePlus]: 'Avg +',
}

export const getColorsAndGrade = (grade?: FinalGrade, gradesMap?: GradesMapInterface) => {
  const FinalGradeMap = gradesMap || FinalGradeToString
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: Token.color.red,
        // FIXME: remove hardcode
        background: '#FEE4E2',
        grade: FinalGradeMap[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: Token.color.greyTone10,
        grade: FinalGradeMap[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.teal,
        background: '#E6F8F4',
        grade: FinalGradeMap[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.background,
        background: '#80DEC7',
        grade: FinalGradeMap[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: FinalGradeMap[FinalGrade.Exceptional],
      }
    default:
      return {
        color: Token.color.foreground,
        background: Token.color.background,
        grade: 'N/A',
      }
  }
}

export enum ScorecardFieldTypes {
  thumbs = 'thumbs',
  textfield = 'text_field',
}

export interface ScorecardThumbsSection {
  card_type: ScorecardFieldTypes.thumbs
  text: string
  info?: {
    title: string
    values: {
      title: string
      value: string
    }[]
  }
  value?:
    | ScorecardRadioValues
    | Map<
        ScorecardRadioValues,
        {
          name: string
          date: string
        }[]
      >
}

export interface ScorecardTextfieldSection {
  card_type: ScorecardFieldTypes.textfield
  text: string
  value?: number
}

export type ScorecardFields = ScorecardThumbsSection | ScorecardTextfieldSection
export interface ScorecardSections {
  title: string
  score_label?: PerformanceScores
  score?: number
  sub_title: string
  fields: ScorecardFields[]
}

export interface ScorecardFeedback {
  full_name: string
  reviewer_id: number
  editable: boolean
  reviewer_relation: 'line_manager' | 'self' | 'team_mate' | 'other'
  score_label: PerformanceScores
  score?: number
  scorecard_id: number
  submitted_date: string
  text: string
}

export enum PerformanceScores {
  UNSATISFACTORY = 'unsatisfactory',
  PROBATION_PASSED = 'probation_passed',
  PROBATION_FAILED = 'probation_failed',
  BELOW_EXPECTATIONS = 'below_expectations',
  MEETS_EXPECTATIONS = 'meets_expectations',
  ABOVE_EXPECTATIONS = 'above_expectations',
  AVERAGE_MINUS = 'average_minus',
  AVERAGE_PLUS = 'average_plus',
  STRONG = 'strong',
  EXCEPTIONAL = 'exceptional',
  NEUTRAL = 'neutral',
}

export interface ScorecardInterface {
  id: number
  title: string
  reviewed_by: {
    id: number
    full_name?: string
    status?: string
  }
  reviewed_person: {
    id: number
    full_name?: string
    status?: string
  }
  scorecards: ScorecardSections[]
  verdict?: 'pass' | 'fail' | null
  passed_probation?: true | false | null
  created_date: string
  updated_date: string
  performance_score_label: PerformanceScores
  performance_score?: number
  feedback: ScorecardFeedback[]
  category: ReviewCycleCategory
}

export interface ScorecrdsGenerationsCategory {
  id: string
  label: string
  enabled: boolean
}
