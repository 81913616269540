import {
  TableWidget,
  DataPoint,
  MoreBar,
  ErrorWidget,
  FilterButton,
  FilterButtonSkeleton,
} from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import {
  getAllPerformanceReviews,
  getAllPerformanceReviewsStats,
} from '@src/api/performanceReview'
import Stat from '@components/Stat/Stat'
import React from 'react'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  performanceReviewEmployeeColumn,
  performanceReviewReviewerColumn,
  performanceReviewSeniorityColumn,
  performanceReviewTeamColumn,
  performanceReviewRoleColumn,
  performanceReviewStatusColumn,
  performanceReviewTypeColumn,
} from '@src/constants/columns/performanceReviews'
import {
  PerformanceReviewInterface,
  PerformanceReviewStats,
} from '@src/interfaces/performance'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { Statuses } from '@src/interfaces'
import { formatPercentage } from '@src/utils/format'
import { selectorKeys } from '@src/constants/api'
import { useHasNewScorecards, useSelectedPerformanceCycle } from '@src/utils/performance'
import {
  getReviewsUrl,
  getReviewEditUrl,
} from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import { navigateTo, getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { useGlobalSettings } from '@src/api/settings'
import TableLoader from '@components/Table/TableLoader'

const row = (
  currentUserId: number,
  location: Location<unknown>,
  showNewScorecards: boolean,
): RowInterface<PerformanceReviewInterface> => ({
  linkToForm: data => {
    if (
      (data.status === Statuses.pending || data.status === Statuses.draft) &&
      currentUserId === data.reviewer.id
    ) {
      return navigateTo(
        getReviewEditUrl(
          data.category,
          data.reviewed_employee.id,
          data.reviewed_employee.id,
          data.id,
          showNewScorecards,
        ),
      )
    }

    return navigateTo(
      getReviewsUrl(
        data.category,
        data.cycle,
        data.reviewed_employee.id,
        location,
        showNewScorecards,
      ),
    )
  },
  cells: [
    {
      ...performanceReviewEmployeeColumn,
      width: 200,
    },
    {
      ...performanceReviewTypeColumn,
      width: 100,
    },
    {
      ...performanceReviewReviewerColumn,
      width: 200,
    },
    {
      ...performanceReviewSeniorityColumn,
      width: 100,
    },
    {
      ...performanceReviewTeamColumn,
      width: 150,
    },
    {
      ...performanceReviewRoleColumn,
      width: 150,
    },
    {
      ...performanceReviewStatusColumn,
      width: 100,
    },
  ],
})

interface ReviewsTableProps {
  currentCycle: OptionInterface
}

export const calculateCompletion = (stats?: PerformanceReviewStats) => {
  if (!stats) {
    return null
  }

  let completed = 0
  let total = 0
  for (const [, stat] of Object.entries(stats)) {
    total += stat.amount
    if (stat.status === Statuses.completed) {
      completed = stat.amount
    }
  }

  return total > 0 ? formatPercentage(completed / total) : '0%'
}

const CYCLE_KEY: string = 'cycle__id'

const ReviewsTable = ({ currentCycle }: ReviewsTableProps) => {
  const showNewScorecards = useHasNewScorecards()
  const location = useLocation()
  const permissions = useSelector(selectPermissions)
  const { data: reviewCycles } = useGetSelectors(selectorKeys.review_cycles)
  const currentUser = useSelector(selectUser)
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()

  const canCalibrate = permissions.includes(PermissionTypes.ViewReviewsWhitelist)
  const canViewCycles = permissions.includes(PermissionTypes.ViewReviewCycles)
  const canViewPromotions =
    promotions_enabled && permissions.includes(PermissionTypes.ViewPromotionNomination)

  const initialFilters = [
    {
      filters: [
        { name: `${currentCycle.id}`, id: currentCycle.id, offset: currentCycle.offset },
      ],
      columnName: CYCLE_KEY,
      nonResettable: true,
    },
    {
      columnName: 'reviewer__id',
      filters: [{ name: currentUser.display_name, id: currentUser.id }],
      nonResettable: true,
    },
  ]

  const table = useTable<PerformanceReviewInterface, PerformanceReviewStats>(
    {
      getItems: getAllPerformanceReviews,
      getStats: getAllPerformanceReviewsStats,
    },
    initialFilters,
  )

  const filtersByReviewer =
    table.filterBy.find(filterBy => filterBy.columnName === 'reviewer__id')?.filters || []
  const isFilteredByMyReviews = filtersByReviewer.some(
    filter => filter.id === currentUser.id,
  )

  const toggleFilterByReviews = () => {
    table.onFilterChange(
      isFilteredByMyReviews
        ? { columnName: 'reviewer__id', filters: [] }
        : {
            columnName: 'reviewer__id',
            filters: [{ name: currentUser.display_name, id: currentUser.id }],
          },
    )
  }

  const selectedCycle = table.filterBy.find(filter => filter.columnName === CYCLE_KEY)
    ?.filters[0]
  const cycleOffset = reviewCycles
    ? reviewCycles.find(cycle => String(cycle.id) === String(selectedCycle?.id))?.offset
    : undefined

  return (
    <TableWidget>
      <TableWidget.Info>
        <DataPoint>
          <PerformanceLayoutCycleFilter
            onFilterChange={table.onFilterChange}
            columnName={CYCLE_KEY}
            filter={table.filterBy}
            selector={() => Promise.resolve({ options: reviewCycles || [] })}
          />
        </DataPoint>
        <Stat label="Review completion" val={calculateCompletion(table.stats)} />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canCalibrate ? (
            <MoreBar.Action
              // @ts-expect-error object works fine here, but UI kit expects string
              to={{
                ...getLocationDescriptor(
                  pathToUrl(ROUTES.PERFORMANCE.REVIEWS_CALIBRATION),
                ),
                search: cycleOffset ? `cycle__offset=${cycleOffset}` : undefined,
              }}
              use={InternalLink}
              useIcon="Compare"
            >
              Calibrate grades
            </MoreBar.Action>
          ) : null}
          {canViewPromotions && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.PERFORMANCE.PROMOTION_NOMINEES)}
              use={InternalLink}
              useIcon="ArrowUpgrade"
            >
              Manage promotions
            </MoreBar.Action>
          )}
          {canViewCycles && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.PERFORMANCE.REVIEWS_CYCLES)}
              use={InternalLink}
              useIcon="Pencil"
            >
              Manage cycles
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.PERFORMANCE.SETTINGS.LANDING}
            canView={settingsConfig.performance.canView}
          />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <FilterButton active={isFilteredByMyReviews} onClick={toggleFilterByReviews}>
          My reviews
        </FilterButton>
      </TableWidget.Filters>

      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.PerformanceReviews}
          useWindowScroll
          row={row(currentUser.id, location, showNewScorecards)}
          {...table}
          noDataMessage="All performance reviews will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

const ReviewsBase = () => {
  const { cycles, loading } = useSelectedPerformanceCycle(selectorKeys.review_cycles)

  const reviewCycle =
    cycles.find(cycle => cycle.performance_reviews_selected_cycle) || cycles[0]

  if (loading) {
    return (
      <TableWidget>
        <TableWidget.Filters>
          <FilterButtonSkeleton />
          <FilterButtonSkeleton />
          <FilterButtonSkeleton />
        </TableWidget.Filters>
        <TableWidget.Table>
          <TableLoader loading rowHeight="small" />
        </TableWidget.Table>
      </TableWidget>
    )
  }

  if (cycles.length === 0) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D018.png" />
        <ErrorWidget.Title>There has been no review cycles</ErrorWidget.Title>
      </ErrorWidget>
    )
  }

  return <ReviewsTable currentCycle={reviewCycle} />
}

export const Reviews = () => {
  return (
    <OnboardingAppScreen category="reviews">
      <ReviewsBase />
    </OnboardingAppScreen>
  )
}
