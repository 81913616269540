import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { CreateLocationPopup } from '@src/features/GenericEditableTable/CellPopups/CreateLocation'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import {
  onboardingJobsV2DescriptionColumn,
  onboardingJobsV2HeadcountColumn,
  onboardingJobsV2LocationsColumn,
  onboardingJobsV2RecruiterColumn,
  onboardingJobsV2RoleColumn,
  onboardingJobsV2SeniorityColumn,
  onboardingJobsV2TeamColumn,
  onboardingJobsV2TitleColumn,
} from '@src/constants/columns/importJobsV2'
import { RequisitionPostingSimpleInterface } from '@src/interfaces/requisitions'
import {
  BulkUpdateJobPopup,
  BulkUpdateRequisitionJobField,
} from '@src/pages/OnboardingChecklistV2/Jobs/components'
import { IdAndName } from '@src/interfaces'
import {
  archiveBulkRequisitionPosting,
  archiveRequisitionPosting,
} from '@src/pages/OnboardingChecklistV2/Jobs/api'
import {
  BulkDeleteExistingEntitiesButton,
  GenericEditableTableCreateCallbackType,
} from '@src/features/GenericEditableTable/components'
import { useOnboardingContext } from '@src/pages/OnboardingChecklistV2/components/OnboardingContext'
import { CreateTeamPopup } from '@src/features/GenericEditableTable/CellPopups/CreateTeam'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'

type CreateNewItemType = 'locations' | 'team' | 'role'

const row =
  (onCreate: GenericEditableTableCreateCallbackType<CreateNewItemType>) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<RequisitionPostingSimpleInterface>> => ({
    cells: [
      {
        ...onboardingJobsV2TitleColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingJobsV2TeamColumn(options.onChange, onChangeAction => {
          if (onChangeAction) {
            onCreate('team', onChangeAction)
          }
        }),
        width: 200,
      },
      {
        ...onboardingJobsV2RoleColumn(options.onChange, onChangeAction => {
          if (onChangeAction) {
            onCreate('role', onChangeAction)
          }
        }),
        width: 150,
      },
      {
        ...onboardingJobsV2HeadcountColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingJobsV2SeniorityColumn(options.onBulkChange),
        width: 200,
      },
      {
        ...onboardingJobsV2LocationsColumn(options.onChange, () => {
          // we don't create anything, just open a popup
          onCreate('locations', () => {})
        }),
        width: 200,
      },
      {
        ...onboardingJobsV2RecruiterColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingJobsV2DescriptionColumn(options.onChange),
        width: 150,
      },
    ],
  })

export const JobsImport = () => {
  const { setNextButtonState } = useOnboardingContext()
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    id?: number
    onChangeAction?: (newEntity: IdAndName) => void
  }>()

  const onCreateNew: GenericEditableTableCreateCallbackType<CreateNewItemType> = (
    type,
    onChangeAction,
  ) => {
    setCreateNewTypeState({ type, onChangeAction })
  }

  const [popupState, setPopupState] = useState<{
    field: BulkUpdateRequisitionJobField
    items: number[]
    refreshTable: () => void
  } | null>(null)
  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.REQUISITION_POSTING_SIMPLE}
        apiVersion="v2"
        tableName={TableNames.ImportJobsV2}
        row={row(onCreateNew)}
        entity="job"
        variant="existingEntities"
        filterByInitial={[
          {
            columnName: 'status',
            filters: [
              {
                name: 'pending',
                id: 'pending',
              },
              {
                name: 'approved',
                id: 'approved',
              },
              {
                name: 'opened',
                id: 'opened',
              },
            ],
            nonResettable: true,
          },
        ]}
        existingEntitiesDeleteHandler={archiveRequisitionPosting}
        onChangeValidationState={state => {
          if (state === 'valid') {
            setNextButtonState('active')
          } else if (state === 'invalid') {
            setNextButtonState('disabled')
          }
        }}
        tableActions={props => (
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.NEW}
              useIcon="Plus"
            >
              Add requisition
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.IMPORT}
              useIcon="Upload"
            >
              Import requisitions
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="People"
              disabled={!props.selectionContext?.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'team',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change team
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="RepairTool"
              disabled={!props.selectionContext?.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'specialisation',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change role
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="ArrowRightLeft"
              disabled={!props.selectionContext?.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'seniority',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change seniority
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="MapPoint"
              disabled={!props.selectionContext?.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'locations',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change locations
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="Profile"
              disabled={!props.selectionContext?.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'recruiter',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change recruiter
            </MoreBar.Action>
            <BulkDeleteExistingEntitiesButton
              {...props}
              apiHandler={archiveBulkRequisitionPosting}
            />
          </MoreBar>
        )}
      />

      <CreateLocationPopup
        open={createNewTypeState?.type === 'locations'}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction?.(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction?.(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
      {popupState && (
        <BulkUpdateJobPopup
          open
          selectedItems={popupState.items}
          field={popupState.field}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => {
            setPopupState(null)
          }}
          onCreateNew={onChangeAction => {
            const type =
              popupState.field === 'team'
                ? 'team'
                : popupState.field === 'specialisation'
                ? 'role'
                : null
            if (type) {
              setCreateNewTypeState({ type, onChangeAction })
            }
          }}
        />
      )}
    </>
  )
}
