import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const TimeManagementIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Time%20Off.mov"
      videoPosterUrl="/intros/Intro%20-%20Time%20Off.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.timeManagement.intro.tips.title"
            defaultMessage="Create time off policies that match your needs"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.timeManagement.intro.tips.text"
            defaultMessage="Customise the policies to fit the needs of your company or your legal requirements, then decide who gets assigned each of these policies."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.create.title"
                defaultMessage="Create time off policies"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.create.text"
                defaultMessage="Time off policies in Revolut People specify the amount of leave an employee accrues, the carryover allowance, and the approval process for time off requests. These policies form the foundation for managing leave within the system."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.assign.title"
                defaultMessage="Assign policies automatically or manually"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.assign.text"
                defaultMessage="After creating a policy, you can assign it to specific employees using filters or groups. For example, you might assign an Annual Leave policy to all senior staff in the UK. Once set up, the policy will automatically apply to anyone who meets these criteria."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
