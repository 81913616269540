import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'

export const getGoalUrl = (goal: GoalsInterface, isOnboarding: boolean) => {
  const route =
    goal.status.id === Statuses.draft ? ROUTES.FORMS.GOAL.EDIT : ROUTES.FORMS.GOAL.PREVIEW
  const onboardingRoute =
    goal.status.id === Statuses.draft
      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
      : ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW

  return pathToUrl(isOnboarding ? onboardingRoute : route, { id: goal.id })
}
