import { IconName, VStack, Widget } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { Grid } from '@src/components/CommonSC/Grid'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React from 'react'

export const SettingsLanding = () => {
  const items = [
    {
      title: 'General settings',
      icon: 'Gear',
      path: ROUTES.PERFORMANCE.SETTINGS.GENERAL,
    },
    {
      title: 'Cycle duration',
      icon: 'Time',
      path: ROUTES.PERFORMANCE.SETTINGS.CYCLE,
    },
    {
      title: 'Review types',
      icon: 'Switches',
      path: ROUTES.PERFORMANCE.SETTINGS.REVIEWS,
    },
    {
      title: 'PIP settings',
      icon: 'RatesCircle',
      path: ROUTES.PERFORMANCE.SETTINGS.PIP,
    },
    {
      title: 'Probation settings',
      icon: '16/SandWatch',
      path: ROUTES.PERFORMANCE.SETTINGS.PROBATION,
    },
  ]
  return (
    <Widget>
      <Grid flow="row" gap={24}>
        <VStack width="100%">
          {items.map(({ title, path, icon }) => (
            <NewSelectionCard
              key={path}
              variant="disclosure"
              use="button"
              onClick={() => navigateTo(pathToUrl(path))}
              icon={icon as IconName}
              title={title}
            />
          ))}
        </VStack>
      </Grid>
    </Widget>
  )
}
