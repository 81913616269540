import React, { useEffect, useState } from 'react'
import {
  ActionButton,
  Box,
  Button,
  Cell,
  Checkbox,
  Color,
  Flex,
  HStack,
  Icon,
  InputGroup,
  Item,
  Link,
  List,
  TabBar,
  Text,
  Token,
} from '@revolut/ui-kit'
import pick from 'lodash/pick'
import { useSelector } from 'react-redux'

import {
  ChangeRequest,
  EmployeeChangeRequestInterface,
  EmployeeChangeRequestParams,
  EmployeeChangeRequestSetSubmitInterface,
  EmployeeChangeRequestType,
} from '@src/interfaces/employeeChangeRequest'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewDatePicker from '@src/components/Inputs/LapeFields/LapeNewDatePicker'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { EmployeeType } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  employeeChangeRequest,
  generateChangeRequestPreview,
} from '@src/api/employeeChangeRequest'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  AccessLostWidget,
  backUrl,
  Details,
  EmployeeDetails,
  HeaderAvatarCss,
  TableWrapper,
} from './common'
import NewStaticTable from '@src/components/Table/NewStaticTable'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import {
  CHANGE_REQUEST_PROCESS_LINK,
  HR_SERVICE_DESK_CHANGE_REQUEST_LINK,
  PERFORMANCE_REVIEW_REQUEST_CHANGE_LINK,
} from '@src/constants/externalLinks'
import {
  approvalTypeColumn,
  fieldToChangeColumn,
  newValueColumn,
  oldValueColumn,
} from '@src/constants/columns/employeeChangeRequest'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { getColor } from '@src/styles/colors'
import { useTheme } from '@src/styles/theme'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { difference } from '@src/utils/form'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetEmployeeSettings } from '@src/api/settings'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import {
  EmployeeProfileSectionKey,
  getProfileSectionFields,
} from '@src/pages/EmployeeProfile/Preview/common'

type Tabs = 'edit' | 'preview'

interface RequestNewProps {
  employee: EmployeeDetails
  type: EmployeeChangeRequestType
  setChangeRequestDetails: (request: EmployeeChangeRequestInterface) => void
}

export const RequestNew = ({
  setChangeRequestDetails,
  employee,
  type,
}: RequestNewProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const form = useLapeContext<EmployeeChangeRequestSetSubmitInterface>()
  const { values, initialValues, dirty } = form

  const [preview, setPreview] = useState<EmployeeChangeRequestInterface>()
  const [generatingPreview, setGeneratingPreview] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [tab, setTab] = useState<Tabs>('edit')

  const [dutiesAffectedChecked, setDutiesAffectedChecked] = useState(false)
  const [employeeAwareChecked, setEmployeeAwareChecked] = useState(false)
  const [autoApproveChecked, setAutoApprovedChecked] = useState(false)
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false)

  const formValidator = useSafeFormValidator()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getPayload = () => {
    const getNewValue = (formValue: any) => {
      if (typeof formValue === 'string') {
        return formValue
      }

      if (typeof formValue === 'object' && formValue != null && formValue.id) {
        return formValue.id
      }

      return undefined
    }

    const diff = difference(values, initialValues)
    const fields = pick(diff, getProfileSectionFields(type as EmployeeProfileSectionKey))

    const payload: EmployeeChangeRequestSetSubmitInterface = {
      effective_date: values.effective_date,
      skip_approvals: autoApproveChecked,
      justification: values.justification,
      field_changes: Object.entries(fields).map(([field_name, value]) => ({
        field_name,
        new_value: getNewValue(value),
      })),
    }

    return payload
  }

  const onGeneratePreview = async () => {
    if (
      type === 'organisation' &&
      !isCommercial &&
      (!dutiesAffectedChecked || !employeeAwareChecked)
    ) {
      setHasTriedToSubmit(true)
      return
    }

    setGeneratingPreview(true)

    try {
      const response = await generateChangeRequestPreview(employee.id, getPayload())
      setPreview(response.data)
      setTab('preview')
    } finally {
      setGeneratingPreview(false)
    }
  }

  const isPositionType = type === 'position'
  const isNameType = type === 'name'
  const previewDisabled = !values.effective_date || !values.justification || !dirty

  const onSubmit = async () => {
    setSubmitting(true)

    try {
      const response = await employeeChangeRequest.submit(getPayload(), {
        employeeId: `${employee.id}`,
      })
      setChangeRequestDetails(response.data)
      setSubmitting(false)
      navigateReplace(
        pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.DETAILS, {
          employeeId: `${employee.id}`,
          id: `${response.data.id}`,
        } as EmployeeChangeRequestParams),
      )
    } catch (err) {
      setSubmitting(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Employee change request"
        subtitle={
          <UserWithAvatar {...employee} status={undefined} css={HeaderAvatarCss} />
        }
        backUrl={backUrl(employee.id)}
      />

      <PageBody>
        {isPositionType || isNameType ? (
          <ActionWidget
            title="Please contact HR"
            text={
              {
                position: `For changes related to your role, seniority, location, or compensation details you still need to submit a request ${
                  isCommercial ? 'to your HR team' : 'in the HR Service Desk'
                }.`,
                name: `For changes related to your name or work email you can submit a request ${
                  isCommercial ? 'to your HR team' : 'in the HR Service Desk'
                }.`,
              }[type]
            }
          >
            <HideIfCommercial>
              <ActionButton
                href={HR_SERVICE_DESK_CHANGE_REQUEST_LINK}
                use={Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open HR service desk
              </ActionButton>
            </HideIfCommercial>
          </ActionWidget>
        ) : (
          <HideIfCommercial>
            <Text variant="caption" color={Color.GREY_TONE_50} mb="s-48">
              You can request one or more of the following fields to be changed, subject
              to approval from the relevant parties. More information on the change
              request process can be found{' '}
              <Link
                href={CHANGE_REQUEST_PROCESS_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </Link>
            </Text>
          </HideIfCommercial>
        )}

        {isPositionType || isNameType ? null : (
          <Flex mb="s-24">
            <TabBar>
              <TabBar.Item onClick={() => setTab('edit')} aria-selected={tab === 'edit'}>
                Edit
              </TabBar.Item>
              <TabBar.Item
                onClick={formValidator.validate(onGeneratePreview)}
                aria-selected={tab === 'preview'}
                disabled={previewDisabled || !preview}
              >
                Preview
              </TabBar.Item>
            </TabBar>
          </Flex>
        )}

        {tab === 'edit' ? (
          <EditTab
            type={type}
            employee_type={employee.employee_type}
            dutiesAffectedChecked={dutiesAffectedChecked}
            setDutiesAffectedChecked={setDutiesAffectedChecked}
            employeeAwareChecked={employeeAwareChecked}
            setEmployeeAwareChecked={setEmployeeAwareChecked}
            autoApproveChecked={autoApproveChecked}
            setAutoApproveChecked={setAutoApprovedChecked}
            hasTriedToSubmit={hasTriedToSubmit}
          />
        ) : null}
        {tab === 'preview' && preview ? (
          <PreviewTab
            preview={preview}
            type={type}
            autoApproveChecked={autoApproveChecked}
          />
        ) : null}
      </PageBody>

      {isPositionType || isNameType ? null : (
        <PageActions>
          {tab === 'edit' ? (
            <Button
              onClick={formValidator.validate(onGeneratePreview)}
              pending={generatingPreview}
              elevated
            >
              Continue
            </Button>
          ) : null}
          {tab === 'preview' ? (
            <>
              <Button
                onClick={() => setTab('edit')}
                variant="secondary"
                disabled={submitting}
              >
                Edit request
              </Button>
              <Button onClick={onSubmit} elevated pending={submitting}>
                Submit
              </Button>
            </>
          ) : null}
        </PageActions>
      )}
    </PageWrapper>
  )
}

export const dutiesAffectedErrorText =
  'If the employee’s core duties are changing you must direct your request to the HR Team'
export const employeeAwareErrorText =
  'Please align with the employee before request this change'

const ErrorMessage: React.FC = ({ children }) => {
  return (
    <Flex alignItems="center" mt="s-8" ml="s-16">
      <Icon name="ExclamationMarkOutline" size={14} color={Token.color.red} />
      <Text variant="small" ml="s-8" color={Token.color.red}>
        {children}
      </Text>
    </Flex>
  )
}

const ChangeIndicator = () => (
  <Box mr="s-8">
    <Icon name="Time" color={Token.color.orange} size={24} />
  </Box>
)

interface EditTabProps {
  type: EmployeeChangeRequestType
  employee_type: EmployeeType
  dutiesAffectedChecked: boolean
  setDutiesAffectedChecked: (checked: boolean) => void
  employeeAwareChecked: boolean
  setEmployeeAwareChecked: (checked: boolean) => void
  autoApproveChecked: boolean
  setAutoApproveChecked: (checked: boolean) => void
  hasTriedToSubmit: boolean
}

const EditTab = ({
  type,
  employee_type,
  dutiesAffectedChecked,
  setDutiesAffectedChecked,
  employeeAwareChecked,
  setEmployeeAwareChecked,
  autoApproveChecked,
  setAutoApproveChecked,
  hasTriedToSubmit,
}: EditTabProps) => {
  const theme = useTheme()
  const { values, initialValues } =
    useLapeContext<EmployeeChangeRequestSetSubmitInterface>()
  const permissions = useSelector(selectPermissions)

  const { data: settings } = useGetEmployeeSettings()

  const canAutoApprove = permissions.includes(
    PermissionTypes.CreateChangeRequestSetWithoutApproval,
  )

  const dutiesAffectedError = hasTriedToSubmit && !dutiesAffectedChecked
  const employeeAwareError = hasTriedToSubmit && !employeeAwareChecked

  const getAutocompleteChangeIndicator = (name: string) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    return initialValues[name]?.id !== values[name]?.id &&
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      (initialValues[name]?.id || values[name]?.id)
      ? {
          renderAction: ChangeIndicator,
        }
      : {}
  }

  return (
    <>
      {type === 'organisation' && (
        <InputGroup data-testid="organisation-inputs">
          <LapeRadioSelectInput
            label={employee_type === 'internal' ? 'Line manager' : 'Point of contact 1'}
            name="line_manager"
            inputProps={getAutocompleteChangeIndicator('line_manager')}
            selector={selectorKeys.employees_for_teams}
          />
          <LapeRadioSelectInput
            label={
              employee_type === 'internal' ? 'Functional manager' : 'Point of contact 2'
            }
            name="quality_control"
            inputProps={getAutocompleteChangeIndicator('quality_control')}
            selector={selectorKeys.employees_for_teams}
          />
          <LapeRadioSelectInput
            label="Team"
            name="team"
            inputProps={getAutocompleteChangeIndicator('team')}
            selector={selectorKeys.team}
          />
          {!!settings?.enable_buddy && (
            <LapeRadioSelectInput
              label="Buddy"
              name="mentor"
              inputProps={getAutocompleteChangeIndicator('mentor')}
              selector={selectorKeys.all_employees}
            />
          )}
          <LapeNewDatePicker
            name="effective_date"
            label="Effective date of change(s)"
            required
            disabledDays={{ before: new Date() }}
          />
          <LapeNewTextArea name="justification" label="Justification" required rows={2} />
          <HideIfCommercial>
            <Box>
              <Item
                style={{
                  backgroundColor: dutiesAffectedError
                    ? getColor(theme, Color.INPUT_ERROR_FOCUS)
                    : undefined,
                }}
              >
                <Item.Prefix height="max-content">
                  <Checkbox
                    checked={dutiesAffectedChecked}
                    onChange={e => setDutiesAffectedChecked(e.currentTarget.checked)}
                    data-testid="duties-affected-checkbox"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>
                    I confirm that the employee’s core duties will not be affected
                  </Item.Title>
                  <Item.Description>
                    If employee’s core duties will be impacted by these changes, please
                    direct your request to the{' '}
                    <Link
                      href={HR_SERVICE_DESK_CHANGE_REQUEST_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HR Service Desk
                    </Link>{' '}
                    so that their contract can be amended
                  </Item.Description>
                </Item.Content>
              </Item>
              {dutiesAffectedError ? (
                <ErrorMessage>{dutiesAffectedErrorText}</ErrorMessage>
              ) : null}
            </Box>
            <Box>
              <Item
                style={{
                  backgroundColor: employeeAwareError
                    ? getColor(theme, Color.INPUT_ERROR_FOCUS)
                    : undefined,
                }}
              >
                <Item.Prefix height="max-content">
                  <Checkbox
                    checked={employeeAwareChecked}
                    onChange={e => setEmployeeAwareChecked(e.currentTarget.checked)}
                    data-testid="employee-aware-checkbox"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>
                    I confirm that the employee is aware of this change
                  </Item.Title>
                  <Item.Description>
                    You must discuss the changes with the employee before submitting a
                    change request
                  </Item.Description>
                </Item.Content>
              </Item>
              {employeeAwareError ? (
                <ErrorMessage>{employeeAwareErrorText}</ErrorMessage>
              ) : null}
            </Box>
          </HideIfCommercial>

          {canAutoApprove ? (
            <Box>
              <Item>
                <Item.Prefix height="max-content">
                  <Checkbox
                    checked={autoApproveChecked}
                    onChange={e => setAutoApproveChecked(e.currentTarget.checked)}
                    data-testid="auto-approve-checkbox"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>I want this change to be auto-approved</Item.Title>
                </Item.Content>
              </Item>
            </Box>
          ) : null}
        </InputGroup>
      )}
    </>
  )
}

const fieldsRow: RowInterface<ChangeRequest> = {
  cells: [
    {
      ...fieldToChangeColumn,
      width: 150,
    },
    {
      ...oldValueColumn,
      width: 160,
    },
    {
      ...newValueColumn,
      width: 160,
    },
    {
      ...approvalTypeColumn,
      width: 150,
    },
    {
      type: CellTypes.insert,
      idPoint: 'approvals',
      dataPoint: 'approvals',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Approvers',
      width: 100,
      insert: ({ data }) => (
        <HStack space="s-8">
          {data.approvals.map(a => (
            <UserWithAvatar {...a.approver} compact key={a.approver.id} />
          ))}
        </HStack>
      ),
    },
  ],
}

interface PreviewTabProps {
  preview: EmployeeChangeRequestInterface
  type: EmployeeChangeRequestType
  autoApproveChecked: boolean
}

const PreviewTab = ({ preview, type, autoApproveChecked }: PreviewTabProps) => {
  return (
    <>
      {type === 'organisation' && preview.is_performance_review_ending ? (
        <ActionWidget
          title="Performance reviewers cannot be changed using this process"
          text={
            <>
              Once a performance review cycle has started, requesting a change of line
              manager or functional manager will not update the appointed reviewers for
              the pending evaluations.
              <HideIfCommercial>
                You can request a change in reviewers separately,{' '}
                <Link
                  href={PERFORMANCE_REVIEW_REQUEST_CHANGE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>
                .
              </HideIfCommercial>
            </>
          }
          mb="s-16"
        />
      ) : null}

      <AccessLostWidget request={preview} />

      <TableWrapper>
        <NewStaticTable rows={fieldsRow} data={preview.change_requests} />
      </TableWrapper>

      <Details request={preview} />

      {type === 'organisation' ? (
        <Box mt="s-16">
          <Cell>
            <List variant="compact" color={Color.FOREGROUND}>
              <List.Item
                useIcon={() => <Icon name="16/StatusCheck" color={Token.color.green} />}
              >
                The employee’s core duties will not be affected
              </List.Item>
              <List.Item
                useIcon={() => <Icon name="16/StatusCheck" color={Token.color.green} />}
              >
                The employee is aware of this change
              </List.Item>
              {autoApproveChecked ? (
                <List.Item
                  useIcon={() => <Icon name="16/StatusCheck" color={Token.color.green} />}
                >
                  This change will be auto-approved
                </List.Item>
              ) : null}
            </List>
          </Cell>
        </Box>
      ) : null}
    </>
  )
}
