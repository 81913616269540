import React, { useRef, useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import {
  ActionButton,
  Button,
  Side,
  StatusPopup,
  TabBar,
  useStatusPopup,
} from '@revolut/ui-kit'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
  StageReference,
} from '@src/interfaces/hiringProccess'
import { useTable } from '@src/components/Table/hooks'
import {
  addStageReferencesToHiringProcess,
  hiringProcessesStagesRequests,
} from '@src/api/hiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetSpecialisation } from '@src/api/specialisations'
import { useGetRole } from '@src/api/roles'
import { HiringStage } from '@src/pages/Forms/SpecialisationHiringProcess/components/HiringStage'
import { CompanyHiringStages } from '@src/pages/Forms/SpecialisationHiringProcess/components/CompanyHiringStages'
import { RoleHiringStages } from '@src/pages/Forms/SpecialisationHiringProcess/components/RoleHiringStages'

const mapToStageReference = (
  stages: HiringProcessInterface[],
  stage_level: 'company' | 'role',
): StageReference[] =>
  stages.map(({ id }) => ({
    stage_id: id,
    stage_level,
  }))

const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === 'fulfilled'
const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === 'rejected'

type AddFromHiringStagesBankSidebarProps = {
  onAddHiringStagesFromBank: (hiringStages: HiringProcessInterface[]) => void
  onClose: () => void
}

type HiringStagesBankTab = 'company' | 'role'

const AddFromHiringStagesBankSidebar = ({
  onAddHiringStagesFromBank,
  onClose,
}: AddFromHiringStagesBankSidebarProps) => {
  const { values } = useLapeContext<SpecialisationHiringProcess>()
  const [tab, setTab] = useState<HiringStagesBankTab>('company')
  const table = useTable<HiringProcessInterface>(hiringProcessesStagesRequests)
  const [companySelectedStages, setCompanySelectedStages] = useState<
    HiringProcessInterface[]
  >([])
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data: specialisation, isLoading: loadingSpecialisation } = useGetSpecialisation(
    values.specialisation.id,
  )
  const { data: role, isLoading: loadingRole } = useGetRole(specialisation?.role?.id)
  const [roleSelectedStages, setRoleSelectedStages] = useState<HiringProcessInterface[]>(
    [],
  )
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const hasCVScreening = (values.hiring_process_stages || []).some(
    stage => stage.stage_type.id === 'cv_screening',
  )
  const handleAddHiringStages = async () => {
    setLoading(true)
    try {
      const stageReferences = [
        ...mapToStageReference(companySelectedStages, 'company'),
        ...mapToStageReference(roleSelectedStages, 'role'),
      ]
      const results = await Promise.allSettled(
        stageReferences.map(stageReference =>
          addStageReferencesToHiringProcess(
            values.specialisation.id,
            values.id,
            stageReference,
          ),
        ),
      )
      const fulfilledValues = results.filter(isFulfilled).map(({ value }) => value.data)
      const rejectedReasons = results
        .filter(isRejected)
        .map(({ reason }) => JSON.parse(reason.config.data))
      onAddHiringStagesFromBank(fulfilledValues)
      if (rejectedReasons.length) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>
              There was an error adding the following hiring stages from bank
            </StatusPopup.Title>
            <StatusPopup.Description>
              {rejectedReasons.map(data => (
                <HiringStage key={data.id} disabled hiringStage={data} />
              ))}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      } else {
        onClose()
      }
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            There was an error adding hiring stages from bank
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <SideBar
      isOpen
      variant="wide"
      title="Hiring stages Bank"
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      <TabBar
        variant="segmented"
        value={tab}
        onChange={newTab => {
          if (newTab) {
            setTab(newTab)
          }
        }}
      >
        <TabBar.Item to="company">Company</TabBar.Item>
        <TabBar.Item to="role">Role</TabBar.Item>
      </TabBar>
      {tab === 'company' && (
        <CompanyHiringStages
          disabled={loading}
          disableSelectingCVScreeningStages={hasCVScreening}
          scrollRef={scrollRef}
          selected={companySelectedStages}
          table={table}
          onFetchNextPage={table.fetchNextPage}
          onChangeSelected={setCompanySelectedStages}
        />
      )}
      {tab === 'role' && (
        <RoleHiringStages
          hiringStages={role?.hiring_process_rounds}
          disabled={loading}
          disableSelectingCVScreeningStages={hasCVScreening}
          loadingHiringStages={loadingSpecialisation || loadingRole}
          selected={roleSelectedStages}
          onChangeSelected={setRoleSelectedStages}
        />
      )}
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleAddHiringStages}
          pending={loading}
          disabled={!companySelectedStages.length && !roleSelectedStages.length}
        >
          Add stage
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

type AddFromHiringStagesBankProps = {
  pending: boolean
  onAddHiringStagesFromBank: (hiringStages: HiringProcessInterface[]) => void
}

export const AddFromHiringStagesBank = ({
  pending,
  onAddHiringStagesFromBank,
}: AddFromHiringStagesBankProps) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ActionButton
        useIcon="Library"
        onClick={() => {
          setOpen(!open)
        }}
        pending={pending}
      >
        Add from hiring stages bank
      </ActionButton>
      {open && (
        <AddFromHiringStagesBankSidebar
          onAddHiringStagesFromBank={onAddHiringStagesFromBank}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
