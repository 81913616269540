import React from 'react'
import { connect } from 'lape'
import ScorecardForm from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateForm'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'

const General = () => {
  const params = useParams<{ id?: string }>()
  const { query } = useQuery<{ ref?: 'onboarding' }>()
  const fromOnboarding = query?.ref === 'onboarding'
  const statusPopup = useStatusPopup()
  const [, setCreatedScorecard] = useLocalStorage<number | undefined>(
    LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD,
    undefined,
  )

  return (
    <ScorecardForm
      noPopup={fromOnboarding}
      onSubmit={
        fromOnboarding
          ? result => {
              setCreatedScorecard(result.id)
              statusPopup.show(
                <StatusPopup variant="success-result" preventUserClose>
                  <StatusPopup.Title>
                    {params.id ? (
                      <FormattedMessage
                        id="recruitment.interviewScorecardTemplate.onboarding.successUpdate.title"
                        defaultMessage="Scorecard updated"
                      />
                    ) : (
                      <FormattedMessage
                        id="recruitment.interviewScorecardTemplate.onboarding.successCreate.title"
                        defaultMessage="New scorecard created"
                      />
                    )}
                  </StatusPopup.Title>
                  <StatusPopup.Description>
                    <FormattedMessage
                      id="recruitment.interviewScorecardTemplate.onboarding.success.description"
                      defaultMessage="Please close this tab and return back to the onboarding setup page."
                    />
                  </StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button
                      elevated
                      onClick={() => {
                        window.close()
                      }}
                    >
                      <FormattedMessage
                        id="recruitment.interviewScorecardTemplate.onboarding.success.button"
                        defaultMessage="Close and go back"
                      />
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            }
          : undefined
      }
    />
  )
}

export default connect(General)
