import React from 'react'
import { ProgressCircle, StatusWidget, Text, VStack } from '@revolut/ui-kit'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import { useGetTimeOffPolicyTempAssignmentsStatus } from '@src/api/timeOff'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import { PolicyAssignmentsTable } from './components/PolicyAssignmentsTable'
import { SubmitTempAssignments } from './components/SubmitTempAssignments'
import { PageBody } from '@components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { formatPercentage } from '@src/utils/format'

const PolicyAssignmentsData = () => {
  const { data } = useGetTimeOffPolicyTempAssignmentsStatus()

  if (!data) {
    return <PageLoading />
  }

  if (data.state === 'not_started') {
    return (
      <SuccessWidget
        maxWidth="100%"
        title="Preparing to simulate time off"
        text="We are getting ready to forecast your time off balances"
        type="planned"
      />
    )
  }

  if (data.state === 'in_progress') {
    const progress = data.total && data.progress && data.progress / data.total

    return (
      <PageBody>
        <StatusWidget>
          <StatusWidget.Title>
            <VStack align="center" space="s-16" pt="s-16">
              <ProgressCircle strokeWidth={3} size={94} value={progress || undefined}>
                <ProgressCircle.Text>{formatPercentage(progress)}</ProgressCircle.Text>
              </ProgressCircle>
              <Text>Simulating time off</Text>
            </VStack>
          </StatusWidget.Title>
          <StatusWidget.Description>
            We are forecasting the time off balances, please wait
          </StatusWidget.Description>
        </StatusWidget>
      </PageBody>
    )
  }

  if (data.state === 'failed') {
    return (
      <SuccessWidget
        maxWidth="100%"
        title="Simulation failed"
        text="We couldn't simulate the time off, please go back and try again."
        type="error"
      />
    )
  }

  return (
    <>
      <PolicyAssignmentsTable />
      <SubmitTempAssignments stepIsCompleted={false} />
    </>
  )
}

export const PolicyAssignments = () => {
  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    timeManagementConfig.category,
  )
  const stepIsCompleted = checkpoint?.state.id === 'completed'

  return (
    <>
      {stepIsCompleted ? (
        <>
          <PageBody>
            <StepCompletedWidget />
          </PageBody>
          <SubmitTempAssignments stepIsCompleted />
        </>
      ) : (
        <PolicyAssignmentsData />
      )}
    </>
  )
}
