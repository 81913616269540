import React from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import SideBar from '@src/components/SideBar/SideBar'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { Group, Header, HStack, MoreBar, TagBar, Token } from '@revolut/ui-kit'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { FormattedMessage } from 'react-intl'
import { PreviewScorecard } from '@src/pages/OnboardingChecklistV2/Interviews/components/PreviewScorecard'
import ActionWidget from '@components/ActionWidget/ActionWidget'

type RoundDetailsSidebarProps = {
  hiringProcessRound: HiringProcessInterface
  onClose: () => void
  onEdit?: () => void
}

type FormPreviewOptionsAsTagsProps = {
  options?: OptionInterface[]
  title: string
}

const FormPreviewOptionsAsTags = ({ options, title }: FormPreviewOptionsAsTagsProps) => {
  if (!options?.length) {
    return <FormPreview.Item title={title} />
  }
  return (
    <FormPreview.Details
      title={title}
      insert={() => {
        return (
          <TagBar>
            {options.map(o => (
              <TagBar.Item key={o.id}>{o.name}</TagBar.Item>
            ))}
          </TagBar>
        )
      }}
    />
  )
}

type FormPreviewEmployeesProps = {
  interviewers?: EmployeeOptionInterface[]
}

const FormPreviewEmployees = ({ interviewers }: FormPreviewEmployeesProps) => {
  if (!interviewers?.length) {
    return <FormPreview.Item title="Interviewers" />
  }
  return (
    <FormPreview.Details
      title="Interviewers"
      insert={() => {
        return (
          <HStack space="s-4">
            {interviewers?.map(item => (
              <UserWithAvatar key={item.id} {...item} compact />
            ))}
          </HStack>
        )
      }}
    />
  )
}

export const RoundDetailsSidebar = ({
  hiringProcessRound,
  onClose,
  onEdit,
}: RoundDetailsSidebarProps) => {
  return (
    <SideBar
      isOpen
      onClose={onClose}
      title={hiringProcessRound.title}
      useLayout
      headerContent={
        onEdit && (
          <Header.Bar>
            <MoreBar>
              <MoreBar.Action onClick={onEdit}>
                <FormattedMessage
                  id="onboarding.interviews.round.editDetails"
                  defaultMessage="Edit details"
                />
              </MoreBar.Action>
            </MoreBar>
          </Header.Bar>
        )
      }
    >
      <FormPreview data={hiringProcessRound}>
        <Group>
          <FormPreview.Item title="Round name" field="title" />
          <FormPreview.Item title="Stage type" field="stage_type.name" />
          {hiringProcessRound.stage_type.id !== 'cv_screening' && (
            <>
              <FormPreview.Item<HiringProcessInterface>
                title="Duration"
                field="duration"
                insert={data => {
                  if (!data.duration) {
                    return '-'
                  }
                  return `${data.duration} ${data.duration_unit.name}`
                }}
              />

              {hiringProcessRound.interviewer_type.id === 'groups' && (
                <FormPreviewOptionsAsTags
                  options={hiringProcessRound.interviewers_groups}
                  title="Interviewer groups"
                />
              )}
              {hiringProcessRound.interviewer_type.id === 'employees' && (
                <FormPreviewEmployees interviewers={hiringProcessRound.interviewers} />
              )}

              <FormPreview.Item<HiringProcessInterface>
                title="Notes"
                field="notes"
                hideIfEmpty
              />
            </>
          )}
        </Group>
      </FormPreview>
      {hiringProcessRound.stage_type.id === 'skills_round' &&
      hiringProcessRound.auto_generated ? (
        <ActionWidget
          mt="s-16"
          avatarColor={Token.color.blue}
          title={
            <FormattedMessage
              id="onboarding.interviews.stage.generateScorecard"
              defaultMessage="Unique scorecard will be generated for every set of skills"
            />
          }
        />
      ) : (
        hiringProcessRound.scorecard_template && (
          <PreviewScorecard
            id={+hiringProcessRound.scorecard_template.id}
            onClose={onClose}
          />
        )
      )}
    </SideBar>
  )
}
