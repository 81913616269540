import { useEffect } from 'react'
import uniq from 'lodash/uniq'

import { ImportSessionInterface } from '@src/interfaces/bulkDataImport'
import { getUploadSessionTable } from '@src/api/bulkDataImport'
import isObject from 'lodash/isObject'
import { TEMPLATE_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  DYNAMIC_COLUMNS_QUERY_KEY,
  CUSTOM_FIELDS_QUERY_KEY,
} from '@src/features/GenericEditableTable/constants'
import { BulkDataImportSessionProps } from './types'

export const getAddedFieldsAfterDeletion = (
  entityName: string,
  field: ConfigurableField,
  addedFields: ConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'seniority') {
    return addedFields.filter(
      f => f.column_name !== 'seniority' && f.column_name !== 'subseniority',
    )
  }
  return addedFields.filter(f => f.column_name !== field.column_name)
}

export type ConfigurableField = {
  name: string
  category: string
  column_name: string
  custom_field_id?: string
}
export const getTemplateDownloadConfigurableFieldsParams = (
  mandatoryColumns: string[],
  configurableFields: ConfigurableField[],
) => {
  const optionalColumns = configurableFields
    .filter(f => !f.custom_field_id)
    .map(f => f.column_name)
  const customFieldsColumns = configurableFields
    .map(f => f.custom_field_id)
    .filter(Boolean)
  const allColumns = uniq([...mandatoryColumns, ...optionalColumns])

  return {
    version: '2',
    ...(allColumns.length ? { extra_columns: allColumns.join(',') } : null),
    ...(customFieldsColumns.length
      ? { extra_custom_fields: customFieldsColumns.join(',') }
      : null),
  }
}

export const getUploadParamsByConfigurableFields = (
  configurableFields: ConfigurableField[],
) => {
  return {
    custom_fields: configurableFields
      .map(f => (f.custom_field_id ? { id: f.custom_field_id, name: f.name } : null))
      .filter(Boolean),
  }
}

export const useDynamicTemplateQueryParams = <T>({
  apiEndpoint,
  apiVersion,
  sessionData,
}: { sessionData: ImportSessionInterface | undefined } & Pick<
  BulkDataImportSessionProps<T>,
  'apiEndpoint' | 'apiVersion'
>) => {
  const { query, changeQueryParam } = useQuery()

  const isDynamicTemplate = query[TEMPLATE_TYPE_QUERY_KEY] === 'dynamic'

  const sessionCustomFields = sessionData?.custom_fields || []
  const customFieldsQueryParam = sessionCustomFields.map(cf => cf.id).join(',')

  useEffect(() => {
    if (!isDynamicTemplate) {
      return
    }
    if (
      sessionData?.state.id === 'ready_for_review' &&
      !query[DYNAMIC_COLUMNS_QUERY_KEY]
    ) {
      const prefetchTableData = getUploadSessionTable<T>(
        apiEndpoint,
        sessionData.id,
        apiVersion,
      )
      prefetchTableData({}).then(res => {
        const firstItemData = res.data.results[0]?.data
        if (isObject(firstItemData)) {
          changeQueryParam(
            DYNAMIC_COLUMNS_QUERY_KEY,
            Object.keys(firstItemData).join(','),
          )
        }
      })
    }
    if (customFieldsQueryParam && !query[CUSTOM_FIELDS_QUERY_KEY]) {
      changeQueryParam(CUSTOM_FIELDS_QUERY_KEY, customFieldsQueryParam)
    }
  }, [query.template, query.data, sessionData?.state.id])
}
