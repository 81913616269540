import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import {
  Button,
  Flex,
  H4,
  Skeleton,
  VStack,
  IconButton,
  Box,
  Token,
} from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { IdAndName } from '@src/interfaces'
import { useSurveyRounds } from '../hooks'

type Props = {
  onChange: (option: IdAndName) => void
  value: IdAndName | undefined
  surveyId: number | undefined
}

export const RoundSelector = ({ onChange, value, surveyId }: Props) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)
  const permissions = useSelector(selectPermissions)
  const canViewSurveyRounds = permissions.includes(
    PermissionTypes.ViewEngagementSurveyRound,
  )

  const { rounds: options, asyncState } = useSurveyRounds(
    canViewSurveyRounds ? surveyId : undefined,
  )

  const currentValueIdx = useMemo(() => {
    return options.findIndex(round => round.id === value?.id)
  }, [options])

  if (!canViewSurveyRounds) {
    return null
  }
  if (asyncState !== 'ready') {
    return (
      <VStack>
        <Skeleton width={72} height={20} my="s-4" />
        <Skeleton width={36} height={12} my="s-4" ml="s-2" />
      </VStack>
    )
  }
  const roundsAvailable = !!options.length

  const isOnNextClickEnabled = currentValueIdx > 0
  const onNextRoundClick = () => {
    if (isOnNextClickEnabled) {
      onChange(options[currentValueIdx - 1])
    }
  }

  const isOnPrevClickEnabled = currentValueIdx < options.length - 1
  const onPrevRoundClick = () => {
    if (isOnPrevClickEnabled) {
      onChange(options[currentValueIdx + 1])
    }
  }

  return (
    <Flex>
      {roundsAvailable && (
        <Box py="s-2" pr="s-4">
          <IconButton
            disabled={!isOnPrevClickEnabled}
            useIcon="ChevronLeft"
            onClick={onPrevRoundClick}
            color={isOnPrevClickEnabled ? Token.color.foreground : Token.color.grey20}
          />
        </Box>
      )}
      <Stat
        label="Round"
        val={
          <Button
            ref={selectRef}
            onClick={() => setOpen(!open)}
            variant="text"
            height="fit-content"
            p={0}
          >
            <H4
              use="span"
              fontWeight="bold"
              color={roundsAvailable ? Token.color.foreground : Token.color.greyTone50}
            >
              {value?.name || 'Select Round'}
            </H4>
            <FilterSelect
              open={open}
              selector={() => Promise.resolve({ options })}
              anchorRef={selectRef}
              onClose={() => {
                setOpen(false)
              }}
              value={value ? [value] : undefined}
              onChange={newValue => {
                const [option] = newValue

                if (option) {
                  setOpen(false)
                  onChange(option)
                }
              }}
              type={FilterSelectType.SingleSelect}
              width={100}
            />
          </Button>
        }
      />
      {roundsAvailable && (
        <Box py="s-2" pl="s-4">
          <IconButton
            disabled={!isOnNextClickEnabled}
            useIcon="ChevronRight"
            onClick={onNextRoundClick}
            color={isOnNextClickEnabled ? Token.color.foreground : Token.color.grey20}
          />
        </Box>
      )}
    </Flex>
  )
}
