import React from 'react'
import RoadmapForm from './RoadmapForm'
import { roadmapsRequestsNew } from '@src/api/roadmaps'
import Form from '@src/features/Form/Form'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { CreateRoadmapLevelSelect } from './CreateRoadmapLevelSelect'

export const Roadmap = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.ROADMAP.SELECT_LEVEL}>
        <CreateRoadmapLevelSelect />
      </Route>
      <Route>
        <Form api={roadmapsRequestsNew}>
          <RoadmapForm />
        </Form>
      </Route>
    </Switch>
  )
}

export default Roadmap
