import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  ActionButton,
  BREAKPOINTS,
  Flex,
  MoreBar,
  MoreBarSkeleton,
  Text,
  Token,
} from '@revolut/ui-kit'
import { ArrowThinLeft, ArrowThinRight } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { BulkReviewContractsProps } from '@src/pages/Forms/EmployeeContracts/components/BulkReviewBar/types'
import { LapeFormInterface } from '@src/features/Form/LapeForm'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { Cancel } from '@src/pages/Forms/EmployeeContracts/components/ContractActionButtons/Cancel'

type Props = {
  form?: LapeFormInterface<EmployeeContractsInterface>
  setForm: (form: LapeFormInterface<EmployeeContractsInterface> | undefined) => void
  reviewInBulk?: BulkReviewContractsProps
  reviewIdx: number
  setReviewIdx: (newIdx: number) => void
  onStatusUpdate: (data: EmployeeContractsInterface) => void
}
export const BulkReviewBar = ({
  form,
  setForm,
  reviewInBulk,
  reviewIdx,
  setReviewIdx,
  onStatusUpdate,
}: Props) => {
  const location = useLocation()

  const onGoToNext = () => {
    if (!reviewInBulk?.length) {
      return
    }
    const nextIdx = reviewIdx + 1

    setReviewIdx(nextIdx)
    setForm(undefined)
    navigateTo(
      `${pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, {
        id: reviewInBulk[nextIdx].contractId,
        employeeId: reviewInBulk[nextIdx].employeeId,
      })}${location.search}`,
      { reviewInBulk },
    )
  }

  const onGoToPrevious = () => {
    if (!reviewInBulk?.length || !reviewIdx) {
      return
    }
    const prevIdx = reviewIdx - 1

    setReviewIdx(prevIdx)
    setForm(undefined)
    navigateTo(
      `${pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, {
        id: reviewInBulk[prevIdx].contractId,
        employeeId: reviewInBulk[prevIdx].employeeId,
      })}${location.search}`,
      { reviewInBulk },
    )
  }

  if (!reviewInBulk?.length) {
    return null
  }
  return (
    <Flex
      flexDirection="column"
      maxWidth={{ all: '100%', md: BREAKPOINTS.md }}
      mb="s-24"
      bg={Token.color.background}
      radius="cell"
      p="s-16"
    >
      <Text variant="h6" use="div" pb="s-16">
        Review contracts ({reviewIdx + 1}/{reviewInBulk.length})
      </Text>
      <Flex justifyContent="space-between">
        {form ? (
          <MoreBar>
            <MoreBar.Action
              onClick={onGoToPrevious}
              useIcon={ArrowThinLeft}
              disabled={!reviewIdx}
            >
              Previous
            </MoreBar.Action>
            <Cancel form={form} onStatusUpdate={onStatusUpdate} />
            <MoreBar.Action
              onClick={onGoToNext}
              useIcon={ArrowThinRight}
              disabled={reviewIdx >= reviewInBulk.length - 1}
            >
              Next
            </MoreBar.Action>
          </MoreBar>
        ) : (
          <MoreBarSkeleton />
        )}
        {reviewIdx === reviewInBulk.length - 1 && (
          <ActionButton
            variant="accent"
            onClick={() => {
              navigateTo(`${pathToUrl(ROUTES.APPS.CONTRACTS.ALL)}${location.search}`)
            }}
          >
            Finish review
          </ActionButton>
        )}
      </Flex>
    </Flex>
  )
}
