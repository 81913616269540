import React, { createContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  Action,
  Box,
  Link as UIKitLink,
  MoreBar,
  TableWidget,
  Token,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  getPayCycleCommentsAPI,
  getPayCycleDetailedReport,
  getPayCycleReportsTableRequests,
  usePayCycles,
} from '@src/api/payroll'
import { PAYROLL_INTERNAL_REQUEST } from '@src/constants/externalLinks'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import {
  payCycleReportsActionsTableColumn,
  payCycleReportsChangesColumn,
  payCycleReportsEmployeeColumn,
  payCycleReportsGrossSalaryColumn,
  payCycleReportsIssuesColumn,
} from '@src/constants/columns/payCycleReports'
import { RowInterface } from '@src/interfaces/data'
import {
  PayCycleInterface,
  PayCycleReportInterface,
  PayCycleReportsStatsInterface,
  PayCycleDetailedReportInterface,
} from '@src/interfaces/payroll'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import CommentsActionButton from '@src/features/Comments/CommentsActionButton'
import { pathToUrl } from '@src/utils/router'
import { ChangeReportDownload } from './ChangeReportDownload'
import { PayCycleSelect } from './PayCycleSelect'

interface Props {
  cycle: PayCycleInterface
  id: string
  setSelectedReportData: (data: PayCycleDetailedReportInterface | null) => void
  setShowClosePopup: (data: boolean) => void
  setShowTimelineSidebar: (data: boolean) => void
  setSidebarOpen: (data: boolean) => void
}

const statsConfig: StatsConfig<PayCycleReportsStatsInterface> = [
  {
    key: 'headcount',
    title: 'Total',
    filterKey: 'headcount',
    color: Token.color.foreground,
  },
  {
    key: 'changes',
    title: 'Changes',
    filterKey: 'changes',
    color: Token.color.foreground,
  },
  {
    key: 'no_issues',
    title: 'Approved',
    filterKey: 'no_issues',
    color: Token.color.green,
  },
  {
    key: 'criticals',
    title: 'Critical',
    filterKey: 'criticals',
    color: Token.color.red,
  },
  {
    key: 'warnings',
    title: 'Warning',
    filterKey: 'warnings',
    color: Token.color.orange,
  },
]

const getRow = (
  linkToForm: (data: PayCycleReportInterface) => void,
): RowInterface<PayCycleReportInterface> => ({
  linkToForm,
  highlight: data => {
    if (data.criticals) {
      return Token.color.inputError
    }
    return ''
  },
  cells: [
    {
      ...payCycleReportsEmployeeColumn,
      width: 200,
    },
    {
      ...payCycleReportsGrossSalaryColumn,
      width: 200,
    },
    {
      ...payCycleReportsChangesColumn,
      width: 100,
    },
    {
      ...payCycleReportsIssuesColumn,
      width: 100,
    },
    {
      ...payCycleReportsActionsTableColumn,
      width: 80,
      insert: ({ data: reportData }) => (
        <Action
          onClick={e => {
            e.stopPropagation()
            linkToForm(reportData)
          }}
        >
          View
        </Action>
      ),
    },
  ],
})

export const CurrencyContext = createContext('GBP')

export const ReportsTableWidget = ({
  cycle,
  id,
  setSelectedReportData,
  setShowClosePopup,
  setShowTimelineSidebar,
  setSidebarOpen,
}: Props) => {
  const { data: payCycles, isLoading: isLoadingPayCycles } = usePayCycles(
    cycle ? String(cycle.pay_group.id) : undefined,
    true,
  )
  const showCloseButton =
    cycle?.status.id === 'open' || cycle?.status.id === 'pending_closure'

  const tableApi = useMemo(() => getPayCycleReportsTableRequests(id), [id])

  const table = useTable<PayCycleReportInterface, PayCycleReportsStatsInterface>(
    tableApi,
    undefined,
    undefined,
    { refetchOnApiChange: true },
  )

  const statFiltersProps = useSelectableTableStats<
    PayCycleReportInterface,
    PayCycleReportsStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
    keepInitialStats: true,
  })

  const handleSidebarOpen = async (data: PayCycleReportInterface) => {
    setSidebarOpen(true)
    setSelectedReportData(null)
    const detailedData = await getPayCycleDetailedReport(id, `${data.employee.id}`)

    setSelectedReportData(detailedData.data)
  }

  const handleGoToUpload = async () => {
    navigateTo(pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.UPLOAD, { id }))
  }

  const reconcilePaymentsUrl = () => {
    const initialUrl = pathToUrl(ROUTES.APPS.PAYROLL.RECONCILE_PAYMENT)
    const searchParams = {
      pay_cycle__pay_group_id: `${cycle?.pay_group.id}`,
      pay_cycle__id: `${cycle?.id}`,
    }
    const queryString = new URLSearchParams(searchParams).toString()

    return queryString ? `${initialUrl}?${queryString}` : initialUrl
  }

  return (
    <Box mt="s-24">
      <TableWidget>
        <TableWidget.Info>
          <PayCycleSelect
            cycles={payCycles?.results}
            isLoading={isLoadingPayCycles}
            onChange={selector => {
              if (selector) {
                navigateTo(
                  pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, { id: selector.id }),
                )
              }
            }}
            selectedCycleId={Number(id)}
          />
          <StatFilters {...statFiltersProps} />
        </TableWidget.Info>
        <TableWidget.Actions>
          <MoreBar maxCount={showCloseButton ? 2 : 1}>
            {showCloseButton && (
              <MoreBar.Action
                variant="accent"
                useIcon="Check"
                onClick={() => setShowClosePopup(true)}
              >
                Close pay cycle
              </MoreBar.Action>
            )}
            <ChangeReportDownload
              id={id}
              issuesCount={table.stats?.issues}
              payrollProvider={cycle.pay_group.payroll_provider}
            />
            <HideIfCommercial>
              <MoreBar.Action useIcon="Upload" onClick={handleGoToUpload}>
                Upload payments
              </MoreBar.Action>
            </HideIfCommercial>
            <CommentsActionButton api={getPayCycleCommentsAPI(id)} useMoreBar />
            <MoreBar.Action
              useIcon="CalendarDate"
              onClick={() => {
                setShowTimelineSidebar(true)
              }}
            >
              Timeline
            </MoreBar.Action>
            <HideIfCommercial>
              <MoreBar.Action useIcon="Coins" use={Link} to={reconcilePaymentsUrl()}>
                Reconcile payments
              </MoreBar.Action>
            </HideIfCommercial>
            <MoreBar.Action
              useIcon="LinkExternal"
              use={Link}
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT, {
                id: String(cycle.pay_group.id),
              })}
              target="_blank"
            >
              Edit paygroup
            </MoreBar.Action>
            <HideIfCommercial>
              <MoreBar.Action
                useIcon="Lightning"
                use={UIKitLink}
                href={PAYROLL_INTERNAL_REQUEST}
                target="_blank"
                variant="negative"
              >
                Report issue
              </MoreBar.Action>
            </HideIfCommercial>
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <CurrencyContext.Provider
            value={cycle?.pay_group?.country?.national_currency?.iso_code}
          >
            <AdjustableTable<PayCycleReportInterface, PayCycleReportsStatsInterface>
              dataType="employee"
              name={TableNames.PayCycleReports}
              row={getRow((data: PayCycleReportInterface) => handleSidebarOpen(data))}
              useWindowScroll
              {...table}
            />
          </CurrencyContext.Provider>
        </TableWidget.Table>
      </TableWidget>
    </Box>
  )
}
