import { Flex, Text } from '@revolut/ui-kit'
import styled from 'styled-components'
import css from '@styled-system/css'

import { ButtonBase } from '@src/pages/Landing/components/Button'
import React from 'react'

interface CookieConsentInitialProps {
  onClickAllow: () => void
  onClickReject: () => void
  onClickManage: () => void
}

const CookieConsentButton = styled(ButtonBase)(
  css({
    flex: 1,
    maxWidth: ['unset', null, '440px', '720px'],
  }),
)

export const CookieConsentInitial = ({
  onClickAllow,
  onClickReject,
  onClickManage,
}: CookieConsentInitialProps) => {
  return (
    <>
      <Text
        use="h5"
        // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
        variant="subtitle2"
        mb="s-16"
        color="foreground"
      >
        Cookies give you a personalised experience
      </Text>
      <Text use="p" variant="secondary" color="foreground" mb="s-16">
        We’re not talking about the crunchy, tasty kind. These cookies help us keep our
        website safe, give you a better experience and show more relevant ads. We won’t
        turn them on unless you accept. Want to know more or adjust your preferences?
      </Text>
      <Flex justifyContent="space-between" flexWrap="wrap" ml="-s-8" mr="-s-8">
        <CookieConsentButton
          variant="primary"
          size="sm"
          mt="s-16"
          mx="s-4"
          onClick={onClickAllow}
        >
          Allow all cookies
        </CookieConsentButton>
        <CookieConsentButton
          variant="primary"
          size="sm"
          mt="s-16"
          mx="s-4"
          onClick={onClickReject}
        >
          Reject all cookies
        </CookieConsentButton>
      </Flex>
      <Flex ml="-s-8" mr="-s-8">
        <CookieConsentButton
          variant="secondary"
          size="sm"
          mt="s-16"
          mx="s-4"
          onClick={onClickManage}
        >
          Manage cookies
        </CookieConsentButton>
      </Flex>
    </>
  )
}
