import React from 'react'
import { Box, VStack, Text, Flex } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const HeroBlockContentBaseLayout = styled(ContentBlockLayout)(
  css({
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: ['column', null, null, 'row'],
    justifyContent: [null, null, null, 'space-between'],
    paddingBottom: 0,
    paddingTop: ['s-40', 's-40', null, 0],
  }),
)

export const HeroBlockContentBase = styled(Box)<{
  align?: 'center' | 'start'
}>(({ align }) =>
  css({
    width: '100%',
    alignContent: 'center',
    display: 'grid',
    gap: ['s-16', null, null, 's-24', null, 's-32'],
    height: 'auto',
    marginX: ['auto', null, null, align === 'start' ? 0 : 'auto'],
    minHeight: [null, null, null, 200],
    textAlign: ['start', null, align, align],
    justifyItems: ['start', null, align, align],
    maxWidth: [null, null, null, align === 'start' ? '65%' : undefined],
  }),
)

export const HeroBlockContent = () => {
  return (
    <HeroBlockContentBaseLayout>
      <HeroBlockContentBase align="center">
        <VStack
          align={{ all: 'start', md: 'center' }}
          space={{ all: 's-8', lg: 's-24' }}
          width="100%"
        >
          <Text
            color="grey-tone-50"
            role="doc-subtitle"
            use="p"
            variant="h6"
            whiteSpace="pre-wrap"
            width="100%"
          >
            Revolut People
          </Text>
          <Text use="h1" variant="h1" whiteSpace="pre-wrap" width="100%">
            The platform for all things people
          </Text>
          <Text
            use="p"
            whiteSpace="pre-wrap"
            // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
            variant="subtitle1"
            width={{ all: '100%', lg: '60%', xxl: '80%' }}
          >
            Tackle all your employee needs in one place — from performance and HR to
            hiring — no matter your size or where your team's based. It's the engine
            behind our 30x people growth — and now we're bringing it to businesses around
            the globe.
          </Text>
        </VStack>

        <Flex gap="s-24">
          <Button variant="primary" use={InternalLink} to={pathToUrl(ROUTES.SIGNUP.MAIN)}>
            Sign up now
          </Button>
          <Button
            variant="secondary"
            use={InternalLink}
            to={pathToUrl(ROUTES.CONTACT_US)}
            size="sm"
          >
            Contact sales
          </Button>
        </Flex>
      </HeroBlockContentBase>
    </HeroBlockContentBaseLayout>
  )
}
